<script setup>
import {ref} from 'vue';

const props = defineProps({
  updatePromo: {
    type: Function,
    required: true
  }
})

const promo = ref(null);
</script>

<template>
  <div class="promo">
    <h5 class="promo__title">
      Промокод
    </h5>
    <div class="promo__control">
      <b-form-input class="promo__input"
                    placeholder="Введите ваш промокод"
                    v-model="promo"
                    @change="props.updatePromo(promo)"
      />
      <a class="promo__accept link_as_btn"
      >
        Применить
      </a>
    </div>
  </div>
</template>

<style scoped lang="scss">
$grey: #908F90;

.promo {
  padding: 36px 1rem;
  background: #fff;

  &__title {
    color: $grey;
    font-size: 16px;
    font-weight: 400;
  }

  &__control {
    position: relative;
  }

  &__input {
    height: auto;
    padding: 12px 16px;
    border: none;
    border-bottom: 1px solid #ECE6F0;
    color: $grey;
    font-size: 18px;
    font-weight: 400;
    outline: none;
    box-shadow: none !important;
    background: #fff !important;
  }

  &__accept {
    position: absolute;
    top: 0;
    right: 1rem;
    padding: 14px 0;
    color: $grey;
    font-size: 16px;
    font-weight: 500;
  }
}
</style>
