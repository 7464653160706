<script setup>
import {ref} from 'vue';

import ModalSetting from "@/components/setting-columns/ModalSetting.vue";

const props = defineProps({
  columns: Array,
  pageName: String,
  saved: Function
})

const RefModalSetting = ref(null);

const openModal = () => RefModalSetting.value.show();
</script>

<template>
  <div>
    <b-btn class="btn_icon btn_settings_columns"
           @click="openModal"
    >
      <b-icon icon="gear-fill" aria-hidden="true"></b-icon>
    </b-btn>

    <ModalSetting ref="RefModalSetting"
                  :pageName="props.pageName"
                  :columns="props.columns"
                  :saved="saved"
    />
  </div>
</template>

<style lang="scss">
.btn_settings_columns {
  border: 1px solid #6c757d !important;
  background: #6c757d !important;
}
</style>
