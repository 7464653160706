import moment from 'moment';

moment.locale('ru');

const getEmptyDate = (date_interval, options) => {
  return {
    consumption_sum: 0,
    discount_price: 0,
    ordered_in_pieces: 0,
    ordered_in_rubles: 0,
    redeemed: 0,
    to_transfer: 0,
    date_interval: mapDate(date_interval, options),
  };
};

const mapDate = (date, options) => {
  if (options.groupBy === 'days') {
    return moment(date).format('DD MMMM YYYY');
  }
  if (options.groupBy === 'weeks') {
    const startOfWeek = moment(date, 'YYYY-WW').startOf('week');
    const endOfWeek = moment(date, 'YYYY-WW').endOf('week');
    const startDate = moment(startOfWeek).isBefore(options.from)
      ? moment(options.from).format('DD MMMM YYYY')
      : moment(startOfWeek).format('DD MMMM YYYY');
    const endDate = moment(endOfWeek).isAfter(options.to)
      ? moment(options.to).format('DD MMMM YYYY')
      : moment(endOfWeek).format('DD MMMM YYYY');
    return `${startDate} - ${endDate}`;
  }
  if (options.groupBy === 'months') {
    return moment(date, 'YYYY-M').startOf('month').format('MMMM YYYY');
  }
  return moment(date, 'YYYY').startOf('year').format('YYYY') + ' год';
};

const getMappedValue = (value) => {
  return parseInt(100 * value) / 100;
};

const mapReport = (report, options) => {
  return {
    consumption_sum: getMappedValue(report.consumption_sum),
    discount_price: getMappedValue(report.discount_price),
    ordered_in_pieces: getMappedValue(report.ordered_in_pieces),
    ordered_in_rubles: getMappedValue(report.ordered_in_rubles),
    redeemed: getMappedValue(report.redeemed),
    to_transfer: getMappedValue(report.to_transfer),
    date_interval: options ? mapDate(report.date_interval, options) : undefined,
  };
};

const mapProduct = (product) => {
  return {
    ...product,
    total_consumptions: getMappedValue(product.total_consumptions),
    total_in_pieces: getMappedValue(product.total_in_pieces),
    total_in_rubles: getMappedValue(product.total_in_rubles),
    total_prices: getMappedValue(product.total_prices),
    total_redeemed: getMappedValue(product.total_redeemed),
    total_to_transfer: getMappedValue(product.total_to_transfer),
  };
};

const getDatesList = ({ from, to, groupBy }) => {
  let dates = [];
  const formatTypes = {
    days: 'YYYY-MM-DD',
    weeks: 'YYYY-WW',
    months: 'YYYY-M',
    years: 'YYYY',
  };
  let currentDate = moment(from, 'YYYY-MM-DD').format(formatTypes[groupBy]);
  const stopDate = moment(to, 'YYYY-MM-DD').format(formatTypes[groupBy]);
  while (currentDate <= stopDate) {
    dates.push(currentDate);
    currentDate = moment(currentDate)
      .add(1, groupBy)
      .format(formatTypes[groupBy]);
  }
  return dates;
};

export const mapProducts = (products, options) => {
  const datesList = getDatesList(options);
  return products.map((product) => {
    const mappedReports = product.reports.reduce((acc, report) => {
      return {
        ...acc,
        [report.date_interval]: mapReport(report, options),
      };
    }, {});

    const fullReports = datesList.reduce((acc, date) => {
      return {
        ...acc,
        [date]: mappedReports[date]
          ? mappedReports[date]
          : getEmptyDate(date, options),
      };
    }, {});

    return {
      ...mapProduct(product),
      reports: fullReports,
    };
  });
};

export const mapTotals = (totals) => {
  return {
    products_totals: mapProduct(totals.products_totals),
    reports_totals: Object.entries(totals.reports_totals).reduce(
      (acc, [key, report]) => {
        return {
          ...acc,
          [key]: mapReport(report),
        };
      },
      {}
    ),
  };
};
