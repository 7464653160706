<template>
  <div class="filters">
    <div class="third-report-card p-3">
      <div
        style="flex-basis: 30%; flex-grow: 1"
        class="third-report-card__info"
        v-if="!hideOptions.includes('image')"
      >
        <div class="mr-2 td_product_images">
          <img
            height="70"
            :src="img"
            @mouseover="onMouseOverImg(img)"
            @mouseleave="onMouseLeaveImg()"
          />

          <ImgPreview
            :src="img"
            :alt="name"
            :title="name"
            v-if="activeImg && img"
          />
        </div>
        <div class="third-report-card__info-data ml-2">
          <div class="third-report-card__title mt-1 mb-1">
            <a style="color: #481172"
              >Анализ артикула в рекламе {{ article }}</a
            >
          </div>
          <div class="third-report-card__description">
            {{ name }}
          </div>
        </div>
      </div>

      <div
        style="flex-basis: 60%; flex-grow: 1"
        class="d-flex"
        v-if="!hideOptions.includes('range')"
      >
        <div class="third-report-card__date-filter mr-1">
          <div class="d-flex period-filter me-3">
            <label class="d-flex align-items-center me-3 mb-0"> Период </label>
            <b-input-group style="width: 160px">
              <b-input-group-prepend
                class="third-report-card_sort-and-filter__input-prepend"
              >
                <b-form-datepicker
                  start-weekday="1"
                  v-model="filters.from"
                  button-only
                >
                  <template #button-content>
                    <img height="20" src="/img/datepicker-icon.svg" />
                  </template>
                </b-form-datepicker>
              </b-input-group-prepend>
              <b-form-input
                class="third-report-card_sort-and-filter__input"
                type="text"
                placeholder="17/02/2023"
                v-model="formattedFrom"
                disabled
                style="height: 45px"
              ></b-form-input>
            </b-input-group>
          </div>

          <div class="d-flex period-filter">
            <label class="d-flex align-items-center me-3 mb-0"> по </label>
            <b-input-group style="width: 160px">
              <b-input-group-prepend
                class="third-report-card_sort-and-filter__input-prepend"
              >
                <b-form-datepicker
                  start-weekday="1"
                  v-model="filters.to"
                  button-only
                >
                  <template #button-content>
                    <img height="20" src="/img/datepicker-icon.svg" />
                  </template>
                </b-form-datepicker>
              </b-input-group-prepend>
              <b-form-input
                type="text"
                class="third-report-card_sort-and-filter__input"
                placeholder="17/02/2023"
                v-model="formattedTo"
                disabled
                style="height: 45px"
              ></b-form-input>
            </b-input-group>
          </div>
        </div>

        <div
          class="third-report-card__date-filter ml-1"
          v-if="!hideOptions.includes('range_btns')"
        >
          <b-button-group class="wb-button-group">
            <b-button
              style="font-size: 13px"
              :pressed="checkDate(7)"
              @click="setDate(7)"
            >
              7 дней
            </b-button>
            <b-button
              style="font-size: 13px"
              :pressed="checkDate(14)"
              @click="setDate(14)"
            >
              14 дней
            </b-button>
            <b-button
              style="font-size: 13px"
              :pressed="checkDate(30)"
              @click="setDate(30)"
            >
              30 дней
            </b-button>
            <b-button
              style="font-size: 13px"
              :pressed="checkDate(90)"
              @click="setDate(90)"
            >
              90 дней
            </b-button>
          </b-button-group>
        </div>

        <div class="flex justify-content-end text-right filters__wrap_export">
          <ReportsGroupBtnMenu :options="groups" :onSelect="onSelectGroup" />

          <b-btn @click="downloadReport" class="wb-btn wb-btn-reverse export">
            <div class="export__container">
              <img src="/img/export-icon-2.svg" />
              <span>Export</span>
            </div>
          </b-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapActions } from 'vuex';
import { includes } from 'core-js/internals/array-includes';
import ImgPreview from '@/components/images/ImgPreview.vue';
import ReportsGroupBtnMenu from '@/components/reports-groups/ReportsGroupBtnMenu.vue';

export default {
  name: 'filterAndsort',
  components: {
    ImgPreview,
    ReportsGroupBtnMenu,
  },
  props: {
    article: {
      type: Number | String,
      default: '',
      required: false,
    },
    name: {
      type: String,
      default: '',
    },
    hideOptions: {
      type: Array,
      required: false,
      default: () => [],
    },
    img: {
      type: String,
      required: false,
      default: '/img/aesthetic purple outfits.png',
    },
    groups: {
      type: Array,
    },
    onSelectGroup: {
      type: Function,
    },
    activeGroup: {
      type: String,
    },
    currentId: {
      type: Number,
    },
  },
  data() {
    return {
      tableHeight: 0,
      sortKey: null,
      isWatchCompetitor: false,
      newKeyword: '',
      newDirectCompetitor: '',
      filters: {
        from:
          this.$route.query.from ||
          moment().subtract(7, 'days').format('YYYY-MM-DD'),
        to: this.$route.query.to || moment().format('YYYY-MM-DD'),
      },
      activeImg: null,
    };
  },
  computed: {
    formattedTo() {
      return moment(this.filters.to).format('DD/MM/YYYY');
    },
    formattedFrom() {
      return moment(this.filters.from).format('DD/MM/YYYY');
    },
  },
  watch: {
    filters: {
      handler(newVal, oldVal) {
        const formattedTo = moment(this.filters.to).format('YYYY-MM-DD');
        const formattedFrom = moment(this.filters.from).format('YYYY-MM-DD');
        this.setQuery();
        this.emitDateFilterHandler({ to: formattedTo, from: formattedFrom });
      },
      deep: true,
    },
  },
  methods: {
    includes,
    ...mapActions(['uploadReport']),
    downloadReport() {
      const formattedTo = moment(this.filters.to).format('YYYY-MM-DD');
      const formattedFrom = moment(this.filters.from).format('YYYY-MM-DD');
      this.uploadReport({
        type: 'dyn_product',
        filters: {
          end_date: formattedTo,
          start_date: formattedFrom,
          nm_id: this.currentId,
          group_by: this.activeGroup,
        },
      });
    },
    setFilterByQuery() {
      this.filters.from = moment().subtract(8, 'days').format('YYYY-MM-DD');
      this.filters.to = moment().subtract(1, 'days').format('YYYY-MM-DD');
    },
    setQuery() {
      this.$router.replace({
        name: 'productsStatistic',
        query: {
          from: this.filters.from || '',
          to: this.filters.to || '',
        },
      });
    },
    checkDate(interval) {
      let days = interval + 1;

      const now = moment().subtract(1, 'days');
      const pre = moment().subtract(days, 'days');

      return (
        now.isSame(moment(this.filters.to), 'day') &&
        pre.isSame(moment(this.filters.from), 'day')
      );
    },

    setDate(interval) {
      const now = moment();
      const pre = moment().subtract(interval, 'days');

      this.filters.from = pre.subtract(1, 'days').format('YYYY-MM-DD');
      this.filters.to = now.subtract(1, 'days').format('YYYY-MM-DD');
    },
    emitDateFilterHandler(data) {
      this.$emit('dateFilterHandler', data);
    },

    onMouseOverImg(image) {
      this.activeImg = image;
    },

    onMouseLeaveImg() {
      this.activeImg = null;
    },
  },
  mounted() {
    this.setFilterByQuery();
  },
};
</script>

<style lang="scss" scoped>
@import '../index.scss';

.filters {
  & .third-report-card {
    align-items: center;
    grid-template-columns: 300px calc(100% - 300px);
  }

  & .filters__wrap_export {
    display: flex;
  }

  & .export {
    height: 40px !important;
    color: #fff !important;
    background: #481173 !important;

    &__container {
      display: flex;
      gap: 0.5rem;
      align-items: center;
    }
  }
}

.third-report-card__description {
  font-size: 12px;
  color: #908f90;
  text-transform: uppercase;
}

.td_product_images {
  position: relative;
  top: -7px;
  border-radius: 8px;
}
</style>
