var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"companiesAdvertising"},[_c('div',{staticClass:"companiesAdvertising-filter wrap_page_filter"},[(_vm.isShowCompFilter)?_c('filterAndSort',{attrs:{"dateStart":_vm.from,"dateEnd":_vm.to},on:{"getReports":_vm.getReports,"setFilters":_vm.setFilters,"dateFilterHandler":_vm.dateFilterHandler}}):_vm._e()],1),(_vm.isHasSubscribe && _vm.hasCompaniesSuitableSubscribtion)?_c('div',{staticClass:"companiesAdvertising-table",staticStyle:{"overflow-x":"scroll"},style:('height: ' + _vm.tableHeight + 'px;')},[(_vm.isShowColumnSettings)?_c('SettingsColumn',{attrs:{"columns":_vm.columnsForSort,"saved":_vm.setSettings,"pageName":_vm.pageName}}):_vm._e(),(_vm.loading)?_c('b-skeleton-table',{attrs:{"rows":5,"columns":4,"table-props":{ bordered: true, striped: true }}}):_vm._e(),(!_vm.loading)?_c('div',[(_vm.companiesAdvertising.length > 0 && !_vm.loading)?_c('table',{staticClass:"companies-advertising-table table table-v2"},[_c('thead',[_c('tr',[_c('th',{staticClass:"sticky-col table-label text-center",staticStyle:{"z-index":"10","background":"#fcf8ff !important","border-left":"1px solid #f1f1f1"},attrs:{"scope":"col","title":"Статус Вашей рекламной кампании (зеленый- РК активна, оранжевый- РК приостановлена, серый- РК завершена (в архиве))"}},[_vm._v(" Статус "),_c('filterAndSortModal',{attrs:{"active_key":{ status_key: _vm.row_keys.status_key },"active-sort-key":_vm.sortKey,"sort-key":"adv_id","items":_vm.setFilterContainer('status'),"isUsed":_vm.bufferQueryArray.status !== null,"onUpdateReport":_vm.onUpdateReport,"clearReport":_vm.clearReport,"keyItem":"status"},on:{"activeKeyHandler":_vm.activeKeyHandler,"sort":_vm.sort}})],1),_c('th',{class:[
                _vm.row_keys.type_key
                  ? 'right-box-shadow table-label'
                  : 'sticky-col table-label',
              ],staticStyle:{"min-width":"100px","max-width":"120px","z-index":"10","left":"84px","background":"#fcf8ff !important"},attrs:{"scope":"col","title":"Тип Вашей рекламной кампании (АРК-Автоматическая кампания, Поиск+Каталог)"}},[_vm._v(" Тип "),_c('filterAndSortModal',{attrs:{"active_key":{ category_name: _vm.row_keys.category_name },"active-sort-key":_vm.sortKey,"sort-key":"category_name","items":_vm.setFilterContainer('category_name'),"isUsed":_vm.bufferQueryArray.category_name !== null,"onUpdateReport":_vm.onUpdateReport,"keyItem":"category","clearReport":_vm.clearReport},on:{"activeKeyHandler":_vm.activeKeyHandler,"sort":_vm.sort}})],1),_c('th',{staticClass:"table-label",class:[
                _vm.row_keys.id_key
                  ? ' right-box-shadow table-label'
                  : ' sticky-col table-label',
              ],staticStyle:{"min-width":"300px","max-width":"300px","z-index":"9","left":"184px","background":"#fcf8ff !important"},attrs:{"scope":"col","title":"Название Вашей рекламной кампании (из рекламного кабинета)"}},[_vm._v(" Кампания "),_c('filterAndSortModal',{attrs:{"active_key":{ company_key: _vm.row_keys.company_key },"active-sort-key":_vm.sortKey,"sort-key":"campaign_name","items":_vm.setFilterContainer('campaign_name'),"isUsed":_vm.bufferQueryArray.campaign_name !== null,"onUpdateReport":_vm.onUpdateReport,"keyItem":"company","clearReport":_vm.clearReport},on:{"activeKeyHandler":_vm.activeKeyHandler,"sort":_vm.sort}})],1),_vm._l((_vm.columnsForSort),function(columnSort,columnIndex){return [(
                  _vm.activeColumns.includes('campaign_id') &&
                  columnSort.key === 'campaign_id'
                )?_c('th',{attrs:{"scope":"col","title":"ID кампании (из рекламного кабинета)"},on:{"drop":function($event){return _vm.onDrop(columnIndex)},"dragover":function($event){$event.preventDefault();},"dragenter":function($event){$event.preventDefault();}}},[_c('div',{attrs:{"draggable":""},on:{"dragstart":function($event){return _vm.startDrag(columnSort, columnIndex)}}},[_vm._v(" ID ")])]):_vm._e(),(
                  _vm.activeColumns.includes('articul') &&
                  columnSort.key === 'articul'
                )?_c('th',{staticClass:"table-label",staticStyle:{"min-width":"200px","z-index":"1"},attrs:{"scope":"col"},on:{"drop":function($event){return _vm.onDrop(columnIndex)},"dragover":function($event){$event.preventDefault();},"dragenter":function($event){$event.preventDefault();}}},[_c('div',{attrs:{"draggable":""},on:{"dragstart":function($event){return _vm.startDrag(columnSort, columnIndex)}}},[_vm._v(" Фото ")])]):_vm._e(),(
                  _vm.activeColumns.includes('brand') &&
                  columnSort.key === 'brand'
                )?_c('th',{staticClass:"table-label",staticStyle:{"min-width":"135px"},attrs:{"scope":"col"},on:{"drop":function($event){return _vm.onDrop(columnIndex)},"dragover":function($event){$event.preventDefault();},"dragenter":function($event){$event.preventDefault();}}},[_c('div',{attrs:{"draggable":""},on:{"dragstart":function($event){return _vm.startDrag(columnSort, columnIndex)}}},[_vm._v(" Бренд "),_c('filterAndSortModal',{attrs:{"active_key":{ brand_key: _vm.row_keys.brand_key },"active-sort-key":_vm.sortKey,"items":_vm.setFilterContainer('brand'),"isUsed":_vm.bufferQueryArray.brands !== null,"onUpdateReport":_vm.onUpdateReport,"sort-key":"brand","keyItem":"brands","clearReport":_vm.clearReport},on:{"activeKeyHandler":_vm.activeKeyHandler,"sort":_vm.sort}})],1)]):_vm._e(),(
                  _vm.activeColumns.includes('start_date') &&
                  columnSort.key === 'start_date'
                )?_c('th',{staticClass:"table-label",staticStyle:{"min-width":"135px"},attrs:{"scope":"col","title":"Дата первого запуска рекламной кампании"},on:{"drop":function($event){return _vm.onDrop(columnIndex)},"dragover":function($event){$event.preventDefault();},"dragenter":function($event){$event.preventDefault();}}},[_c('div',{attrs:{"draggable":""},on:{"dragstart":function($event){return _vm.startDrag(columnSort, columnIndex)}}},[_vm._v(" Старт "),_c('sort-btn',{attrs:{"sort-key":"create_date","active-sort-key":_vm.sortKey,"isAscDefault":_vm.sortIsAsc},on:{"sort":_vm.sort}})],1)]):_vm._e(),(
                  _vm.activeColumns.includes('finish_date') &&
                  columnSort.key === 'finish_date'
                )?_c('th',{staticClass:"table-label",staticStyle:{"min-width":"130px"},attrs:{"scope":"col","title":"Дата завершения рекламной кампании"},on:{"drop":function($event){return _vm.onDrop(columnIndex)},"dragover":function($event){$event.preventDefault();},"dragenter":function($event){$event.preventDefault();}}},[_c('div',{attrs:{"draggable":""},on:{"dragstart":function($event){return _vm.startDrag(columnSort, columnIndex)}}},[_vm._v(" Финиш "),_c('sort-btn',{attrs:{"sort-key":"finish_date","active-sort-key":_vm.sortKey,"isAscDefault":_vm.sortIsAsc},on:{"sort":_vm.sort}})],1)]):_vm._e(),(
                  _vm.activeColumns.includes('views') &&
                  columnSort.key === 'views'
                )?_c('th',{staticClass:"table-label",staticStyle:{"min-width":"160px"},attrs:{"scope":"col","title":"Количество единиц показов товара/товаров рекламируемых в рекламной кампании за выбранный период"},on:{"drop":function($event){return _vm.onDrop(columnIndex)},"dragover":function($event){$event.preventDefault();},"dragenter":function($event){$event.preventDefault();}}},[_c('div',{attrs:{"draggable":""},on:{"dragstart":function($event){return _vm.startDrag(columnSort, columnIndex)}}},[_vm._v(" Просмотры, шт "),_c('sort-btn',{attrs:{"sort-key":"views","active-sort-key":_vm.sortKey,"isAscDefault":_vm.sortIsAsc},on:{"sort":_vm.sort}})],1)]):_vm._e(),(
                  _vm.activeColumns.includes('frequency') &&
                  columnSort.key === 'frequency'
                )?_c('th',{staticClass:"table-label",staticStyle:{"min-width":"150px"},attrs:{"scope":"col"},on:{"drop":function($event){return _vm.onDrop(columnIndex)},"dragover":function($event){$event.preventDefault();},"dragenter":function($event){$event.preventDefault();}}},[_c('div',{attrs:{"draggable":""},on:{"dragstart":function($event){return _vm.startDrag(columnSort, columnIndex)}}},[_vm._v(" Частота "),_c('sort-btn',{attrs:{"sort-key":"frequency","active-sort-key":_vm.sortKey,"isAscDefault":_vm.sortIsAsc},on:{"sort":_vm.sort}})],1)]):_vm._e(),(
                  _vm.activeColumns.includes('clicks') &&
                  columnSort.key === 'clicks'
                )?_c('th',{staticClass:"table-label",staticStyle:{"min-width":"120px"},attrs:{"scope":"col","title":"Количество единиц кликов (переходов клиента в карточку товара) по товару/товарам рекламируемых в рекламной кампании за выбранный период"},on:{"drop":function($event){return _vm.onDrop(columnIndex)},"dragover":function($event){$event.preventDefault();},"dragenter":function($event){$event.preventDefault();}}},[_c('div',{attrs:{"draggable":""},on:{"dragstart":function($event){return _vm.startDrag(columnSort, columnIndex)}}},[_vm._v(" Клики, шт "),_c('sort-btn',{attrs:{"sort-key":"clicks","active-sort-key":_vm.sortKey,"isAscDefault":_vm.sortIsAsc},on:{"sort":_vm.sort}})],1)]):_vm._e(),(
                  _vm.activeColumns.includes('cpc') && columnSort.key === 'cpc'
                )?_c('th',{staticClass:"table-label",staticStyle:{"min-width":"120px"},attrs:{"scope":"col","title":"CPC (сost per click)- цена за 1 клик (переход клиента в карточку ) в рекламной кампании за выбранный период"},on:{"drop":function($event){return _vm.onDrop(columnIndex)},"dragover":function($event){$event.preventDefault();},"dragenter":function($event){$event.preventDefault();}}},[_c('div',{attrs:{"draggable":""},on:{"dragstart":function($event){return _vm.startDrag(columnSort, columnIndex)}}},[_vm._v(" CPC, руб. "),_c('sort-btn',{attrs:{"sort-key":"cpc","active-sort-key":_vm.sortKey,"isAscDefault":_vm.sortIsAsc},on:{"sort":_vm.sort}})],1)]):_vm._e(),(
                  _vm.activeColumns.includes('ctr') && columnSort.key === 'ctr'
                )?_c('th',{staticClass:"table-label",staticStyle:{"min-width":"120px"},attrs:{"scope":"col","title":"CTR (Click-Through Rate) - соотношение количества кликов к показам в процентах. (считаем формулой (количество кликов / количество показов) х 100%)"},on:{"drop":function($event){return _vm.onDrop(columnIndex)},"dragover":function($event){$event.preventDefault();},"dragenter":function($event){$event.preventDefault();}}},[_c('div',{attrs:{"draggable":""},on:{"dragstart":function($event){return _vm.startDrag(columnSort, columnIndex)}}},[_vm._v(" CTR, % "),_c('sort-btn',{attrs:{"sort-key":"ctr","active-sort-key":_vm.sortKey,"isAscDefault":_vm.sortIsAsc},on:{"sort":_vm.sort}})],1)]):_vm._e(),(
                  _vm.activeColumns.includes('bid') && columnSort.key === 'bid'
                )?_c('th',{staticClass:"table-label",staticStyle:{"min-width":"150px"},attrs:{"scope":"col","title":"CPM (Cost Per Mille или Cost Per Millenium) — стоимость тысячи показов рекламного объявления. Ставка, которую Вы указали в рекламном кабинете по рекламной кампании за выбранный период"},on:{"drop":function($event){return _vm.onDrop(columnIndex)},"dragover":function($event){$event.preventDefault();},"dragenter":function($event){$event.preventDefault();}}},[_c('div',{attrs:{"draggable":""},on:{"dragstart":function($event){return _vm.startDrag(columnSort, columnIndex)}}},[_vm._v(" CPM по WB, руб "),_c('sort-btn',{attrs:{"sort-key":"bid","active-sort-key":_vm.sortKey,"isAscDefault":_vm.sortIsAsc},on:{"sort":_vm.sort}})],1)]):_vm._e(),(_vm.activeColumns.includes('cr') && columnSort.key === 'cr')?_c('th',{staticClass:"table-label",staticStyle:{"min-width":"120px"},attrs:{"scope":"col","title":"CR (Conversion rate) Соотношение количества кликов к количеству заказов. (считаем формулой (количество кликов/ количество показов) х 100%)"},on:{"drop":function($event){return _vm.onDrop(columnIndex)},"dragover":function($event){$event.preventDefault();},"dragenter":function($event){$event.preventDefault();}}},[_c('div',{attrs:{"draggable":""},on:{"dragstart":function($event){return _vm.startDrag(columnSort, columnIndex)}}},[_vm._v(" CR, % "),_c('sort-btn',{attrs:{"sort-key":"cr","active-sort-key":_vm.sortKey,"isAscDefault":_vm.sortIsAsc},on:{"sort":_vm.sort}})],1)]):_vm._e(),(
                  _vm.activeColumns.includes('total') &&
                  columnSort.key === 'total'
                )?_c('th',{staticClass:"table-label",staticStyle:{"min-width":"120px"},attrs:{"scope":"col"},on:{"drop":function($event){return _vm.onDrop(columnIndex)},"dragover":function($event){$event.preventDefault();},"dragenter":function($event){$event.preventDefault();}}},[_c('div',{attrs:{"draggable":""},on:{"dragstart":function($event){return _vm.startDrag(columnSort, columnIndex)}}},[_vm._v(" Расход, руб. "),_c('sort-btn',{attrs:{"sort-key":"total","active-sort-key":_vm.sortKey,"isAscDefault":_vm.sortIsAsc},on:{"sort":_vm.sort}})],1)]):_vm._e(),(
                  _vm.activeColumns.includes('cart') && columnSort.key === 'cart'
                )?_c('th',{staticClass:"table-label",staticStyle:{"min-width":"140px"},attrs:{"scope":"col","title":"Количество единиц добавленых товаров в корзину по рекламной кампании за выбранный период. (из рекламной статистики ВБ)"},on:{"drop":function($event){return _vm.onDrop(columnIndex)},"dragover":function($event){$event.preventDefault();},"dragenter":function($event){$event.preventDefault();}}},[_c('div',{attrs:{"draggable":""},on:{"dragstart":function($event){return _vm.startDrag(columnSort, columnIndex)}}},[_vm._v(" Корзина, шт. "),_c('sort-btn',{attrs:{"sort-key":"cart","active-sort-key":_vm.sortKey,"isAscDefault":_vm.sortIsAsc},on:{"sort":_vm.sort}})],1)]):_vm._e(),(
                  _vm.activeColumns.includes('orders') &&
                  columnSort.key === 'orders'
                )?_c('th',{staticClass:"table-label",staticStyle:{"min-width":"130px"},attrs:{"scope":"col","title":"Количество единиц заказанных товаров по рекламной кампании за выбранный период. (из рекламной статистики ВБ)a"},on:{"drop":function($event){return _vm.onDrop(columnIndex)},"dragover":function($event){$event.preventDefault();},"dragenter":function($event){$event.preventDefault();}}},[_c('div',{attrs:{"draggable":""},on:{"dragstart":function($event){return _vm.startDrag(columnSort, columnIndex)}}},[_vm._v(" Заказы, шт. "),_c('sort-btn',{attrs:{"sort-key":"orders","active-sort-key":_vm.sortKey,"isAscDefault":_vm.sortIsAsc},on:{"sort":_vm.sort}})],1)]):_vm._e(),(
                  _vm.activeColumns.includes('drr') && columnSort.key === 'drr'
                )?_c('th',{staticClass:"table-label",staticStyle:{"min-width":"130px"},attrs:{"scope":"col","title":"Расход денежных средств  за выбранный период относительно рекламной кампании. (из рекламной статистики ВБ)"},on:{"drop":function($event){return _vm.onDrop(columnIndex)},"dragover":function($event){$event.preventDefault();},"dragenter":function($event){$event.preventDefault();}}},[_c('div',{attrs:{"draggable":""},on:{"dragstart":function($event){return _vm.startDrag(columnSort, columnIndex)}}},[_vm._v(" ДРР, % "),_c('sort-btn',{attrs:{"sort-key":"drr","active-sort-key":_vm.sortKey,"isAscDefault":_vm.sortIsAsc},on:{"sort":_vm.sort}})],1)]):_vm._e(),(
                  _vm.activeColumns.includes('cpl') && columnSort.key === 'cpl'
                )?_c('th',{staticClass:"table-label",staticStyle:{"min-width":"120px"},attrs:{"scope":"col","title":"(«Cost per lead» - цена за лид). Стоимость одного добавления в корзину по рекламной кампании за выбранный период. (считаем формулой расход,руб / на корзины в шт)"},on:{"drop":function($event){return _vm.onDrop(columnIndex)},"dragover":function($event){$event.preventDefault();},"dragenter":function($event){$event.preventDefault();}}},[_c('div',{attrs:{"draggable":""},on:{"dragstart":function($event){return _vm.startDrag(columnSort, columnIndex)}}},[_vm._v(" CPL, руб. "),_c('sort-btn',{attrs:{"sort-key":"cpl","active-sort-key":_vm.sortKey,"isAscDefault":_vm.sortIsAsc},on:{"sort":_vm.sort}})],1)]):_vm._e(),(
                  _vm.activeColumns.includes('cpo') && columnSort.key === 'cpo'
                )?_c('th',{staticClass:"table-label",staticStyle:{"min-width":"120px"},attrs:{"scope":"col","title":"(«Cost per Order» - стоимость оформленного заказа). Стоимость одного оформленного заказа по рекламной кампании за выбранный период. (считаем формулой расход,руб / на заказы в шт)"},on:{"drop":function($event){return _vm.onDrop(columnIndex)},"dragover":function($event){$event.preventDefault();},"dragenter":function($event){$event.preventDefault();}}},[_c('div',{attrs:{"draggable":""},on:{"dragstart":function($event){return _vm.startDrag(columnSort, columnIndex)}}},[_vm._v(" CPO, руб. "),_c('sort-btn',{attrs:{"sort-key":"cpo","active-sort-key":_vm.sortKey,"isAscDefault":_vm.sortIsAsc},on:{"sort":_vm.sort}})],1)]):_vm._e(),(
                  _vm.activeColumns.includes('cpm') && columnSort.key === 'cpm'
                )?_c('th',{staticClass:"table-label",staticStyle:{"min-width":"135px"},attrs:{"scope":"col"},on:{"drop":function($event){return _vm.onDrop(columnIndex)},"dragover":function($event){$event.preventDefault();},"dragenter":function($event){$event.preventDefault();}}},[_c('div',{attrs:{"draggable":""},on:{"dragstart":function($event){return _vm.startDrag(columnSort, columnIndex)}}},[_vm._v(" CPM факт, руб "),_c('sort-btn',{attrs:{"sort-key":"cpm","active-sort-key":_vm.sortKey,"isAscDefault":_vm.sortIsAsc},on:{"sort":_vm.sort}})],1)]):_vm._e(),(
                  _vm.activeColumns.includes('working_days') &&
                  columnSort.key === 'working_days'
                )?_c('th',{staticClass:"table-label",staticStyle:{"min-width":"170px"},attrs:{"scope":"col","title":"Дни работы относительно каждой рекламной кампании"},on:{"drop":function($event){return _vm.onDrop(columnIndex)},"dragover":function($event){$event.preventDefault();},"dragenter":function($event){$event.preventDefault();}}},[_c('div',{attrs:{"draggable":""},on:{"dragstart":function($event){return _vm.startDrag(columnSort, columnIndex)}}},[_vm._v(" Дни работы "),_c('sort-btn',{attrs:{"sort-key":"working_days","active-sort-key":_vm.sortKey,"isAscDefault":_vm.sortIsAsc},on:{"sort":_vm.sort}})],1)]):_vm._e(),(
                  _vm.activeColumns.includes('limit_consumption') &&
                  columnSort.key === 'limit_consumption'
                )?_c('th',{staticClass:"table-label",staticStyle:{"min-width":"135px"},attrs:{"scope":"col","title":"СPM (Cost Per Mille или Cost Per Millenium) — стоимость тысячи показов рекламного объявления. Фактическая ставка в рекламной кампании за выбранный период. (считаем по формуле количество показов в шт/ 1000)"},on:{"drop":function($event){return _vm.onDrop(columnIndex)},"dragover":function($event){$event.preventDefault();},"dragenter":function($event){$event.preventDefault();}}},[_c('div',{attrs:{"draggable":""},on:{"dragstart":function($event){return _vm.startDrag(columnSort, columnIndex)}}},[_vm._v(" Расход лимита ")])]):_vm._e()]})],2)]),_c('tbody',[(!_vm.loading)?[_vm._l((_vm.companiesAdvertising),function(item,idx){return _c('tr',{key:idx},[_c('td',{staticClass:"sticky-col"},[_c('div',{staticClass:"d-flex justify-content-center align-items-center"},[_c('div',{staticStyle:{"width":"16px","height":"16px","border-radius":"50%","cursor":"help"},style:({
                      'background-color': _vm.getStatusColor(item.status),
                    }),attrs:{"title":item.status}})])]),_c('td',{staticClass:"sticky-col",staticStyle:{"max-width":"120px","min-width":"100px","left":"84px"}},[_c('span',{staticClass:"cell-text"},[_vm._v(" "+_vm._s(item.category_name === 'Автоматическая кампания' ? 'АРК' : item.category_name)+" ")])]),_c('td',{staticClass:"sticky-col",staticStyle:{"min-width":"300px","max-width":"300px","left":"184px","box-shadow":"inset -8px 0 8px -8px rgba(47, 10, 73, 0.12)"}},[(item.campaign_id !== 0)?_c('router-link',{staticClass:"wb-table-link",attrs:{"to":'/reports/statistic-advertising/' + item.campaign_id}},[_c('span',{staticClass:"cell-text"},[_vm._v(" "+_vm._s(item.campaign_name)+" ")])]):_vm._e()],1),_vm._l((_vm.columnsForSort),function(columnSort){return [(
                    _vm.activeColumns.includes('campaign_id') &&
                    columnSort.key === 'campaign_id'
                  )?_c('td',[_c('span',{staticClass:"cell-text"},[_vm._v(" "+_vm._s(item.campaign_id)+" ")])]):_vm._e(),(
                    _vm.activeColumns.includes('articul') &&
                    columnSort.key === 'articul'
                  )?_c('td',{staticClass:"td_product_images"},[_c('div',{staticClass:"table_container_images"},_vm._l((item.products),function(product){return _c('img',{key:`${product.nm_id}-${idx}`,staticClass:"image_preview_small",attrs:{"src":product.image,"alt":product.brand,"title":product.brand},on:{"mouseover":function($event){return _vm.onMouseOverImg(idx, product)},"mouseleave":function($event){return _vm.onMouseLeaveImg()}}})}),0),(_vm.activeImg && _vm.activeImg.index === idx)?_c('ImgPreview',{attrs:{"src":_vm.activeImg.src,"alt":_vm.activeImg.brand,"title":_vm.activeImg.brand}}):_vm._e()],1):_vm._e(),(
                    _vm.activeColumns.includes('brand') &&
                    columnSort.key === 'brand'
                  )?_c('td',_vm._l((_vm.renderBrand(item)),function(brand){return _c('div',[_vm._v(" "+_vm._s(brand)+" ")])}),0):_vm._e(),(
                    _vm.activeColumns.includes('start_date') &&
                    columnSort.key === 'start_date'
                  )?_c('td',[_vm._v(" "+_vm._s(_vm.renderDate(item.create_date))+" ")]):_vm._e(),(
                    _vm.activeColumns.includes('finish_date') &&
                    columnSort.key === 'finish_date'
                  )?_c('td',[_vm._v(" "+_vm._s(_vm.renderDate(item.finish_date))+" ")]):_vm._e(),(
                    _vm.activeColumns.includes('views') &&
                    columnSort.key === 'views'
                  )?_c('td',[_vm._v(" "+_vm._s(_vm.renderPrice(Math.round(item.views)))+" ")]):_vm._e(),(
                    _vm.activeColumns.includes('frequency') &&
                    columnSort.key === 'frequency'
                  )?_c('td',[_vm._v(" "+_vm._s(Math.round(item.frequency))+" ")]):_vm._e(),(
                    _vm.activeColumns.includes('clicks') &&
                    columnSort.key === 'clicks'
                  )?_c('td',[_vm._v(" "+_vm._s(_vm.renderPrice(Math.round(item.clicks)))+" ")]):_vm._e(),(
                    _vm.activeColumns.includes('cpc') && columnSort.key === 'cpc'
                  )?_c('td',[_vm._v(" "+_vm._s(_vm.renderFixedFormat(item.cpc))+" ")]):_vm._e(),(
                    _vm.activeColumns.includes('ctr') && columnSort.key === 'ctr'
                  )?_c('td',[_vm._v(" "+_vm._s(_vm.renderFixedFormat(item.ctr, 2))+" ")]):_vm._e(),(
                    _vm.activeColumns.includes('bid') && columnSort.key === 'bid'
                  )?_c('td',[_vm._v(" "+_vm._s(item.bid)+" ")]):_vm._e(),(
                    _vm.activeColumns.includes('cr') && columnSort.key === 'cr'
                  )?_c('td',[_vm._v(" "+_vm._s(item.cr)+" ")]):_vm._e(),(
                    _vm.activeColumns.includes('total') &&
                    columnSort.key === 'total'
                  )?_c('td',[_vm._v(" "+_vm._s(_vm.renderPrice(Math.round(item.total)))+" ")]):_vm._e(),(
                    _vm.activeColumns.includes('cart') &&
                    columnSort.key === 'cart'
                  )?_c('td',[_vm._v(" "+_vm._s(_vm.renderPrice(Math.round(item.cart)))+" ")]):_vm._e(),(
                    _vm.activeColumns.includes('orders') &&
                    columnSort.key === 'orders'
                  )?_c('td',[_vm._v(" "+_vm._s(_vm.renderPrice(Math.round(item.orders)))+" ")]):_vm._e(),(
                    _vm.activeColumns.includes('drr') && columnSort.key === 'drr'
                  )?_c('td',[_vm._v(" "+_vm._s(_vm.renderPrice(_vm.renderFixedFormat(item.drr, 2)))+" ")]):_vm._e(),(
                    _vm.activeColumns.includes('cpl') && columnSort.key === 'cpl'
                  )?_c('td',[_vm._v(" "+_vm._s(_vm.renderPrice(Math.round(item.cpl)))+" ")]):_vm._e(),(
                    _vm.activeColumns.includes('cpo') && columnSort.key === 'cpo'
                  )?_c('td',[_vm._v(" "+_vm._s(_vm.renderPrice(Math.round(item.cpo)))+" ")]):_vm._e(),(
                    _vm.activeColumns.includes('cpm') && columnSort.key === 'cpm'
                  )?_c('td',[_vm._v(" "+_vm._s(_vm.renderPrice(Math.round(item.cpm)))+" ")]):_vm._e(),(
                    _vm.activeColumns.includes('working_days') &&
                    columnSort.key === 'working_days'
                  )?_c('td',[_vm._v(" "+_vm._s(_vm.renderPrice(item.working_days))+" ")]):_vm._e(),(
                    _vm.activeColumns.includes('limit_consumption') &&
                    columnSort.key === 'limit_consumption'
                  )?_c('td',[_vm._v(" "+_vm._s(_vm.renderLimit(item.limit_consumption))+" ")]):_vm._e()]}),_c('td',{staticClass:"table-v2-btn-settings_icon"})],2)}),_c('tr',{staticClass:"table-row-total"},[_c('td',{staticClass:"sticky-col"}),_c('td',{staticClass:"sticky-col",staticStyle:{"max-width":"120px","min-width":"100px","left":"84px"}}),_vm._m(0),_vm._l((_vm.columnsForSort),function(columnSort){return [(
                    _vm.activeColumns.includes('company') &&
                    columnSort.key === 'company'
                  )?_c('td'):_vm._e(),(
                    _vm.activeColumns.includes('articul') &&
                    columnSort.key === 'articul'
                  )?_c('td',{staticClass:"td_product_images"}):_vm._e(),(
                    _vm.activeColumns.includes('brand') &&
                    columnSort.key === 'brand'
                  )?_c('td'):_vm._e(),(
                    _vm.activeColumns.includes('start_date') &&
                    columnSort.key === 'start_date'
                  )?_c('td'):_vm._e(),(
                    _vm.activeColumns.includes('finish_date') &&
                    columnSort.key === 'finish_date'
                  )?_c('td'):_vm._e(),(
                    _vm.activeColumns.includes('views') &&
                    columnSort.key === 'views'
                  )?_c('td',[_vm._v(" "+_vm._s(_vm.renderPrice(Math.round(_vm.total.views)))+" ")]):_vm._e(),(
                    _vm.activeColumns.includes('frequency') &&
                    columnSort.key === 'frequency'
                  )?_c('td',[_vm._v(" "+_vm._s(_vm.renderPrice(Math.round(_vm.total.frequency)))+" ")]):_vm._e(),(
                    _vm.activeColumns.includes('clicks') &&
                    columnSort.key === 'clicks'
                  )?_c('td',[_vm._v(" "+_vm._s(_vm.renderPrice(Math.round(_vm.total.clicks)))+" ")]):_vm._e(),(
                    _vm.activeColumns.includes('cpc') && columnSort.key === 'cpc'
                  )?_c('td',[_vm._v(" "+_vm._s(_vm.renderFixedFormat(_vm.total.cpc))+" ")]):_vm._e(),(
                    _vm.activeColumns.includes('ctr') && columnSort.key === 'ctr'
                  )?_c('td',[_vm._v(" "+_vm._s(_vm.renderFixedFormat(_vm.total.ctr, 2))+" ")]):_vm._e(),(
                    _vm.activeColumns.includes('bid') && columnSort.key === 'bid'
                  )?_c('td',[_vm._v(" - ")]):_vm._e(),(
                    _vm.activeColumns.includes('cr') && columnSort.key === 'cr'
                  )?_c('td',[_vm._v(" "+_vm._s(_vm.renderPrice(_vm.total.cr))+" ")]):_vm._e(),(
                    _vm.activeColumns.includes('total') &&
                    columnSort.key === 'total'
                  )?_c('td',[_vm._v(" "+_vm._s(_vm.renderPrice(Math.round(_vm.total.total)))+", руб ")]):_vm._e(),(
                    _vm.activeColumns.includes('cart') &&
                    columnSort.key === 'cart'
                  )?_c('td',[_vm._v(" "+_vm._s(_vm.renderPrice(Math.round(_vm.total.cart)))+" ")]):_vm._e(),(
                    _vm.activeColumns.includes('orders') &&
                    columnSort.key === 'orders'
                  )?_c('td',[_vm._v(" "+_vm._s(_vm.renderPrice(Math.round(_vm.total.orders)))+" ")]):_vm._e(),(
                    _vm.activeColumns.includes('drr') && columnSort.key === 'drr'
                  )?_c('td',[_vm._v(" - ")]):_vm._e(),(
                    _vm.activeColumns.includes('cpl') && columnSort.key === 'cpl'
                  )?_c('td',[_vm._v(" "+_vm._s(_vm.renderPrice(Math.round(_vm.total.cpl)))+" ")]):_vm._e(),(
                    _vm.activeColumns.includes('cpo') && columnSort.key === 'cpo'
                  )?_c('td',[_vm._v(" "+_vm._s(_vm.renderPrice(Math.round(_vm.total.cpo)))+" ")]):_vm._e(),(
                    _vm.activeColumns.includes('cpm') && columnSort.key === 'cpm'
                  )?_c('td',[_vm._v(" "+_vm._s(_vm.renderPrice(Math.round(_vm.total.cpm)))+" ")]):_vm._e(),(
                    _vm.activeColumns.includes('working_days') &&
                    columnSort.key === 'working_days'
                  )?_c('td',[_vm._v(" - ")]):_vm._e(),(
                    _vm.activeColumns.includes('limit_consumption') &&
                    columnSort.key === 'limit_consumption'
                  )?_c('td',[_vm._v(" "+_vm._s(_vm.total.limit_consumption)+" ")]):_vm._e()]}),_c('td',{staticClass:"table-v2-btn-settings_icon"})],2)]:_c('tr',[_c('td',{staticClass:"text-center",attrs:{"colspan":"22"}},[_vm._v("Загрузка...")])])],2)]):_c('div',[_c('NoData')],1)]):_vm._e()],1):_c('PartEmpty',{attrs:{"notSuitableTariff":!_vm.hasCompaniesSuitableSubscribtion}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticStyle:{"max-width":"120px","min-width":"100px","left":"184px","box-shadow":"inset -8px 0 8px -8px rgba(47, 10, 73, 0.12)"},attrs:{"colspan":"2"}},[_c('span',{staticClass:"total_text"},[_vm._v("Итог")])])
}]

export { render, staticRenderFns }