<template>
  <div class="auth-card">
    <div class="title mb-4">
      Забыли пароль?
    </div>

    <validation-observer ref="observer">
        <b-form @submit.prevent="forget">
            <validation-provider
                    name="E-mail"
                    :rules="{ required: true, email: true }"
                    v-slot="validationContext"
            >
                <b-form-group class="mb-3">
                    <b-form-input v-model="email" placeholder="E-mail" :state="getValidationState(validationContext)"/>

                    <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                </b-form-group>
            </validation-provider>

            <b-btn @click="forget" :disabled="processing" pill class="mt-4 w-100 py-2" variant="wblead">Восстановить пароль</b-btn>
        </b-form>
    </validation-observer>

    <div class="no-account mt-5 text-center">
      Нет аккаунта? <a @click.prevent="$router.push({ name: 'auth.register' })" href="/auth/register">Зарегистрироваться</a>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'ForgetView',

  data() {
    return {
      processing: false,
      email: null
    }
  },

  methods: {
    async forget() {
      try {
        this.processing = true

        if (await this.$refs.observer.validate()) {
          await axios.post(process.env.VUE_APP_API_URL + '/auth/password_reset/', {
            email: this.email
          })
        }
      } catch (e) {
        console.error(e)
      } finally {
        this.processing = false
      }
    }
  }
}
</script>
