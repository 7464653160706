import { NOT_SUITABLE_TARIFF } from '@/constants/error-responses';
import axios from 'axios';

const state = {
  companiesAdvertising: [],
  total: {},
  hasSuitableSubscribtion: true,
};

const getters = {
  companiesAdvertising: (state) => state.companiesAdvertising,
  total: (state) => state.total,
  hasCompaniesSuitableSubscribtion: (state) => state.hasSuitableSubscribtion,
};

const mutations = {
  SET_COMPANIES_ADVERTISING(state, payload) {
    state.companiesAdvertising = payload;
  },

  SET_TOTAL(state, value) {
    state.total = value;
  },

  SET_SUITABLE_SUBSCRIPTION(state, value) {
    state.hasSuitableSubscribtion = value;
  },
};

const actions = {
  async getCompaniesAdvertising({ commit }, payload) {
    try {
      let url = `/new-reports/comm_rk/`;

      const response = await axios({
        url: url,
        method: 'POST',
        data: {
          ...payload.params,
        },
      });

      const data = response.data;
      commit('SET_COMPANIES_ADVERTISING', data.data);
      commit('SET_TOTAL', data.totals);
    } catch (error) {
      console.error(error);
      commit('SET_COMPANIES_ADVERTISING', []);
      commit('SET_TOTAL', {});
      commit(
        'SET_SUITABLE_SUBSCRIPTION',
        error.response.data.detail !== NOT_SUITABLE_TARIFF
      );
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
