<template>
  <div class="d-inline filterAndSortModal cursor-pointer" @click="callHandler">
    <div class="d-inline"
         @click="openHandler"
    >
      <img
          v-if="!isUsed"
          height="18"
          src="@/assets/img/Funnel.svg"
      />
      <img v-else src="@/assets/img/Funnel-active.svg" alt="" />

      <b-dropdown id="dropdown-form" ref="dropdown" offset="25" @hide="onDropdownHide"
                  v-if="isActive"
      >
        <template #button-content class="btn-modal"> </template>
        <b-dropdown-form style="width: 260px" class="filter-and-sort-modal__dropdown">
          <div class="wrap_sort">
            <a @click="sort(true)">
              <img src="@/assets/img/SortAsc.svg" alt=""> Сортировка от А до Я
            </a>
            <a @click="sort(false)">
              <img src="@/assets/img/SortDesc.svg" alt="">Сортировка от Я до А
            </a>
          </div>

          <div class="input-container">
            <b-icon icon="search" class="input-icon input-container__search"></b-icon>
            <b-form-input
                style="width: 215px"
                v-model="searchValue"
                @input="searchHandler"
                class="input-search"
                placeholder="Поиск"
            ></b-form-input>
          </div>

          <div class="checkboxes-content" @click.stop>
            <b-form-checkbox
                id="checkbox-1"
                class="modal-checkbox"
                @change="selectHandler(listItems)"
                v-model="checkedAll"
            >
              Все
            </b-form-checkbox>
            <b-form-checkbox
                v-for="item in listItems" :key="item.text"
                v-model="item.isChecked"
                class="modal-checkbox"
                @change="updateCheckedAll"
            >
              {{ item.text }}
            </b-form-checkbox>
          </div>

          <div class="d-flex justify-content-end mt-3">
            <button class="modal-btn-cancel" @click.prevent="clear">
              {{ isUsed ? 'Очистить' : 'Отмена' }}
            </button>
            <button class="ml-2 modal-btn-submit" @click.prevent="submitFilter">
              Применить
            </button>
          </div>
        </b-dropdown-form>
      </b-dropdown>
    </div>
  </div>
</template>

<script>
import {mapActions,mapGetters} from 'vuex'
export default {
  name: "SortBtn",

  props: {
    sortKey: {
      type: String,
    },
    activeSortKey: {
      type: String,
    },
    active_key:{
      type: Object,
    },
    id:{
      type: Object
    },
    items: {
      type: Array,
      required: false
    },
    onUpdateReport: {
      type: Function,
      required: false
    },
    keyItem: {
      type: String,
      required: false
    },
    isUsed: {
      type: Boolean,
      required: false
    },
    clearReport: {
      type: Function,
      required: false
    }
  },

  data() {
    return {
      isActive: false,
      searchValue: "",
      current_active_value:'',
      status:'',
      checkedAll: false,
      listItems: [],
      filteredItems: [],
      bufferItems: []
    };
  },
  watch: {
    'items': {
      handler(newVal) {
        this.bufferItems = newVal.slice();
        this.listItems = newVal.slice();
        this.filteredItems = this.listItems.slice();
        this.updateCheckedAll();
      }
    }
  },

  methods: {
    ...mapActions(['getSortItems']),

    clear() {
      if(!this.isUsed) this.closeModal();
      else this.clearReport(this.keyItem);
    },

    updateCheckedAll() {
      let index = 0;
      for(let item of this.listItems) {
        if(item.isChecked) index++;
      }

      if(index === this.listItems.length) this.checkedAll = true;
      else this.checkedAll = false;
    },

    sort(value) {
      this.isAsc = value
      this.$emit('sort', this.sortKey, this.isAsc)
    },
    async callHandler(){
      await this.getSortItems();
    },
    openHandler() {
      if(this.isActive) return false;

      this.isActive = true;
      this.$emit("activeKeyHandler",this.active_key);

      setTimeout(() => {
        if(this.$refs.dropdown) {
          this.$refs.dropdown.show(true);
        }

      }, 400);
    },
    selectHandler() {
      this.listItems = this.listItems.map((item) => {
        item.isChecked = this.checkedAll;
        return item;
      });
    },

    submitFilter() {
      if(this.onUpdateReport) {
        const list = this.listItems.filter((item) => {
          if(item.isChecked) return item;
        })


        this.onUpdateReport(this.keyItem, this.listItems.length === list.length ? [] : list);

      } else this.$emit('getReports',this.active_key);
    },

    searchHandler() {
      this.listItems = this.filteredItems.filter((item) => {
        if (this.searchValue === null || this.searchValue === '') return item;

        if (item.text.toLowerCase().indexOf(this.searchValue) > -1) return item;
      })
    },

    closeModal() {
      this.$refs.dropdown.hide(true);
    },

    onDropdownHide() {
      this.$emit("activeKeyHandler",this.active_key);
      this.isActive = !this.isActive
      this.$refs.dropdown.hide(true);
    },
  },

  mounted() {
    if(this.items)
      this.listItems = this.items.slice();
  },

  computed:{
    ...mapGetters(['sort_items'])
  }
};
</script>
<style>
.filterAndSortModal .dropdown-toggle {
  background: transparent !important;
  border: none !important;
  height: 18px;
  width: 18px;
}
.filterAndSortModal .dropdown-menu {
  box-shadow: 0 0 8px 0 #2f0a491f;
  border: none;
}
.filterAndSortModal #dropdown-form__BV_toggle_ {
  visibility: hidden;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  border-color: #481173;
  background-color: #481173;
}
.dropdown-toggle::after {
  visibility: hidden;
}
</style>

<style lang="scss">

.form-control {
  border: 1px solid #f1f1f1;
}
.input-search {
  background: white !important;
  border: 1px solid rgba(0,0,0,.15) !important;
  height: 42px !important;
  width: 240px;
  border-radius: 10px !important;
  color: #908F90 !important;
  padding: 0 12px 0 37px !important;
}

.input-search:hover, .input-search:active, .input-search:focus {
  box-shadow: none !important;
}

.input-container {
  position: relative;
  display: flex;
  align-items: center;
}

.input-icon {
  position: absolute;
  top: 50%;
  left: 12px; /* Adjust the left value to position the icon as desired */
  font-size: 16px;
  color: #908F91; /* You can change the color of the icon to match the input placeholder */
}
.checkboxes-content {
  height: 200px;
  border: 1px solid #f1f1f1;
  margin-top: 10px;
  padding: 10px;
  border-radius: 10px;
  overflow-y: auto;
}
.modal-checkbox {
  margin-top: 5px;
  color: #908f90;
}

.filterAndSortModal {
  & .modal-checkbox {
    &:before {
      top: 3px;
    }
  }

  & .custom-control-label {
    &:before {
      top: 3px;
    }

    &:after {
      top: 3px;
    }
  }
}

.modal-checkbox .custom-control-label {
  padding: 5px;
}
.modal-btn-cancel {
  border-radius: 10px;
  color: #908f90;
  font-size: 14px;
  background: white;
  height: 30px;
  width: 80px;
  border: 1px solid #908f90;
}
.modal-btn-submit {
  border-radius: 10px;
  color: white;
  background: #481173;
  height: 30px;
  font-size: 14px;
  width: 100px;
  border: none;
}

.wrap_sort {
  margin-bottom: 1rem;

  & a {
    display: inline-block;

    &:first-child {
      margin-bottom: .5rem;
    }
  }
}

.filter-and-sort-modal__dropdown {
  padding: 12px 0;
  z-index: 1000;
}

table thead th {
  border: none !important;
  box-shadow: none !important;
  background: #FCF8FF !important;
}
</style>
