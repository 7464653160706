<script setup>
import LandingAdvantage from "@/components/landing/LandingAdvantage.vue";

const advantages = [
  {
    key: 1,
    category: 'Система отчетов',
    title: 'Управление',
    text: 'WBLeads - позволяет продавцу управлять рекламным кабинетом так, чтобы выжать из каждого потраченного рубля максимум прибыли.'
  },
  {
    key: 2,
    category: 'Таблицы в одном месте',
    title: 'Статистика',
    text: 'Больше не нужно сводить рекламную статистику руками - WBLeads сэкономит сотни часов, которые можно потратить на более точечное управление.'
  },
  {
    key: 3,
    category: 'Дашборды',
    title: 'Результаты',
    text: 'Прозрачные результаты по каждой рекламной кампании и эффективность рекламы по каждому артикулу, это позволяет повысить рентабельность и строить прибыльный бизнес.'
  }
];
</script>

<template>
  <div id="Advantages" class="landing_advantages">
    <h4 class="landing_advantages__title text-center">
      Преимущества сервиса WBLeads
    </h4>

    <p class="landing_advantages__description text-center">
      Узнайте почему стоит выбрать именно нас
    </p>

    <div class="landing_advantages__items">
      <LandingAdvantage
          v-for="advantage in advantages"
          :key="advantage.key"
          :advantage="advantage"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
.landing_advantages {
  padding: 80px 0;

  &__title {
    margin-bottom: 10px;
    font-size: 50px;
    font-weight: 500;
    line-height: 130%;
  }

  &__description {
    margin-bottom: 40px;
    font-size: 18px;
    font-weight: 400;
  }

  &__items {
    display: flex;
    gap: 1rem;
    justify-content: space-around;
  }
}
</style>
