<template>
  <div class="productsAdvertising">
    <ModalSetting
      ref="RefModalSetting"
      :pageName="pageName"
      :columns="columnsForSort"
      :saved="setSettings"
      columnName="text"
    />
    <div class="productsAdvertising-filter wrap_page_filter">
      <filterAndSort
        @getReports="getReports"
        @setFilters="setFilters"
        @dateFilterHandler="dateFilterHandler"
        v-if="isShowCompFilter"
        :dateStart="from"
        :dateEnd="to"
      ></filterAndSort>
    </div>

    <div v-if="isHasSubscribe && hasProductsSuitableSubscribtion">
      <div
        style="overflow-x: scroll"
        :style="'height: ' + tableHeight + 'px;'"
        class="productsAdvertising-table"
      >
        <b-skeleton-table
          :rows="5"
          v-if="loading"
          :columns="4"
          :table-props="{ bordered: true, striped: true }"
        ></b-skeleton-table>
        <div v-if="!loading">
          <table
            v-if="productsAdvertising.length > 0"
            bordered
            class="product-advertising-table table table-v2"
          >
            <thead>
              <tr class="wb-table-head-without-underline">
                <th
                  scope="col"
                  colspan="2"
                  class="text-left pl-4 col-settings sticky-col"
                  style="
                    max-width: 480px;
                    position: sticky;
                    z-index: 999 !important;
                    background: #fcf8ff !important;
                  "
                >
                  <b-btn
                    class="btn_icon btn_settings_columns table-v2-btn-settings"
                    @click="openModalSettings"
                  >
                    <b-icon icon="gear-fill" aria-hidden="true"></b-icon>
                  </b-btn>
                </th>

                <th scope="col right-box-shadow" colspan="3"></th>

                <th
                  v-for="(columnSort, columnIndex) in columnsForSort"
                  :key="columnSort.key"
                  scope="col"
                  :colspan="renderLengthColumns(columnSort)"
                  class="text-center table-top-label right-border"
                  v-if="activeColumns.includes(columnSort.key)"
                  @drop="onDrop(columnIndex, 'first')"
                  @dragover.prevent
                  @dragenter.prevent
                >
                  <div
                    draggable
                    @dragstart="startDrag(columnSort, columnIndex, 'first')"
                  >
                    {{ columnSort.text }}
                  </div>
                </th>
              </tr>

              <tr class="tr-second">
                <th
                  scope="col"
                  class="sticky-col table-label"
                  style="
                    min-width: 120px;
                    background: #fcf8ff !important;
                    position: sticky;
                    z-index: 999 !important;
                  "
                >
                  Фото
                </th>
                <th
                  scope="col"
                  :class="[
                    row_keys.id_key
                      ? ' right-box-shadow table-label'
                      : 'sticky-col right-box-shadow table-label',
                  ]"
                  style="
                    max-width: 200px;
                    left: 200px;
                    background: #fcf8ff !important;
                    position: sticky;
                    z-index: 999 !important;
                  "
                >
                  Артикул WB
                </th>
                <th
                  scope="col"
                  :class="[
                    row_keys.id_key
                      ? ' right-box-shadow table-label'
                      : ' table-label',
                  ]"
                  style="
                    max-width: 290px;
                    background: #fcf8ff !important;
                    position: sticky;
                    z-index: 999 !important;
                    left: 400px !important;
                  "
                >
                  Артикул поставщика
                  <FilterAndSortModal
                    :active_key="{ id_key: row_keys.article_key }"
                    @activeKeyHandler="activeKeyHandler"
                    :active-sort-key="sortKey"
                    sort-key="sa_name"
                    @sort="sort"
                    :items="setFilterContainer('sa_name')"
                    :isUsed="bufferQueryArray.sa_name !== null"
                    :onUpdateReport="onUpdateReport"
                    :clearReport="clearReport"
                    keyItem="sa_name"
                  />
                </th>
                <th
                  scope="col"
                  :class="[
                    row_keys.id_key
                      ? ' right-box-shadow table-label'
                      : ' table-label',
                  ]"
                  style="min-width: 140px; background: #fcf8ff !important"
                >
                  Предмет
                  <FilterAndSortModal
                    :active_key="{ id_key: row_keys.subject_key }"
                    @activeKeyHandler="activeKeyHandler"
                    sort-key="subject"
                    :active-sort-key="sortKey"
                    @sort="sort"
                    :items="setFilterContainer('subject')"
                    :isUsed="bufferQueryArray.subject !== null"
                    :onUpdateReport="onUpdateReport"
                    :clearReport="clearReport"
                    keyItem="subject"
                  />
                </th>
                <th
                  scope="col"
                  :class="[
                    row_keys.id_key
                      ? ' right-box-shadow table-label'
                      : ' table-label',
                  ]"
                  style="min-width: 140px; background: #fcf8ff !important"
                >
                  Бренд
                  <FilterAndSortModal
                    :active_key="{ id_key: row_keys.brand_key }"
                    @activeKeyHandler="activeKeyHandler"
                    :active-sort-key="sortKey"
                    sort-key="brand"
                    @sort="sort"
                    :items="setFilterContainer('brand')"
                    :isUsed="bufferQueryArray.brand !== null"
                    :onUpdateReport="onUpdateReport"
                    :clearReport="clearReport"
                    keyItem="brand"
                  />
                </th>

                <template
                  v-for="(columnSort, columnSortIndex) in columnsForSort"
                >
                  <template v-if="columnSort.key === 'card'">
                    <template v-for="(cellItem, cellItemIndex) in listCard">
                      <th
                        scope="col"
                        class="table-label first"
                        :style="cellItem.style"
                        v-if="activeColumns.includes('auto_company')"
                        @drop="onDrop(cellItemIndex, 'second')"
                        @dragover.prevent
                        @dragenter.prevent
                        :title="cellItem.title"
                      >
                        <div
                          draggable
                          @dragstart="
                            startDrag(
                              cellItem,
                              cellItemIndex,
                              'second',
                              listAutoCompany
                            )
                          "
                        >
                          {{ cellItem.name }}
                          <sort-btn
                            :sort-key="cellItem.key"
                            :active-sort-key="sortKey"
                            :isAscDefault="sortIsAsc"
                            @sort="sort"
                          />
                        </div>
                      </th>
                    </template>
                  </template>

                  <template v-if="columnSort.key === 'search'">
                    <template v-for="(cellItem, cellItemIndex) in listSearch">
                      <th
                        scope="col"
                        class="table-label first"
                        :style="cellItem.style"
                        v-if="activeColumns.includes('search')"
                        @drop="onDrop(cellItemIndex, 'second')"
                        @dragover.prevent
                        @dragenter.prevent
                        :title="cellItem.title"
                      >
                        <div
                          draggable
                          @dragstart="
                            startDrag(
                              cellItem,
                              cellItemIndex,
                              'second',
                              listSearch
                            )
                          "
                        >
                          {{ cellItem.name }}
                          <sort-btn
                            :sort-key="cellItem.key"
                            :active-sort-key="sortKey"
                            :isAscDefault="sortIsAsc"
                            @sort="sort"
                          />
                        </div>
                      </th>
                    </template>
                  </template>

                  <template v-if="columnSort.key === 'catalog'">
                    <template v-for="(cellItem, cellItemIndex) in listCatalog">
                      <th
                        scope="col"
                        class="table-label first"
                        :style="cellItem.style"
                        v-if="activeColumns.includes('catalog')"
                        @drop="onDrop(cellItemIndex, 'second')"
                        @dragover.prevent
                        @dragenter.prevent
                        :title="cellItem.title"
                      >
                        <div
                          draggable
                          @dragstart="
                            startDrag(
                              cellItem,
                              cellItemIndex,
                              'second',
                              listCatalog
                            )
                          "
                        >
                          {{ cellItem.name }}
                          <sort-btn
                            :sort-key="cellItem.key"
                            :active-sort-key="sortKey"
                            :isAscDefault="sortIsAsc"
                            @sort="sort"
                          />
                        </div>
                      </th>
                    </template>
                  </template>

                  <template v-if="columnSort.key === 'auto_company'">
                    <template
                      v-for="(cellItem, cellItemIndex) in listAutoCompany"
                    >
                      <th
                        scope="col"
                        class="table-label first"
                        :style="cellItem.style"
                        v-if="activeColumns.includes('auto_company')"
                        @drop="onDrop(cellItemIndex, 'second')"
                        @dragover.prevent
                        @dragenter.prevent
                        :title="cellItem.title"
                      >
                        <div
                          draggable
                          @dragstart="
                            startDrag(
                              cellItem,
                              cellItemIndex,
                              'second',
                              listAutoCompany
                            )
                          "
                        >
                          {{ cellItem.name }}
                          <sort-btn
                            :sort-key="cellItem.key"
                            :active-sort-key="sortKey"
                            :isAscDefault="sortIsAsc"
                            @sort="sort"
                          />
                        </div>
                      </th>
                    </template>
                  </template>

                  <template v-if="columnSort.key === 'search_and_catalog'">
                    <template
                      v-for="(cellItem, cellItemIndex) in listSearchCatalog"
                    >
                      <th
                        scope="col"
                        class="table-label first"
                        :style="cellItem.style"
                        v-if="activeColumns.includes('search_and_catalog')"
                        @drop="onDrop(cellItemIndex, 'second')"
                        @dragover.prevent
                        @dragenter.prevent
                        :title="cellItem.title"
                      >
                        <div
                          draggable
                          @dragstart="
                            startDrag(
                              cellItem,
                              cellItemIndex,
                              'second',
                              listSearchCatalog
                            )
                          "
                        >
                          {{ cellItem.name }}
                          <sort-btn
                            :sort-key="cellItem.key"
                            :active-sort-key="sortKey"
                            :isAscDefault="sortIsAsc"
                            @sort="sort"
                          />
                        </div>
                      </th>
                    </template>
                  </template>

                  <template v-if="columnSort.key === 'total'">
                    <template v-for="(cellItem, cellItemIndex) in listTotal">
                      <th
                        scope="col"
                        class="table-label first"
                        :style="cellItem.style"
                        v-if="activeColumns.includes('total')"
                        @drop="onDrop(cellItemIndex, 'second')"
                        @dragover.prevent
                        @dragenter.prevent
                        :title="cellItem.title"
                      >
                        <div
                          draggable
                          @dragstart="
                            startDrag(
                              cellItem,
                              cellItemIndex,
                              'second',
                              listTotal
                            )
                          "
                        >
                          {{ cellItem.name }}
                          <sort-btn
                            :sort-key="cellItem.key"
                            :active-sort-key="sortKey"
                            :isAscDefault="sortIsAsc"
                            @sort="sort"
                          />
                        </div>
                      </th>
                    </template>
                  </template>

                  <template v-if="columnSort.key === 'sales_report'">
                    <template
                      v-for="(cellItem, cellItemIndex) in listSalesReport"
                    >
                      <th
                        scope="col"
                        class="table-label first"
                        :style="cellItem.style"
                        v-if="activeColumns.includes('sales_report')"
                        @drop="onDrop(cellItemIndex, 'second')"
                        @dragover.prevent
                        @dragenter.prevent
                        :title="cellItem.title"
                      >
                        <div
                          draggable
                          @dragstart="
                            startDrag(
                              cellItem,
                              cellItemIndex,
                              'second',
                              listSalesReport
                            )
                          "
                        >
                          {{ cellItem.name }}
                          <sort-btn
                            :sort-key="cellItem.key"
                            :active-sort-key="sortKey"
                            :isAscDefault="sortIsAsc"
                            @sort="sort"
                          />
                        </div>
                      </th>
                    </template>
                  </template>
                </template>
              </tr>
            </thead>

            <tbody>
              <template v-if="!loading">
                <tr v-for="(item, index) in productsAdvertising" :key="item.id">
                  <td
                    class="sticky-col td_product_images"
                    style="max-width: 120px"
                  >
                    <img
                      :src="item.image"
                      alt=""
                      class="table_img table-v2-img"
                      @mouseover="onMouseOverImg(index, item)"
                      @mouseleave="onMouseLeaveImg()"
                    />
                    <ImgPreview
                      :src="activeImg.src"
                      :alt="activeImg.brand"
                      :title="activeImg.brand"
                      v-if="activeImg && activeImg.index === index"
                    />
                  </td>

                  <td
                    class="sticky-col"
                    style="max-width: 130px; left: 200px; z-index: 9"
                  >
                    <router-link
                      :to="'/reports/products-statistic/' + item.nm_id"
                      class="wb-table-link"
                    >
                      {{ item.nm_id }}
                    </router-link>
                  </td>

                  <td
                    class="sticky-col"
                    style="
                      max-width: 290px;
                      left: 400px !important;
                      overflow: hidden;
                      box-shadow: inset -8px 0 8px -8px rgba(47, 10, 73, 0.12);
                    "
                  >
                    <div class="cell-text">
                      {{ item.sa_name }}
                    </div>
                  </td>

                  <td style="max-width: 140px">
                    <div class="cell-text">
                      {{ item.subject }}
                    </div>
                  </td>

                  <td>
                    <div class="cell-text">
                      {{ item.brand }}
                    </div>
                  </td>

                  <template v-for="columnSort in columnsForSort">
                    <template v-if="columnSort.key === 'card'">
                      <template v-for="cellItem in listCard">
                        <td
                          v-if="
                            cellItem.key === 'card.consumption' &&
                            activeColumns.includes('card')
                          "
                          class="td-dynamic-card first"
                          :class="{}"
                        >
                          <div>
                            {{
                              renderPrice(Math.round(item.card?.consumption))
                            }}
                          </div>
                        </td>
                        <td
                          v-if="
                            cellItem.key === 'card.cart' &&
                            activeColumns.includes('card')
                          "
                          class="td-dynamic-card"
                        >
                          {{ renderPrice(Math.round(item.card?.cart)) }}
                        </td>
                        <td
                          v-if="
                            cellItem.key === 'card.order' &&
                            activeColumns.includes('card')
                          "
                          class="td-dynamic-card"
                        >
                          {{ renderPrice(Math.round(item.card?.order)) }}
                        </td>
                        <td
                          v-if="
                            cellItem.key === 'card.cpl' &&
                            activeColumns.includes('card')
                          "
                          class="td-dynamic-card"
                        >
                          {{ renderPrice(Math.round(item.card?.cpl)) }}
                        </td>
                        <td
                          class="td-dynamic-card"
                          v-if="
                            cellItem.key === 'card.cpo' &&
                            activeColumns.includes('card')
                          "
                        >
                          {{ renderPrice(Math.round(item.card?.cpo)) }}
                        </td>
                      </template>
                    </template>

                    <template v-if="columnSort.key === 'search'">
                      <template v-for="cellItem in listSearch">
                        <td
                          v-if="
                            cellItem.key === 'search.consumption' &&
                            activeColumns.includes('search')
                          "
                          class="td-dynamic--search first"
                        >
                          {{
                            renderPrice(Math.round(item.search?.consumption))
                          }}
                        </td>
                        <td
                          v-if="
                            cellItem.key === 'search.cart' &&
                            activeColumns.includes('search')
                          "
                          class="td-dynamic--search"
                        >
                          {{ renderPrice(Math.round(item.search?.cart)) }}
                        </td>
                        <td
                          v-if="
                            cellItem.key === 'search.order' &&
                            activeColumns.includes('search')
                          "
                          class="td-dynamic--search"
                        >
                          {{ renderPrice(Math.round(item.search?.order)) }}
                        </td>
                        <td
                          v-if="
                            cellItem.key === 'search.cpl' &&
                            activeColumns.includes('search')
                          "
                          class="td-dynamic--search"
                        >
                          {{ renderPrice(Math.round(item.search?.cpl)) }}
                        </td>
                        <td
                          class="td-dynamic--search"
                          v-if="
                            cellItem.key === 'search.cpo' &&
                            activeColumns.includes('search')
                          "
                        >
                          {{ renderPrice(Math.round(item.search?.cpo)) }}
                        </td>
                      </template>
                    </template>

                    <template v-if="columnSort.key === 'catalog'">
                      <template v-for="cellItem in listCatalog">
                        <td
                          v-if="
                            cellItem.key === 'catalog.consumption' &&
                            activeColumns.includes('catalog')
                          "
                          class="td-dynamic-catalog first"
                        >
                          {{
                            renderPrice(Math.round(item.catalog?.consumption))
                          }}
                        </td>
                        <td
                          v-if="
                            cellItem.key === 'catalog.cart' &&
                            activeColumns.includes('catalog')
                          "
                          class="td-dynamic-catalog"
                        >
                          {{ renderPrice(Math.round(item.catalog?.cart)) }}
                        </td>
                        <td
                          v-if="
                            cellItem.key === 'catalog.order' &&
                            activeColumns.includes('catalog')
                          "
                          class="td-dynamic-catalog"
                        >
                          {{ renderPrice(Math.round(item.catalog?.order)) }}
                        </td>
                        <td
                          v-if="
                            cellItem.key === 'catalog.cpl' &&
                            activeColumns.includes('catalog')
                          "
                          class="td-dynamic-catalog"
                        >
                          {{ renderPrice(Math.round(item.catalog?.cpl)) }}
                        </td>
                        <td
                          v-if="
                            cellItem.key === 'catalog.cpo' &&
                            activeColumns.includes('catalog')
                          "
                          class="td-dynamic-catalog"
                        >
                          {{ renderPrice(Math.round(item.catalog?.cpo)) }}
                        </td>
                      </template>
                    </template>

                    <template v-if="columnSort.key === 'auto_company'">
                      <template v-for="cellItem in listAutoCompany">
                        <td
                          v-if="
                            cellItem.key === 'auto_company.consumption' &&
                            activeColumns.includes('auto_company')
                          "
                          class="td-dynamic__auto_company first"
                        >
                          {{
                            renderPrice(
                              Math.round(item.auto_company?.consumption)
                            )
                          }}
                        </td>
                        <td
                          v-if="
                            cellItem.key === 'auto_company.cart' &&
                            activeColumns.includes('auto_company')
                          "
                          class="td-dynamic__auto_company"
                        >
                          {{ renderPrice(Math.round(item.auto_company?.cart)) }}
                        </td>
                        <td
                          v-if="
                            cellItem.key === 'auto_company.order' &&
                            activeColumns.includes('auto_company')
                          "
                          class="td-dynamic__auto_company"
                        >
                          {{
                            renderPrice(Math.round(item.auto_company?.order))
                          }}
                        </td>
                        <td
                          v-if="
                            cellItem.key === 'auto_company.cpl' &&
                            activeColumns.includes('auto_company')
                          "
                          class="td-dynamic__auto_company"
                        >
                          {{ renderPrice(Math.round(item.auto_company?.cpl)) }}
                        </td>
                        <td
                          v-if="
                            cellItem.key === 'auto_company.cpo' &&
                            activeColumns.includes('auto_company')
                          "
                          class="td-dynamic__auto_company"
                        >
                          {{ renderPrice(Math.round(item.auto_company?.cpo)) }}
                        </td>
                      </template>
                    </template>

                    <template v-if="columnSort.key === 'search_and_catalog'">
                      <template v-for="cellItem in listSearchCatalog">
                        <td
                          v-if="
                            cellItem.key === 'search_and_catalog.consumption' &&
                            activeColumns.includes('search_and_catalog')
                          "
                          class="td-dynamic-search_and_catalog first"
                        >
                          {{
                            renderPrice(
                              Math.round(item.search_and_catalog?.consumption)
                            )
                          }}
                        </td>

                        <td
                          v-if="
                            cellItem.key === 'search_and_catalog.cart' &&
                            activeColumns.includes('search_and_catalog')
                          "
                          class="td-dynamic-search_and_catalog"
                        >
                          {{
                            renderPrice(
                              Math.round(item.search_and_catalog?.cart)
                            )
                          }}
                        </td>
                        <td
                          v-if="
                            cellItem.key === 'search_and_catalog.order' &&
                            activeColumns.includes('search_and_catalog')
                          "
                          class="td-dynamic-search_and_catalog"
                        >
                          {{
                            renderPrice(
                              Math.round(item.search_and_catalog?.order)
                            )
                          }}
                        </td>
                        <td
                          v-if="
                            cellItem.key === 'search_and_catalog.cpl' &&
                            activeColumns.includes('search_and_catalog')
                          "
                          class="td-dynamic-search_and_catalog"
                        >
                          {{
                            renderPrice(
                              Math.round(item.search_and_catalog?.cpl)
                            )
                          }}
                        </td>
                        <td
                          v-if="
                            cellItem.key === 'search_and_catalog.cpo' &&
                            activeColumns.includes('search_and_catalog')
                          "
                          class="td-dynamic-search_and_catalog"
                        >
                          {{
                            renderPrice(
                              Math.round(item.search_and_catalog?.cpo)
                            )
                          }}
                        </td>
                      </template>
                    </template>

                    <template v-if="columnSort.key === 'total'">
                      <template v-for="cellItem in listTotal">
                        <td
                          v-if="
                            cellItem.key === 'total.advert_drr' &&
                            activeColumns.includes('total')
                          "
                          class="td-dynamic-total"
                        >
                          {{ renderPrice(item.sales_report.advert_drr) }}
                        </td>

                        <td
                          v-if="
                            cellItem.key === 'total.consumption' &&
                            activeColumns.includes('total')
                          "
                          class="td-dynamic-total first"
                        >
                          {{ renderPrice(Math.round(item.total?.consumption)) }}
                        </td>

                        <td
                          v-if="
                            cellItem.key === 'total.cart' &&
                            activeColumns.includes('total')
                          "
                          class="td-dynamic-total"
                        >
                          {{ renderPrice(Math.round(item.total?.cart)) }}
                        </td>

                        <td
                          v-if="
                            cellItem.key === 'total.order' &&
                            activeColumns.includes('total')
                          "
                          class="td-dynamic-total"
                        >
                          {{ renderPrice(Math.round(item.total?.order)) }}
                        </td>

                        <td
                          v-if="
                            cellItem.key === 'total.cpl' &&
                            activeColumns.includes('total')
                          "
                          class="td-dynamic-total"
                        >
                          {{ renderPrice(Math.round(item.total?.cpl)) }}
                        </td>

                        <td
                          class="right-border td-dynamic-total"
                          v-if="
                            cellItem.key === 'total.cpo' &&
                            activeColumns.includes('total')
                          "
                        >
                          {{ renderPrice(Math.round(item.total?.cpo)) }}
                        </td>
                      </template>
                    </template>
                    <template v-if="columnSort.key === 'sales_report'">
                      <template v-for="cellItem in listSalesReport">
                        <td
                          v-if="
                            cellItem.key ===
                              'sales_report.total_ordered_in_pieces' &&
                            activeColumns.includes('sales_report')
                          "
                          class="td-dynamic-sales_report first"
                        >
                          {{
                            renderPrice(
                              Math.round(
                                item.sales_report.total_ordered_in_pieces
                              )
                            )
                          }}
                        </td>

                        <td
                          v-if="
                            cellItem.key ===
                              'sales_report.total_ordered_in_rubles' &&
                            activeColumns.includes('sales_report')
                          "
                          class="td-dynamic-sales_report"
                        >
                          {{
                            renderPrice(
                              Math.round(
                                item.sales_report.total_ordered_in_rubles
                              )
                            )
                          }}
                        </td>

                        <td
                          v-if="
                            cellItem.key === 'sales_report.total_redeemed' &&
                            activeColumns.includes('sales_report')
                          "
                          class="td-dynamic-sales_report"
                        >
                          {{
                            renderPrice(
                              Math.round(item.sales_report.total_redeemed)
                            )
                          }}
                        </td>

                        <td
                          v-if="
                            cellItem.key === 'sales_report.total_to_transfer' &&
                            activeColumns.includes('sales_report')
                          "
                          class="td-dynamic-sales_report"
                        >
                          {{
                            renderPrice(
                              Math.round(item.sales_report.total_to_transfer)
                            )
                          }}
                        </td>

                        <td
                          v-if="
                            cellItem.key === 'sales_report.drr' &&
                            activeColumns.includes('sales_report')
                          "
                          class="td-dynamic-sales_report"
                        >
                          {{ renderValue('drr', item.sales_report.drr) }}
                        </td>
                      </template>
                    </template>
                  </template>
                </tr>

                <tr class="table-row-total">
                  <td
                    class="sticky-col td_product_images"
                    style="min-width: 120px"
                  ></td>

                  <td
                    class="sticky-col"
                    style="max-width: 130px; left: 100px"
                  ></td>

                  <td
                    class="sticky-col"
                    style="
                      box-shadow: inset -8px 0 8px -8px rgba(47, 10, 73, 0.12);
                      left: 206px !important;
                      z-index: 100 !important;
                    "
                  >
                    Итого:
                  </td>

                  <td></td>

                  <td style="max-width: 140px"></td>

                  <template v-for="columnSort in columnsForSort">
                    <template v-if="columnSort.key === 'card'">
                      <template v-for="cellItem in listCard">
                        <td
                          v-if="
                            cellItem.key === 'card.consumption' &&
                            activeColumns.includes('card')
                          "
                          class="first"
                        >
                          {{
                            renderPrice(Math.round(totals.cart?.consumption))
                          }}
                        </td>
                        <td
                          v-if="
                            cellItem.key === 'card.cart' &&
                            activeColumns.includes('card')
                          "
                        >
                          {{ renderPrice(Math.round(totals.cart?.cart)) }}
                        </td>
                        <td
                          v-if="
                            cellItem.key === 'card.order' &&
                            activeColumns.includes('card')
                          "
                        >
                          {{ renderPrice(Math.round(totals.cart?.order)) }}
                        </td>
                        <td
                          v-if="
                            cellItem.key === 'card.cpl' &&
                            activeColumns.includes('card')
                          "
                        >
                          {{ renderPrice(Math.round(totals.cart?.cpl)) }}
                        </td>
                        <td
                          class="right-border"
                          v-if="
                            cellItem.key === 'card.cpo' &&
                            activeColumns.includes('card')
                          "
                        >
                          {{ renderPrice(Math.round(totals.cart?.cpo)) }}
                        </td>
                      </template>
                    </template>

                    <template v-if="columnSort.key === 'search'">
                      <template v-for="cellItem in listSearch">
                        <td
                          v-if="
                            cellItem.key === 'search.consumption' &&
                            activeColumns.includes('search')
                          "
                          class="first"
                        >
                          {{
                            renderPrice(Math.round(totals.search?.consumption))
                          }}
                        </td>
                        <td
                          v-if="
                            cellItem.key === 'search.cart' &&
                            activeColumns.includes('search')
                          "
                        >
                          {{ renderPrice(Math.round(totals.search?.cart)) }}
                        </td>
                        <td
                          v-if="
                            cellItem.key === 'search.order' &&
                            activeColumns.includes('search')
                          "
                        >
                          {{ renderPrice(Math.round(totals.search?.order)) }}
                        </td>
                        <td
                          v-if="
                            cellItem.key === 'search.cpl' &&
                            activeColumns.includes('search')
                          "
                        >
                          {{ renderPrice(Math.round(totals.search?.cpl)) }}
                        </td>
                        <td
                          class="right-border"
                          v-if="
                            cellItem.key === 'search.cpo' &&
                            activeColumns.includes('search')
                          "
                        >
                          {{ renderPrice(Math.round(totals.search?.cpo)) }}
                        </td>
                      </template>
                    </template>

                    <template v-if="columnSort.key === 'catalog'">
                      <template v-for="cellItem in listCatalog">
                        <td
                          v-if="
                            cellItem.key === 'catalog.consumption' &&
                            activeColumns.includes('catalog')
                          "
                          class="first"
                        >
                          {{
                            renderPrice(Math.round(totals.catalog?.consumption))
                          }}
                        </td>
                        <td
                          v-if="
                            cellItem.key === 'catalog.cart' &&
                            activeColumns.includes('catalog')
                          "
                        >
                          {{ renderPrice(Math.round(totals.catalog?.cart)) }}
                        </td>
                        <td
                          v-if="
                            cellItem.key === 'catalog.order' &&
                            activeColumns.includes('catalog')
                          "
                        >
                          {{ renderPrice(Math.round(totals.catalog?.order)) }}
                        </td>
                        <td
                          v-if="
                            cellItem.key === 'catalog.cpl' &&
                            activeColumns.includes('catalog')
                          "
                        >
                          {{ renderPrice(Math.round(totals.catalog?.cpl)) }}
                        </td>
                        <td
                          class="right-border"
                          v-if="
                            cellItem.key === 'catalog.cpo' &&
                            activeColumns.includes('catalog')
                          "
                        >
                          {{ renderPrice(Math.round(totals.catalog?.cpo)) }}
                        </td>
                      </template>
                    </template>

                    <template v-if="columnSort.key === 'auto_company'">
                      <template v-for="cellItem in listAutoCompany">
                        <td
                          v-if="
                            cellItem.key === 'auto_company.consumption' &&
                            activeColumns.includes('auto_company')
                          "
                          class="first"
                        >
                          {{
                            renderPrice(
                              Math.round(totals.auto_company?.consumption)
                            )
                          }}
                        </td>
                        <td
                          v-if="
                            cellItem.key === 'auto_company.cart' &&
                            activeColumns.includes('auto_company')
                          "
                        >
                          {{
                            renderPrice(Math.round(totals.auto_company?.cart))
                          }}
                        </td>
                        <td
                          v-if="
                            cellItem.key === 'auto_company.order' &&
                            activeColumns.includes('auto_company')
                          "
                        >
                          {{
                            renderPrice(Math.round(totals.auto_company?.order))
                          }}
                        </td>
                        <td
                          v-if="
                            cellItem.key === 'auto_company.cpl' &&
                            activeColumns.includes('auto_company')
                          "
                        >
                          {{
                            renderPrice(Math.round(totals.auto_company?.cpl))
                          }}
                        </td>
                        <td
                          v-if="
                            cellItem.key === 'auto_company.cpo' &&
                            activeColumns.includes('auto_company')
                          "
                        >
                          {{
                            renderPrice(Math.round(totals.auto_company?.cpo))
                          }}
                        </td>
                      </template>
                    </template>

                    <template v-if="columnSort.key === 'search_and_catalog'">
                      <template v-for="cellItem in listSearchCatalog">
                        <td
                          v-if="
                            cellItem.key === 'search_and_catalog.consumption' &&
                            activeColumns.includes('search_and_catalog')
                          "
                          class="first"
                        >
                          {{
                            renderPrice(
                              Math.round(totals.search_and_catalog?.consumption)
                            )
                          }}
                        </td>
                        <td
                          v-if="
                            cellItem.key === 'search_and_catalog.cart' &&
                            activeColumns.includes('search_and_catalog')
                          "
                        >
                          {{
                            renderPrice(
                              Math.round(totals.search_and_catalog?.cart)
                            )
                          }}
                        </td>
                        <td
                          v-if="
                            cellItem.key === 'search_and_catalog.order' &&
                            activeColumns.includes('search_and_catalog')
                          "
                        >
                          {{
                            renderPrice(
                              Math.round(totals.search_and_catalog?.order)
                            )
                          }}
                        </td>
                        <td
                          v-if="
                            cellItem.key === 'search_and_catalog.cpl' &&
                            activeColumns.includes('search_and_catalog')
                          "
                        >
                          {{
                            renderPrice(
                              Math.round(totals.search_and_catalog?.cpl)
                            )
                          }}
                        </td>
                        <td
                          v-if="
                            cellItem.key === 'search_and_catalog.cpo' &&
                            activeColumns.includes('search_and_catalog')
                          "
                        >
                          {{
                            renderPrice(
                              Math.round(totals.search_and_catalog?.cpo)
                            )
                          }}
                        </td>
                      </template>
                    </template>

                    <template v-if="columnSort.key === 'total'">
                      <template v-for="cellItem in listTotal">
                        <td
                          v-if="
                            cellItem.key === 'total.consumption' &&
                            activeColumns.includes('total')
                          "
                          class="first"
                        >
                          {{
                            renderPrice(Math.round(totals.total?.consumption))
                          }}
                        </td>
                        <td
                          v-if="
                            cellItem.key === 'total.cart' &&
                            activeColumns.includes('total')
                          "
                        >
                          {{ renderPrice(Math.round(totals.total?.cart)) }}
                        </td>
                        <td
                          v-if="
                            cellItem.key === 'total.order' &&
                            activeColumns.includes('total')
                          "
                        >
                          {{ renderPrice(Math.round(totals.total?.order)) }}
                        </td>
                        <td
                          v-if="
                            cellItem.key === 'total.cpl' &&
                            activeColumns.includes('total')
                          "
                        >
                          {{ renderPrice(Math.round(totals.total?.cpl)) }}
                        </td>
                        <td
                          class="right-border"
                          v-if="
                            cellItem.key === 'total.advert_drr' &&
                            activeColumns.includes('total')
                          "
                        ></td>
                        <td
                          class="right-border"
                          v-if="
                            cellItem.key === 'total.cpo' &&
                            activeColumns.includes('total')
                          "
                        >
                          {{ renderPrice(Math.round(totals.total?.cpo)) }}
                        </td>
                      </template>
                    </template>

                    <template v-if="columnSort.key === 'sales_report'">
                      <template v-for="cellItem in listSalesReport">
                        <td
                          v-if="
                            cellItem.key ===
                              'sales_report.total_ordered_in_pieces' &&
                            activeColumns.includes('sales_report')
                          "
                          class="first"
                        >
                          {{
                            renderPrice(
                              Math.round(
                                totals.sales_report.total_ordered_in_pieces
                              )
                            )
                          }}
                        </td>
                        <td
                          v-if="
                            cellItem.key ===
                              'sales_report.total_ordered_in_rubles' &&
                            activeColumns.includes('sales_report')
                          "
                        >
                          {{
                            renderPrice(
                              Math.round(
                                totals.sales_report.total_ordered_in_rubles
                              )
                            )
                          }}
                        </td>
                        <td
                          v-if="
                            cellItem.key === 'sales_report.total_redeemed' &&
                            activeColumns.includes('sales_report')
                          "
                        >
                          {{
                            renderPrice(
                              Math.round(totals.sales_report.total_redeemed)
                            )
                          }}
                        </td>
                        <td
                          v-if="
                            cellItem.key === 'sales_report.total_to_transfer' &&
                            activeColumns.includes('sales_report')
                          "
                        >
                          {{
                            renderPrice(
                              Math.round(totals.sales_report.total_to_transfer)
                            )
                          }}
                        </td>
                        <td
                          v-if="
                            cellItem.key === 'sales_report.drr' &&
                            activeColumns.includes('sales_report')
                          "
                        >
                          {{ renderPrice(Math.round(totals.sales_report.drr)) }}
                        </td>
                      </template>
                    </template>
                  </template>
                </tr>
              </template>
              <tr v-else>
                <td colspan="28" class="text-center">Загрузка...</td>
              </tr>
            </tbody>
          </table>

          <div v-else>
            <NoData />
          </div>
        </div>
      </div>
    </div>
    <PartEmpty v-else :notSuitableTariff="!hasProductsSuitableSubscribtion" />
  </div>
</template>

<script>
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import filterAndSort from './components/filterAndSort';
import FilterAndSortModal from './components/filterAndSortModal';
import SortBtn from '@/components/ui/SortBtn.vue';
import NoData from '@/components/no-data/NoData.vue';
import ImgPreview from '@/components/images/ImgPreview.vue';
import SettingsColumn from '@/components/setting-columns/SettingsColumn.vue';
import PartEmpty from '@/components/empty/PartEmpty.vue';
import ModalSetting from '@/components/setting-columns/ModalSetting.vue';

import { renderTextFromItem } from '@/utils/text';
import { renderPrice } from '@/utils/prices';
import {
  setFilterStore,
  getFilterFromStore,
  setSortStore,
} from '@/middleware/filter-store';
import { nextTick } from 'vue';

export default {
  name: 'index',
  components: {
    ModalSetting,
    PartEmpty,
    SettingsColumn,
    ImgPreview,
    NoData,
    filterAndSort,
    FilterAndSortModal,
    SortBtn,
  },
  data() {
    return {
      isShowSettings: true,
      listAutoCompany: [
        {
          key: 'auto_company.consumption',
          name: 'Расход, руб',
          style: 'min-width: 130px',
          title:
            'Расход денежных средств на автоматические рекламные кампании за выбранный период относительно артикула. (из рекламной статистики ВБ)',
        },
        {
          key: 'auto_company.cart',
          name: 'Корзина, шт',
          style: 'min-width: 140px',
          title:
            'Количество единиц добавленых товаров в корзину артикула по всем автоматическим рекламным кампаниям за выбранный период. (из рекламной статистики ВБ)',
        },
        {
          key: 'auto_company.order',
          name: 'Заказ, шт',
          style: 'min-width: 130px',
          title:
            'Количество единиц заказанных товаров артикула по всем автоматическим рекламным кампаниям за выбранный период. (из рекламной статистики ВБ)',
        },
        {
          key: 'auto_company.cpl',
          name: 'CPL, руб.',
          style: 'min-width: 120px',
          title:
            '(«Cost per lead» - цена за лид). Стоимость одного добавления в корзину относительно артикула по автоматическим рекламным кампаниям за выбранный \n' +
            'период. (считаем формулой "расход,руб разделить на корзины в шт)',
        },
        {
          key: 'auto_company.cpo',
          name: 'CPO, руб.',
          style: 'min-width: 120px',
          title:
            '(«Cost per Order» - стоимость оформленного заказа). Стоимость одного оформленного заказа по автоматическим рекламным кампаниям относительно артикула за выбранный \n' +
            'период. (считаем формулой "расход,руб разделить на заказы в шт)',
        },
      ],
      listCard: [
        {
          key: 'card.consumption',
          name: 'Расход, руб.',
          style: 'min-width: 130px',
        },
        {
          key: 'card.cart',
          name: 'Корзина, шт',
          style: 'min-width: 140px',
        },

        {
          key: 'card.order',
          name: 'Заказ, шт',
          style: 'min-width: 120px',
        },
        {
          key: 'card.cpl',
          name: 'CPL, руб.',
          style: 'min-width: 120px',
        },
        {
          key: 'card.cpo',
          name: 'CPO, руб.',
          style: 'min-width: 120px',
        },
      ],
      listSearch: [
        {
          key: 'search.consumption',
          name: 'Расход, руб.',
          style: 'min-width: 130px',
        },
        {
          key: 'search.cart',
          name: 'Корзина, шт',
          style: 'min-width: 140px',
        },
        {
          key: 'search.order',
          name: 'Заказ, шт',
          style: 'min-width: 130px',
        },
        {
          key: 'search.cpl',
          name: 'CPL, руб.',
          style: 'min-width: 120px',
        },
        {
          key: 'search.cpo',
          name: 'CPO, руб.',
          style: 'min-width: 140px',
        },
      ],
      listCatalog: [
        {
          key: 'catalog.consumption',
          name: 'Расход, руб.',
          style: 'min-width: 130px',
        },
        {
          key: 'catalog.cart',
          name: 'Корзина, шт',
          style: 'min-width: 140px',
        },
        {
          key: 'catalog.order',
          name: 'Заказ, шт',
          style: 'min-width: 130px',
        },
        {
          key: 'catalog.cpl',
          name: 'CPL, руб.',
          style: 'min-width: 120px',
        },
        {
          key: 'catalog.cpo',
          name: 'CPO, руб.',
          style: 'min-width: 140px',
        },
      ],
      listSearchCatalog: [
        {
          key: 'search_and_catalog.consumption',
          name: 'Расход, руб.',
          style: 'min-width: 130px',
          title:
            'Расход денежных средств на  рекламные кампании Поиск/Каталог за выбранный период относительно артикула. (из рекламной статистики ВБ)',
        },
        {
          key: 'search_and_catalog.cart',
          name: 'Корзина, шт',
          style: 'min-width: 140px',
          title:
            'Количество единиц добавленых товаров в корзину артикула по всем рекламным кампаниям Поиск/Каталог за выбранный период. (из рекламной статистики ВБ)',
        },
        {
          key: 'search_and_catalog.order',
          name: 'Заказ, шт',
          style: 'min-width: 130px',
          title:
            'Количество единиц заказанных товаров артикула по всем  рекламным кампаниям (Поиск+Каталог) за выбранный период. (из рекламной статистики ВБ)',
        },
        {
          key: 'search_and_catalog.cpl',
          name: 'CPL, руб.',
          style: 'min-width: 120px',
          title:
            'CPL («Cost per lead» - цена за лид). Стоимость одного добавления в корзину относительно артикула по рекламным кампаниям Поиск/каталог за выбранный \n' +
            'период. (считаем формулой "расход,руб разделить на корзины в шт)',
        },
        {
          key: 'search_and_catalog.cpo',
          name: 'CPO, руб.',
          style: 'min-width: 140px',
          title:
            '(«Cost per Order» - стоимость оформленного заказа). Стоимость одного оформленного заказа по рекламным кампаниям Поиск/Каталог относительно артикула за выбранный \n' +
            'период. (считаем формулой "расход,руб разделить на заказы в шт)',
        },
      ],
      listTotal: [
        {
          key: 'total.consumption',
          name: 'Расход, руб.',
          style: 'min-width: 130px',
          title:
            'Суммарный расход денежных средств на все виды рекламных кампаний за выбранный период относительно артикула. (из рекламной статистики ВБ)',
        },
        {
          key: 'total.cart',
          name: 'Корзина, шт',
          style: 'min-width: 140px',
          title:
            'Суммарное количество единиц добавленых товаров в корзину артикула по всем видам рекламных кампаний за выбранный период. (из рекламной статистики ВБ)',
        },
        {
          key: 'total.order',
          name: 'Заказ, шт',
          style: 'min-width: 130px',
          title:
            'Суммарное количество единиц заказанных товаров  по всем видам рекламных кампаний за выбранный период. (из рекламной статистики ВБ)',
        },
        {
          key: 'total.cpl',
          name: 'CPL, руб.',
          style: 'min-width: 120px',
          title:
            'CPL («Cost per lead» - цена за лид). Стоимость одного добавления в корзину относительно артикула по всем рекламным кампаниям за выбранный период.',
        },
        {
          key: 'total.cpo',
          name: 'CPO, руб.',
          style: 'min-width: 140px',
          title:
            'CPL («Cost per lead» - цена за лид). Стоимость одного добавления в корзину относительно артикула по всем рекламным кампаниям за выбранный \n' +
            'период. (считаем формулой "расход,руб разделить на корзины в шт)',
        },
        {
          key: 'total.advert_drr',
          name: 'ДРР, %',
          style: '',
        },
      ],
      listSalesReport: [
        {
          key: 'sales_report.total_ordered_in_pieces',
          name: 'Заказано, шт',
          style: 'min-width: 180px',
          title:
            'Суммарное количество единиц заказанного товара в штуках за выбранный период (из воронки продаж ВБ. Совпадает с отчётом "Еженедельная динамика и анализ продаж")',
        },
        {
          key: 'sales_report.total_ordered_in_rubles',
          name: 'Заказано, руб',
          style: 'min-width: 190px',
          title:
            'Суммарное количество заказанного товара в рублях за выбранный период (из воронки продаж ВБ. Сервис тянет каждый заказ по сделанной в моменте цене)',
        },
        {
          key: 'sales_report.total_redeemed',
          name: 'Выкуплено, шт',
          style: 'min-width: 190px',
          title:
            'Суммарное количество единиц выкупленного товара в штуках за выбранный период (ориентир - воронка продаж ВБ. Совпадает с отчётом "Еженедельная динамика и анализ продаж")',
        },
        {
          key: 'sales_report.total_to_transfer',
          name: 'Выручка с продаж, руб',
          style: 'min-width: 250px',
          title:
            'Суммарное количество выкупленного товара в рублях за выбранный период (из воронки продаж ВБ. Сервис тянет каждую продажу по сделанной в моменте цене)',
        },
        {
          key: 'sales_report.drr',
          name: 'TACoO, % от суммы заказов',
          style: 'min-width: 290px !important;',
          title:
            'TACoO (Total Advertising Cost of Order - общая стоимость заказа на рекламу). Расходы на рекламу по отношению к общей сумме заказов, за выбранный период. (считаем формулой "(расходы на рекламу в руб / общий объем заказов в руб) х 100%)',
        },
      ],

      droppedItem: {},
      localStorageSubListName: 'WBLeadProductsAdvertisingSubList',
      from: moment().subtract(7, 'days').format('YYYY-MM-DD'),
      to: moment().format('YYYY-MM-DD'),
      fromDefault: moment().subtract(7, 'days').format('YYYY-MM-DD'),
      toDefault: moment().format('YYYY-MM-DD'),
      loading: false,
      sortKey: 'total.consumption',
      brand: '',
      row_keys: {
        id_key: false,
        article_key: false,
        subject_key: false,
        brand_key: false,
      },
      active_key: false,
      subject: '',
      article: '',
      tableHeight: 0,
      activeImg: null,
      columnsForSortDefault: [
        // {key: 'card', text: 'Карточка'},
        // {key: 'search', text: 'Поиск'},
        // {key: 'catalog', text: 'Каталог'},
        { key: 'auto_company', text: 'Автоматическая' },
        { key: 'search_and_catalog', text: 'Поиск+Каталог' },
        { key: 'total', text: 'Итого' },
        { key: 'sales_report', text: 'Отчет ВБ' },
      ],
      columnsForSort: [],
      activeColumns: [],
      columns: [
        // {key: 'card', isChecked: false, name: 'Карточка'},
        // {key: 'search', isChecked: false, name: 'Поиск'},
        // {key: 'catalog', isChecked: true, name: 'Каталог'},
        { key: 'auto_company', isChecked: false, name: 'Автоматическая' },
        { key: 'search_and_catalog', isChecked: false, name: 'Поиск+Каталог' },
        { key: 'sales_report', isChecked: false, name: 'Отчет 2 по продажам' },
        { key: 'total', isChecked: false, name: 'Итого' },
      ],
      pageName: 'productsAdvertising',
      isShowColumnSettings: false,
      loaderCounter: 0,
      arrayOptions: {
        sa_name: null,
        subject: null,
        brand: null,
      },
      bufferQueryArray: {
        sa_name: null,
        subject: null,
        brand: null,
      },
      isFirstLoad: true,
      isShowCompFilter: false,
      defaultFilter: {},
      sortIsAsc: true,
    };
  },
  computed: {
    ...mapGetters([
      'productsAdvertising',
      'currentOrganization',
      'totals',
      'isHasSubscribe',
      'hasProductsSuitableSubscribtion',
    ]),
  },
  methods: {
    ...mapActions(['getProductsAdvertising']),
    renderTextFromItem,
    renderPrice,

    renderLengthColumns(columnSort) {
      if (columnSort.key === 'total') return 6;
      return 5;
    },

    saveSubLists() {
      const options = {
        listAutoCompany: this.listAutoCompany.map((item) => item.key),
        listCard: this.listCard.map((item) => item.key),
        listSearch: this.listSearch.map((item) => item.key),
        listCatalog: this.listCatalog.map((item) => item.key),
        listSearchCatalog: this.listSearchCatalog.map((item) => item.key),
        listTotal: this.listTotal.map((item) => item.key),
        listSalesReport: this.listSalesReport.map((item) => item.key),
      };

      localStorage.setItem(
        this.localStorageSubListName,
        JSON.stringify(options)
      );
    },

    operationWithList(key, isUpdateList = false, newList = null) {
      let activeArr = [];

      switch (key) {
        case 'listAutoCompany':
          if (isUpdateList) {
            this.listAutoCompany = newList.slice();
          } else {
            activeArr = this.listAutoCompany.slice();
          }
          break;

        case 'listCard':
          if (isUpdateList) {
            this.listCard = newList.slice();
          } else {
            activeArr = this.listCard.slice();
          }
          break;

        case 'listSearch':
          if (isUpdateList) {
            this.listSearch = newList.slice();
          } else {
            activeArr = this.listSearch.slice();
          }
          break;

        case 'listCatalog':
          if (isUpdateList) {
            this.listCatalog = newList.slice();
          } else {
            activeArr = this.listCatalog.slice();
          }
          break;

        case 'listSearchCatalog':
          if (isUpdateList) {
            this.listSearchCatalog = newList.slice();
          } else {
            activeArr = this.listSearchCatalog.slice();
          }
          break;

        case 'listTotal':
          if (isUpdateList) {
            this.listTotal = newList.slice();
          } else {
            activeArr = this.listTotal.slice();
          }

          break;

        case 'listSalesReport':
          if (isUpdateList) {
            this.listSalesReport = newList.slice();
          } else {
            activeArr = this.listSalesReport.slice();
          }
          break;
      }

      if (!isUpdateList) return activeArr;
    },

    getSubLists() {
      try {
        let storeLists = localStorage.getItem(this.localStorageSubListName);

        const storeUpdate = localStorage.getItem('WBLeadColumnSubUpdate');
        if (storeUpdate === null) {
          localStorage.setItem('WBLeadColumnUpdate', '17-05-2024');
          storeLists = null;
        }

        if (storeLists) {
          const lists = JSON.parse(storeLists);

          for (let listName in lists) {
            let listDefault = null;

            listDefault = this.operationWithList(listName);

            if (listDefault) {
              const arr = [];

              for (let listItem of lists[listName]) {
                for (let itemDefault of listDefault) {
                  if (listItem === itemDefault.key) {
                    arr.push(itemDefault);
                  }
                }
              }

              this.operationWithList(listName, true, arr);
            }
          }
        }
      } catch (err) {
        console.error(err);
      }
    },

    saveColumnsOrder() {
      const arr = this.columnsForSort.map((column) => {
        return column.key;
      });

      localStorage.setItem('WBLeadColumnSort' + this.pageName, arr.toString());
    },

    startDrag(column, index, type, list = []) {
      this.droppedItem = {
        index: index,
        type: type,
      };

      if (type === 'second') {
        this.droppedItem.list = list;
      }
    },

    onDrop(index, type) {
      if (this.droppedItem.type !== type) return;

      this.loading = true;

      if (type !== 'second') {
        const old = this.columnsForSort[index];
        this.columnsForSort[index] =
          this.columnsForSort[this.droppedItem.index];
        this.columnsForSort[this.droppedItem.index] = old;

        this.columns = this.columnsForSort.slice();
        this.saveColumnsOrder();
      } else {
        const list = this.droppedItem.list;
        const old = list[index];

        this.droppedItem.list[index] = list[this.droppedItem.index];
        list[this.droppedItem.index] = old;
        this.saveSubLists();
      }

      nextTick(() => {
        this.loading = false;
      });
    },

    renderValue(key, value) {
      if (key === 'drr') {
        const valueNumber = Number(value);
        if (valueNumber === 0) {
          return 0;
        }
        return valueNumber.toFixed(1);
      }

      return value;
    },

    openModalSettings() {
      this.$refs.RefModalSetting.show();
    },

    setFilterContainer(key = null) {
      const set = new Set();

      if (Array.isArray(this.productsAdvertising) && key !== null) {
        for (let item of this.productsAdvertising) {
          if (this.bufferQueryArray[key] === null) {
            if (item[key]) set.add(item[key]);
          } else {
            for (let arrItem of this.bufferQueryArray[key]) {
              set.add(arrItem);
            }
          }
        }
      }

      const arr = Array.from(set);
      return arr.map((item) => {
        return {
          text: item,
          isChecked: true,
        };
      });
    },

    onUpdateReport(key, list) {
      this.getReports(null, {
        type: 'filter',
        key: key,
        value: list.map((item) => item.text),
      });
    },

    clearReport(key) {
      switch (key) {
        case 'sa_name':
          this.arrayOptions.sa_name = null;
          this.bufferQueryArray.sa_name = null;
          break;

        case 'status':
          this.arrayOptions.subject = null;
          this.bufferQueryArray.subject = null;
          break;

        case 'company':
          this.arrayOptions.brand = null;
          this.bufferQueryArray.brand = null;
          break;

        case 'subject':
          this.arrayOptions.subject = null;
          this.bufferQueryArray.subject = null;
          break;
      }

      this.getReports(null, {
        type: 'filter',
        key: key,
        value: [],
      });
    },

    initColumns() {
      let storeColumns = localStorage.getItem(
        'WBLeadColumnSort' + this.pageName
      );

      if (storeColumns === null) {
        this.activeColumns = this.columns.map((column) => column.key);
        this.columns = this.columns.map((column) => {
          column.isChecked = true;
          return column;
        });
      } else {
        this.activeColumns = [];
        this.activeColumns = storeColumns.split(',').slice();

        this.columns = this.columns.map((column) => {
          if (this.activeColumns.includes(column.key)) column.isChecked = true;
          else column.isChecked = false;

          return column;
        });
      }

      this.isShowColumnSettings = true;
    },

    setSettings(generalOptions) {
      this.setSortColumns();
      this.initColumns();
      this.getReports(null, generalOptions);
    },

    dateFilterHandler(dates) {
      this.to = dates.to;
      this.from = dates.from;
      this.getReports();
    },
    activeKeyHandler(value) {
      let key = Object.keys(value)[0];
      this.row_keys[key] = !this.row_keys[key];
    },
    setFilters(payload) {
      this.brand = payload.brand;
      this.article = payload.article;
      this.subject = payload.subject;
      this.getReports();
    },
    sort(key, isAsc) {
      setSortStore(
        {
          sortKey: key,
          sortIsAsc: isAsc,
        },
        'products-advertising'
      );

      this.sortKey = key;
      this.sortIsAsc = !this.sortIsAsc;
      let keys = ['sa_name', 'subject', 'brand'];
      const sortedItems = [...this.productsAdvertising];

      if (keys.includes(key)) {
        sortedItems.sort((a, b) => {
          const aValue = this.fetchFromObject(a, key).toLowerCase();
          const bValue = this.fetchFromObject(b, key).toLowerCase();

          return isAsc
            ? aValue.localeCompare(bValue)
            : bValue.localeCompare(aValue);
        });
      } else {
        const catalogList = [
          'search_and_catalog.consumption',
          'search_and_catalog.cart',
          'search_and_catalog.cart',
          'search_and_catalog.cpl',
          'search_and_catalog.cpo',
        ];

        let isCatalog = false;

        if (catalogList.includes(key)) {
          isCatalog = true;
        }

        sortedItems.sort((a, b) => {
          let aValue = null;
          let bValue = null;

          if (!catalogList.includes(key)) {
            aValue = this.fetchFromObject(a, key);
            bValue = this.fetchFromObject(b, key);
          } else {
            const tuple = key.split('.');
            aValue = this.fetchFromObject(a.search_and_catalog, tuple[1]);
            bValue = this.fetchFromObject(b.search_and_catalog, tuple[1]);
          }

          return isAsc ? aValue - bValue : bValue - aValue;
        });
      }

      // Replace the array with the sorted one to maintain Vue reactivity
      this.productsAdvertising.splice(
        0,
        this.productsAdvertising.length,
        ...sortedItems
      );
    },
    fetchFromObject(obj, prop) {
      if (typeof obj === 'undefined') {
        return false;
      }

      var _index = prop.indexOf('.');
      if (_index > -1) {
        return this.fetchFromObject(
          obj[prop.substring(0, _index)],
          prop.substr(_index + 1)
        );
      }

      return obj[prop];
    },

    async getReports(payload, options = {}) {
      this.loading = true;

      let isShowZeros = true,
        sa_name = null,
        subject = null,
        brand = null;

      let arrSaName = [],
        arrSubject = [],
        arrBrand = [];

      if (!this.isFirstLoad) {
        if (options) {
          if (options.hasOwnProperty('isShowNull')) {
            if (options.isShowNull === 'true' || options.isShowNull === true) {
              isShowZeros = 'false';
            } else {
              isShowZeros = 'true';
            }
          }

          if (options.type === 'filter') {
            switch (options.key) {
              case 'sa_name':
                if (options.value.length > 0) {
                  sa_name = '';
                  arrSaName = options.value.slice();
                  this.bufferQueryArray.sa_name = options.value.slice();

                  for (let item of options.value) {
                    sa_name += `sa_name=${item}&`;
                  }

                  this.arrayOptions.brands = sa_name;
                } else {
                  this.arrayOptions.sa_name = null;
                  this.bufferQueryArray.sa_name = null;
                }
                break;

              case 'subject':
                if (options.value.length > 0) {
                  subject = '';
                  arrSubject = options.value.slice();
                  this.bufferQueryArray.subject = options.value.slice();

                  for (let item of options.value) {
                    subject += `subject=${item}&`;
                  }

                  this.arrayOptions.subject = subject;
                } else {
                  this.arrayOptions.subject = null;
                }
                break;

              case 'brand':
                if (options.value.length > 0) {
                  brand = '';
                  arrBrand = options.value.slice();
                  this.bufferQueryArray.brand = options.value.slice();

                  for (let item of options.value) {
                    brand += `brand=${item}&`;
                  }

                  this.arrayOptions.brand = brand;
                } else {
                  this.arrayOptions.brand = null;
                  this.bufferQueryArray.brand = null;
                }
                break;
            }
          }
        }
      } else {
        if (
          Array.isArray(this.defaultFilter.arrSaName) &&
          this.defaultFilter.arrSaName.length > 0
        ) {
          arrSaName = this.defaultFilter.arrSaName;
          this.bufferQueryArray.sa_name = this.defaultFilter.arrSaName;
        }

        if (
          Array.isArray(this.defaultFilter.arrSubject) &&
          this.defaultFilter.arrSubject.length > 0
        ) {
          arrSubject = this.defaultFilter.arrSubject;
          this.bufferQueryArray.subject = this.defaultFilter.arrSubject;
        }

        if (
          Array.isArray(this.defaultFilter.arrBrand) &&
          this.defaultFilter.arrBrand.length > 0
        ) {
          arrBrand = this.defaultFilter.arrBrand;
          this.bufferQueryArray.brand = this.defaultFilter.arrBrand;
        }

        isShowZeros = this.defaultFilter.isShowZeros || null;
      }

      let query = this.$route.query;

      const startDate = this.from
        ? moment(this.from)
        : moment().subtract(7, 'days').format('YYYY-MM-DD');
      const endDate = this.to ? moment(this.to) : moment().format('YYYY-MM-DD');

      let params = {
        start_date: moment(startDate).format('YYYY-MM-DD'),
        end_date: moment(endDate).format('YYYY-MM-DD'),
        nm_id: this.article || query.article,
        wb_account: this.currentOrganization.id,
        show_zeros: isShowZeros,
        product_sa_name: arrSaName,
        product_subject: arrSubject,
        product_brand: arrBrand,
      };

      for (let key of Object.keys(params)) {
        if (!params[key]) {
          delete params[key];
        }
      }

      if (!this.isFirstLoad) setFilterStore(params, 'products-advertising');

      await this.getProductsAdvertising(params);

      if (this.isFirstLoad) {
        if (this.defaultFilter.sortKey) {
          this.sort(this.defaultFilter.sortKey, this.defaultFilter.sortIsAsc);
        }
      }

      if (!this.defaultFilter.sortKey) {
        this.sort(this.sortKey || 'total.consumption', false);
      } else {
        this.sort(this.defaultFilter.sortKey, this.defaultFilter.sortIsAsc);
      }

      if (this.isFirstLoad) this.isFirstLoad = false;

      this.loaderCounter++;
      this.loading = false;
    },

    calcTableHeight() {
      this.tableHeight = 0;

      this.$nextTick(() => {
        if (document.getElementById('content')) {
          this.tableHeight =
            document.getElementById('content').clientHeight - 150;
        }
      });
    },

    onMouseOverImg(index, product) {
      this.activeImg = {
        index: index,
        src: product.image,
        brand: product.brand,
      };
    },

    onMouseLeaveImg() {
      this.activeImg = null;
    },

    setSortColumns() {
      const columns = localStorage.getItem('WBLeadColumnSort' + this.pageName);

      if (!columns) {
        this.columnsForSort = this.columnsForSortDefault.slice();
        return false;
      }

      this.columnsForSort = [];
      for (let column of columns.split(',')) {
        for (let item of this.columnsForSortDefault) {
          if (column === item.key) this.columnsForSort.push(item);
        }
      }
    },

    initFilters() {
      const filter = getFilterFromStore('products-advertising');

      if (filter) {
        this.from = filter.start_date || null;
        this.to = filter.end_date || null;
        this.$route.query.nm_id = filter.article;
        this.$route.query.from = filter.start_date || null;
        this.$route.query.to = filter.end_date || null;

        this.defaultFilter = {
          arrSaName: filter.product_sa_name || null,
          arrSubject: filter.product_subject || null,
          arrBrand: filter.product_brand || null,
          isShowZeros: filter.show_zeros || null,
          sortKey: filter.sortKey || null,
          sortIsAsc: filter.sortIsAsc || null,
        };
      }

      this.isShowCompFilter = true;

      this.getReports();
    },
  },
  mounted() {
    this.setSortColumns();
    this.initFilters();
    this.initColumns();
    this.getSubLists();
    this.calcTableHeight();

    window.addEventListener('resize', () => {
      this.calcTableHeight();
    });
  },
};
</script>

<style lang="scss" scoped>
@import './index.scss';
/* Задаем стили для заголовков таблицы */
table thead {
  position: sticky;
  top: -1px;
  background-color: white;
  box-shadow: rgba(47, 10, 73, 0.12) 0px -8px 8px -8px inset;
  z-index: 2;
}

/* Задаем стили для первой колонки */
table tbody td.sticky-col {
  position: sticky;
  left: -1px;
  z-index: 1;
}

.productsAdvertising-filter {
  & #filters__BV_toggle_ {
    margin-right: 1rem;
  }
}

.td_product_images {
  z-index: 11 !important;

  & .preview {
    left: 100%;
  }
}

.product-advertising-table thead {
  z-index: 12 !important;
}

.product-advertising-table tbody tr:nth-last-child(-n + 5) {
  & td:first-child {
    color: red !important;

    & .preview {
      top: auto !important;
      bottom: calc(100% - 2px) !important;
    }
  }
}

.product-advertising-table td {
  padding: 6px 10px !important;

  & .cell-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.table-row-total {
  & td {
    position: sticky;
    bottom: 0;
    z-index: 10 !important;
    font-weight: 700;

    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: -1px;
      width: 100%;
      height: 1px;
      background: #fff;
    }

    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3) {
      z-index: 11 !important;
    }

    &:nth-child(1) {
      min-width: 100px;
      max-width: 100px;
      z-index: 1000 !important;
    }

    &:nth-child(2) {
      max-width: 200px !important;
      left: 100px !important;
    }

    &:nth-child(3) {
      min-width: 290px;
      max-width: 290px;
      left: 200px !important;
      z-index: 14 !important;
    }

    &:nth-child(5) {
      min-width: 290px;
      max-width: 290px;
      left: 200px !important;
      z-index: 14 !important;
    }
  }
}

.col-settings {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1000;
}

.table-top-label {
  text-align: center !important;
}

.productsAdvertising-table {
  & th {
    z-index: 11 !important;
    border: 1px solid #f1f1f1 !important;
  }

  & tr:last-child th {
    border: none !important;
    box-shadow: none !important;

    &.first {
      border-left: 1px solid #f1f1f1 !important;
    }
  }

  & td {
    border: none;
    border-top: 1px solid #f1f1f1;
    border-bottom: 1px solid #f1f1f1;
    box-shadow: none !important;

    &.first {
      border-left: 1px solid #f1f1f1 !important;
    }
  }
}

.tr-second {
  & th {
    min-width: 26ch !important;
    cursor: help;
  }
}
</style>
