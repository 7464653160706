import { NOT_SUITABLE_TARIFF } from '@/constants/error-responses';
import axios from 'axios';

const state = {
  productsAdvertising: [],
  sort_items: [],
  totals: {},
  hasSuitableSubscribtion: true,
};

const getters = {
  productsAdvertising: (state) => state.productsAdvertising,
  sort_items: (state) => state.sort_items,
  totals: (state) => state.totals,
  hasProductsSuitableSubscribtion: (state) => state.hasSuitableSubscribtion,
};

const mutations = {
  SET_PRODUCTS_ADVERTISING(state, payload) {
    state.productsAdvertising = payload;
  },
  SET_ITEMS(state, payload) {
    state.sort_items = payload;
  },

  SET_PRODUCTS_TOTALS(state, payload) {
    state.totals = payload;
  },
  SET_SUITABLE_SUBSCRIPTION(state, value) {
    state.hasSuitableSubscribtion = value;
  },
};

const actions = {
  async getProductsAdvertising({ commit }, payload) {
    try {
      const response = await axios({
        url: '/new-reports/comm_art/',
        method: 'POST',
        data: {
          ...payload,
          show_zeros: 'false',
        },
        config: {
          paramsSerializer: {
            indexes: null,
          },
        },
      });
      const data = response.data.data;
      commit('SET_PRODUCTS_ADVERTISING', data);
      commit('SET_PRODUCTS_TOTALS', response.data.totals);
    } catch (error) {
      console.error(error);
      commit(
        'SET_SUITABLE_SUBSCRIPTION',
        error.response.data.detail !== NOT_SUITABLE_TARIFF
      );
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
