<script setup>
const renderYear = () => {
  const date = new Date();
  return date.getFullYear();
}

const links = [
  {path: '/agreement', name: 'Пользовательское соглашение'},
  {path: '/confidential', name: 'Политика конфиденциальности'},
  {path: '/payment-offer', name: 'Оферта регулярных платежей'}
]
</script>

<template>
  <div class="landing_footer_bottom">
    <div>{{ renderYear() }} © WBLeads</div>

    <div class="landing_footer_bottom__links">
      <router-link
          v-for="link in links"
          :to="link.path"
          class="landing_footer_bottom__link"
      >
       {{ link.name }}
      </router-link>
    </div>
  </div>
</template>

<style scoped lang="scss">
.landing_footer_bottom {
  padding: 20px 0;
  border-top: 1px solid #DFDFDF;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #908F90;
  font-size: 14px;

  &__links {
    display: flex;
    align-items: center;
    gap: 30px;
    justify-content: end;
  }

  &__link {
    color: #908F90;
    text-decoration: none;

    &:hover, &:active {
      color: #481173;
    }
  }
}
</style>
