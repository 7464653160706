<template>
  <div class="reports wb-content">
    <router-view></router-view>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "index.vue",
  data() {
    return {
      report: "first",
    };
  },
  methods: {
    ...mapActions(["uploadReport"]),
    setPage(payload) {
      this.report = payload.report;
      this.$router.push("/reports/" + payload.page);
    },
    downloadReport() {
      this.uploadReport(this.report);
    },
    setActiveTab() {
      // Определяем активную вкладку (activeTab) на основе текущего пути роутера
      if (this.$route.name === "productsAdvertising") {
        return "comm_art";
      } else if (this.$route.name === "CompaniesAdvertising") {
        return "comm_rk";
      } else if (this.$route.name === "productsStatistic") {
        return "dyn_art";
      } else {
        // Устанавливаем значение по умолчанию для неизвестного пути
        return "comm_art";
      }
    },
  },
  watch:{
    "$route.name":{
      handler(newVal){
        if(newVal === 'productsStatistic'){
          this.report = 'dyn_art'
        }else  if(newVal === 'CompaniesAdvertising'){
          this.report = 'comm_rk'
        }else if(newVal === 'productsAdvertising'){
          this.report = "comm_art"
        }
      }
    }
  },
  mounted() {
    this.report = this.setActiveTab();
  },
};
</script>

<style scoped></style>
