<template>
    <b-input-group class="inner-addon right-addon">
        <b-form-input v-model="localValue" :type="type" :placeholder="placeholder" :state="state"/>

        <template #append>
            <b-input-group-append @click="toggle">
                <div class="icon">
                    <i v-if="type === 'password'" class="las la-eye"></i>
                    <i v-else class="las la-eye-slash"></i>
                </div>
            </b-input-group-append>
        </template>

        <b-form-invalid-feedback>
            {{ errors[0] }}
        </b-form-invalid-feedback>
    </b-input-group>
</template>

<script>
export default {
  name: 'PasswordField',
  props: {
    placeholder: {
      type: String,
      default: 'Пароль'
    },
    value: {

    },
    state: {
        type: Boolean,
        default: true
    },
    errors: {
        type: Array,
        default: () => ([])
    }
  },
  computed: {
    localValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  },
  data () {
    return {
      type: 'password',
    }
  },
  methods: {
    toggle () {
      this.type = this.type === 'text' ? 'password' : 'text'
    }
  }
}
</script>

<style scoped>
  .icon {
    height: 37px;
  }
</style>
