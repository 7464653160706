<template lang="">
  <div class="table-filter-modal">
    <!--<div class="table-filter-modal__wrap_sort">
      <a @click="onSortRequested('desc')">
        <img src="@/assets/img/SortAsc.svg" alt="" /> Сортировка от А до Я
      </a>
      <a @click="onSortRequested('asc')">
        <img src="@/assets/img/SortDesc.svg" alt="" />Сортировка от Я до А
      </a>
    </div>-->
    <template v-if="filterValues">
      <div class="input-container">
        <b-icon
          icon="search"
          class="input-icon input-container__search"
        ></b-icon>
        <b-form-input
          style="width: 215px"
          v-model="searchValue"
          @input="searchFilterItems"
          class="input-search"
          placeholder="Поиск"
        ></b-form-input>
      </div>

      <div class="checkboxes-content" @click.stop>
        <b-form-checkbox
          id="checkbox-1"
          class="modal-checkbox"
          @change="onAllChecked"
          v-model="checkedAll"
        >
          Все
        </b-form-checkbox>
        <b-form-checkbox
          v-for="filterValue in searchedFilter"
          :key="filterValue.value"
          class="modal-checkbox"
          @change="onCheckboxChanged(filterValue)"
          v-model="filterValue.isChecked"
        >
          {{ filterValue.value }}
        </b-form-checkbox>
      </div>

      <div class="d-flex justify-content-end mt-3">
        <button class="modal-btn-cancel" @click.prevent="resetFilter">
          {{ isFilterActive() ? 'Очистить' : 'Отмена' }}
        </button>
        <button class="ml-2 modal-btn-submit" @click.prevent="applyFilter">
          Применить
        </button>
      </div>
    </template>
  </div>
</template>
<script>
import debounce from 'debounce';
export default {
  name: 'TableFilterModal',
  data() {
    return {
      filterValues: null,
      selectedValues: [],
      checkedAll: true,
      searchedFilter: null,
      params: null,
      searchValue: '',
    };
  },
  mounted() {
    this.initColumnData();
  },
  methods: {
    initColumnData() {
      let values = new Set();
      this.params.api.forEachNode((node) => {
        if (node.data && node.data[this.params.colDef.field] !== undefined) {
          values.add(node.data[this.params.colDef.field]);
        }
      });
      this.selectedValues = Array.from(values);
      this.searchedFilter = this.filterValues = this.selectedValues.map(
        (value) => ({
          value,
          isChecked: true,
        })
      );

      setTimeout(() => {
        this.checkedAll =
          this.filterValues.length === this.selectedValues.length;
        this.searchedFilter = this.filterValues = this.filterValues.map(
          ({ value }) => ({
            value,
            isChecked: this.selectedValues.includes(value),
          })
        );
      }, 0);
    },

    onCheckboxChanged(filterValue) {
      const value = filterValue.value;
      if (filterValue.isChecked) {
        this.selectedValues.push(filterValue.value);
        this.checkedAll = !this.isFilterActive();
      } else {
        this.selectedValues = this.selectedValues.filter(
          (selectedValue) => selectedValue !== value
        );
        this.checkedAll = false;
      }
    },

    doesFilterPass(params) {
      const value = params.data[this.params.colDef.field];
      return this.selectedValues.includes(value);
    },

    setCheckedValue(value) {
      this.filterValues = this.filterValues.map((filterValue) => {
        return {
          ...filterValue,
          isChecked: value,
        };
      });
      this.searchedFilter = this.searchedFilter.map((searchedItem) => {
        return {
          ...searchedItem,
          isChecked: value,
        };
      });
    },

    onAllChecked(value) {
      if (value) {
        this.selectedValues = this.filterValues.map(
          (filterValue) => filterValue.value
        );
        this.setCheckedValue(true);
      } else {
        this.selectedValues = [];
        this.setCheckedValue(false);
      }
    },

    isFilterActive() {
      return this.selectedValues.length < this.filterValues.length;
    },

    getModel() {
      if (!this.isFilterActive()) {
        return null;
      }
      return { selectedValues: this.selectedValues };
    },
    setModel(model) {
      if (model) {
        this.selectedValues = model.selectedValues;
      } else {
        this.selectedValues = [];
      }
    },

    searchFilterItems: debounce(function (searchValue) {
      this.searchedFilter = this.filterValues.filter((filterValue) =>
        filterValue.value.toLowerCase().includes(searchValue)
      );
    }, 300),

    resetFilter() {
      this.filterValues = this.filterValues.map((value) => ({
        ...value,
        isChecked: true,
      }));
      this.searchedFilter = this.filterValues;
      this.selectedValues = this.filterValues.map(({ value }) => value);
      this.checkedAll = true;
      this.searchValue = '';
      this.params.filterChangedCallback();
      this.params.api.hidePopupMenu();
    },

    applyFilter() {
      this.searchValue = '';
      this.params.filterChangedCallback();
      this.params.api.hidePopupMenu();
    },
  },
};
</script>
<style lang="scss" scoped>
.table-filter-modal {
  z-index: 1000;
  padding: 12px 16px;
  background-color: white;
  width: 272px;
  &__wrap_sort {
    margin-bottom: 1rem;

    & a {
      display: inline-block;

      &:first-child {
        margin-bottom: 0.5rem;
      }
    }
  }
}
</style>
