<script setup>
const listNav = [
  { path: '#Tariffs', name: 'Тарифы' },
  { path: '#About', name: 'О сервисе' },
  { path: '#Opportunities', name: 'Возможности' },
  { path: '#Advantages', name: 'Преимущества' },
  { path: '#Review', name: 'Обзор' },
  { path: '#Faq', name: 'FAQ' },
  { path: 'https://t.me/wbleads_cases', name: 'Отзывы' },
  { path: '/instruction', name: 'Инструкция' },
];
</script>

<template>
  <header class="landing_header">
    <a class="landing_header__wrap_logo" href="/landing">
      <img src="/img/logo.svg" alt="Logo" class="landing_header__logo" />
    </a>

    <div class="landing_header__nav_login">
      <nav class="landing_header__nav">
        <a
          v-for="nav in listNav"
          :key="nav.path"
          :href="nav.path"
          class="landing_header__nav__item"
          :target="nav.path === 'https://t.me/wbleads_cases' ? '_blank' : ''"
          >{{ nav.name }}</a
        >
      </nav>

      <a class="wb-btn landing_header__login" href="/auth/login">Войти</a>
    </div>
  </header>
</template>

<style scoped lang="scss">
.landing_header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #fff;
  z-index: 1000;
  padding: 24px 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;

  &__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__nav_login {
    padding: 0;
    display: flex;
    align-items: center;
    gap: 105px;
  }

  &__wrap_logo {
    display: inline-block;
  }

  &__nav {
    display: flex;
    align-items: center;
    gap: 40px;

    &__item {
      color: #160027;
      font-size: 16px;
      text-decoration: none;
      display: inline-block;
      cursor: pointer;

      &:hover,
      &:active {
        color: #481173;
      }
    }
  }

  &__login {
    height: 51px;
    padding: 0 2.8rem;
    border-radius: 100px;
    text-decoration: none;
  }
}
</style>
