<template>
  <div class="third-report-card p-3">
    <div class="third-report-card__info">
      <h6 class="third-report-card__title">
        Все товары в рекламе
      </h6>
    </div>
    <div class="third-report-card__date-filter mr-4">
      <div class="d-flex period-filter me-3">
        <label class="d-flex align-items-center me-3 mb-0">Период</label>
        <b-input-group style="width: 160px">
          <b-input-group-prepend
              class="third-report-card_sort-and-filter__input-prepend"
          >
            <b-form-datepicker start-weekday="1" v-model="filters.from" button-only>
              <template #button-content>
                <img height="20" src="/img/datepicker-icon.svg" />
              </template>
            </b-form-datepicker>
          </b-input-group-prepend>
          <b-form-input
              class="third-report-card_sort-and-filter__input"
              type="text"
              placeholder="17/02/2023"
              v-model="formattedFrom"
              disabled
              style="height: 45px"
          ></b-form-input>
        </b-input-group>
      </div>
      <div class="d-flex period-filter">
        <label class="d-flex align-items-center me-3 mb-0">по</label>
        <b-input-group style="width: 160px">
          <b-input-group-prepend
              class="third-report-card_sort-and-filter__input-prepend"
          >
            <b-form-datepicker start-weekday="1" v-model="filters.to" button-only>
              <template #button-content>
                <img height="20" src="/img/datepicker-icon.svg" />
              </template>
            </b-form-datepicker>
          </b-input-group-prepend>
          <b-form-input
              type="text"
              class="third-report-card_sort-and-filter__input"
              placeholder="17/02/2023"
              v-model="formattedTo"
              disabled
              style="height: 45px"
          ></b-form-input>
        </b-input-group>
      </div>
    </div>
    <div class="third-report-card__date-filter">
      <b-button-group class="wb-button-group">
        <b-button
            style="font-size: 15px"
            :pressed="checkDate(7)"
            @click="setDate(7)"
        >7 дней</b-button
        >
        <b-button
            style="font-size: 15px"
            :pressed="checkDate(14)"
            @click="setDate(14)"
        >14 дней</b-button
        >
        <b-button
            style="font-size: 15px"
            :pressed="checkDate(30)"
            @click="setDate(30)"
        >30 дней</b-button
        >
        <b-button
            style="font-size: 15px"
            :pressed="checkDate(90)"
            @click="setDate(90)"
        >90 дней</b-button
        >
      </b-button-group>
    </div>
    <div class="third-report-card__date-filter ml-4">
      <b-btn @click="downloadReport" class="wb-btn">
        <img src="@/assets/img/download.svg" /> <span class="ml-2">Export</span>
      </b-btn>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "filterAndSort",
  data() {
    return {
      options: [
        { value: 9, text: "Активные" },
        { value: 7, text: "Показы завершеные" },
        { value: 11, text: "Приостановленые" },
      ],
      status: null,
      brand: null,
      subject: null,
      article: null,
      filters: {
        from:  this.$route.query.from || moment().subtract(7, "days").format("YYYY-MM-DD"),
        to: this.$route.query.to || moment().format("YYYY-MM-DD"),
      },
    };
  },
  computed: {
    ...mapGetters(["currentOrganization"]),

    formattedTo() {
      return moment(this.filters.to).format("DD/MM/YYYY");
    },
    formattedFrom() {
      return moment(this.filters.from).format("DD/MM/YYYY");
    },
  },
  watch: {
    filters: {
      handler(newVal, oldVal) {
        const formattedTo = moment(this.filters.to).format("YYYY-MM-DD");
        const formattedFrom = moment(this.filters.from).format("YYYY-MM-DD");
        this.emitDateFilterHandler({ to: formattedTo, from: formattedFrom });
      },
      deep: true,
    },
  },

  methods: {
    ...mapActions(["getProductsAdvertising","uploadReport"]),

    downloadReport(){
      const filters = {
        brand: this.brand || null,
        campaign_name: this.subject || [],
        category_name: this.article || [],
        status: this.status || [],
        end_date: this.filters.to,
        start_date: this.filters.from,
        wb_account: this.currentOrganization.id
      };

      this.uploadReport({
        type: 'comm_art',
        filters: filters
      });
    },
    setFilterByQuery() {
      const { brand, subject, article, from, to, status } = Object.assign(
          {},
          this.$route.query
      );
      this.brand = brand || "";
      this.subject = subject || "";
      this.article = article || "";
      this.status = status || "";

      if(this.dateEnd) {
        this.filters.to = this.dateEnd;
      } else {
        this.filters.to = to || moment().format("YYYY-MM-DD");
      }
    },
    setQuery() {
      const { brand, subject, article, status } = this;
      this.$router.replace({
        name: "CompaniesAdvertising",
        query: {
          from: this.filters.from || "",
          to: this.filters.to || "",
          brand: brand || "",
          subject: subject || "",
          article: article || "",
          status: status || "",
        },
      });
    },
    clearFilters() {
      this.brand = "";
      this.subject = "";
      this.article = "";
      this.status = "";
      this.setQuery()
      this.$emit('getReports')
      this.setFilterStore(null);
    },
    filterHandler() {
      this.$emit("setFilters", {
        brand: this.brand,
        subject: this.subject,
        article: this.article,
        status: this.status,
      });
    },
    checkDate(interval) {
      let days  = interval + 1;
      const now = moment().subtract(1, 'days');
      const pre = moment().subtract(days, "days");

      return (
          now.isSame(moment(this.filters.to), "day") &&
          pre.isSame(moment(this.filters.from), "day")
      );
    },
    setDate(interval) {
      const now = moment();
      const pre = moment().subtract(interval, "days");

      this.filters.from = pre.subtract(1, 'days').format("YYYY-MM-DD");
      this.filters.to = now.subtract(1, 'days').format("YYYY-MM-DD");
    },
    emitDateFilterHandler(data) {
      this.$emit("dateFilterHandler", data);
    },
  },
  mounted() {
    this.setFilterByQuery();
  },
};
</script>

<style lang="scss" scoped>
@import "../index.scss";

.third-report-card {
  display: grid;
  grid-template-columns: auto auto auto auto !important;
  align-items: center;

  &__title {
    color: rgba(72, 17, 115);
  }
}

.third-report-card__info {
  justify-content: flex-start;
  padding-left: 1.5rem;
}

.filter_btns {
  display: flex;
  justify-content: center;
  width: 100%;
}

.filter_export {
  display: flex;
  justify-content: flex-end;

  & button {
    height: 40px !important;
  }
}
</style>
