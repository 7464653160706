<script>
export default {
  name: 'instruction',

  data() {
    return {
      blocks: [
        {
          text: '1. Нажимаем на "Добавить организацию" в разделе "Организации"',
          img: '/img/instruction-landing/img.png'
        },
        {
          text: '2. В верхнее поле вводим название своей организации\n' +
              'В нижнее поле вводим сгенерированный API токен.',
          img: '/img/instruction-landing/img_1.png'
        },
        {
          text: '3. Для формирования корректного API токена выберите следующие настройки при его генерации:',
          img: '/img/instruction-landing/img_2.png'
        },
        {
          text: '4. После добавления организации включите toggle её активности',
          img: '/img/instruction-landing/img_3.png'
        },{
          text: '5. Ожидаем от 2 до 4 часов для завершения сбора статистики.',
          img: null
        },
        {
          text: '6. Пользуемся и радуемся!',
          img: null
        }
      ]
    }
  }
}
</script>

<template>
  <div class="instruction-view">
    <h2 class="instruction-view__title">
      Инструкция по добавлению организации:
    </h2>

    <div class="instruction-view__blocks">
      <div class="instruction-view__block"
           v-for="block in blocks"
           :key="block.text"
      >
        <p class="instruction-view__block__text">
          {{ block.text }}
        </p>
        <img
            v-if="block.img"
            :src="block.img"
            alt=""
            class="instruction-view__block__img"
        />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.instruction-view {
  margin-top: 100px;
  padding: 2rem 1rem;
  text-align: center;
  background: #fff;

  &__title {
    margin-bottom: 100px;
    font-size: 50px;
    font-weight: 700;
  }

  &__block {
    margin-bottom: 100px;

   &__text {
     margin-bottom: 20px;
     color: #160027;
     font-size: 18px;
   }

    &__img {

    }
  }
}
</style>
