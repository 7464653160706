<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import moment from 'moment/moment';

import DashboardAside from '@/components/dashboard/DashboardAside.vue';
import DashboardReport from '@/components/dashboard/DashboardReport.vue';
import Filters from '@/components/filters/Filters.vue';
import dynamicChart from '@/views/reports/productsStatistic/components/dynamicChart.vue';
import PartEmpty from '@/components/empty/PartEmpty.vue';

export default {
  name: 'dashboard',

  components: {
    PartEmpty,
    dynamicChart,
    DashboardAside,
    DashboardReport,
    Filters,
  },

  data() {
    return {
      notSuitableTariff: false,
      reportTopProducts: {
        id: 'top_products',
        title: 'ТОП-5 РК с высоким TACoO',
        table: {
          headers: [
            {
              key: 'advert_campaign_name',
              text: 'Текущий период',
            },
            {
              key: 'drr',
              text: 'TACoO %',
              small: true,
            },
            {
              key: 'previous_advert_campaign_name',
              text: 'Прошлый период',
            },
            {
              key: 'previous_drr',
              text: 'TACoO %',
              small: true,
            },
          ],
          data: [],
        },
      },
      reportBottomProducts: {
        id: 2,
        title: 'ТОП-5 РК с низким TACoO',
        table: {
          headers: [
            {
              key: 'advert_campaign_name',
              text: 'Текущий период',
            },
            {
              key: 'drr',
              text: 'TACoO %',
              small: true,
            },
            {
              key: 'previous_advert_campaign_name',
              text: 'Прошлый период',
            },
            {
              key: 'previous_drr',
              text: 'TACoO %',
              small: true,
            },
          ],
          data: [],
        },
      },
      reportOrders: {
        id: 1,
        title: null,
        table: {
          headers: [
            {
              key: 'sa_name',
              text: 'Артикул',
            },
            {
              key: 'ordered_in_pieces',
              text: 'Заказано, шт',
              small: true,
            },
            {
              key: 'ordered_in_rubles',
              text: 'Заказано, руб ',
            },
          ],
          data: [],
        },
      },
      dataStart: moment().subtract(7, 'days').format('YYYY-MM-DD'),
      dateEnd: moment().format('YYYY-MM-DD'),
      dashboardData: {},
      dynamic_chart: {
        labels: [],
        datasets: [],
      },
      chartActiveView: 'default',
      drr: 0,
    };
  },

  computed: {
    ...mapGetters([
      'users',
      'currentUser',
      'currentOrganization',
      'isHasSubscribe',
    ]),
  },

  methods: {
    toggleViewChart() {
      if (this.chartActiveView === 'default') {
        this.chartActiveView = 'last';
        this.reportOrders.title = `${this.dashboardData.previous_period_top_products_percent}% РК принесли 80% выкупленных заказов за выбранный период`;
        this.drr = this.dashboardData.previous_period_total_stats.drr;
      } else {
        this.chartActiveView = 'default';
        this.reportOrders.title = `${this.dashboardData.top_products_percent}% РК принесли 80% выкупленных заказов за выбранный период`;
        this.drr = this.dashboardData.total_stats.drr;
      }

      this.setChartValue();
    },

    renderFormatPercent(value) {
      return value.toFixed(0);
    },

    getColor(item) {
      if (!item.color) return this.getRandomColor();
      else return item.color;
    },

    setChartValue() {
      const values = [
        {
          label: 'Расход, руб',
          key: 'total_sum',
          isMoney: true,
          color: '#009900',
          isHiddenDefault: true,
        },
        {
          label: 'Клики, шт',
          key: 'total_clicks',
          isMoney: false,
          color: '#996633',
        },
        // { label: "Добавления в корзину, руб", key: "total_orders", isMoney: true, color: '#FF6633' },
        // { label: "Добавления в корзину, шт", key: "total_atbs", isMoney: false, color: '#FF0033' },
        // { label: "Продажи к заказам, шт", key: "total_redeemed_pcs", isMoney: false, color: '#FF0066' },
        // { label: "Продажи к заказам, руб", key: "total_redeemed_rub", isMoney: true, color: '#FF66FF' },
        {
          label: 'Просмотры, шт',
          key: 'total_views',
          isMoney: false,
          color: '#CC9966',
          isHiddenDefault: true,
        },
        {
          label: 'Заказы по отчету ВБ, шт',
          key: 'total_ordered_in_pieces',
          isMoney: false,
          color: '#FF6666',
        },
        {
          label: 'Заказы по отчету ВБ, руб',
          key: 'total_ordered_in_rubles',
          isMoney: true,
          color: '#CC0033',
          isHiddenDefault: true,
        },
        {
          label: 'Заказы по рекламе, шт',
          key: 'total_orders',
          isMoney: false,
          color: '#FF99CC',
        },
        {
          label: 'Продажи по отчету ВБ, шт',
          key: 'total_redeemed',
          isMoney: false,
          color: '#9933CC',
        },
        {
          label: 'Продажи по отчету ВБ, руб',
          key: 'total_to_transfer',
          isMoney: true,
          color: '#6600CC',
        },
        {
          label: 'TACoO от заказов, %',
          key: 'drr',
          isMoney: false,
          color: '#3366FF',
        },
        {
          label: 'CPC средний, руб',
          key: 'cpc_avg',
          isMoney: false,
          color: '#669966',
        },
        {
          label: 'Товарные остатки, шт',
          key: 'stock',
          isMoney: false,
          color: '#0066FF',
          isHiddenDefault: true,
        },
        {
          label: 'Количество активных РК, шт',
          key: 'total_active_adverts_count',
          isMoney: false,
          color: '#CCCC00',
          isHiddenDefault: true,
        },
        {
          label: 'Колиичество ариткулов в РК, шт',
          key: 'total_active_adverts_nm_id_count',
          isMoney: false,
          color: '#FFCC33',
        },
      ];

      if (!this.dashboardData.daily_stats) return false;

      const dates = [];
      this.dynamic_chart.labels = [];
      const data =
        this.chartActiveView === 'default'
          ? this.dashboardData.daily_stats.days
          : this.dashboardData.previous_periods_daily_stats.days;

      for (let day of data) {
        dates.push(day);
        this.dynamic_chart.labels.push(moment(day.day).format('DD.MM.YYYY'));
      }

      this.dynamic_chart.datasets = values.map((item) => {
        return {
          label: item.label,
          isMoney: item.isMoney || false,
          data: dates.map((obj) => {
            return obj[item.key] || 0;
          }),
          backgroundColor: this.getColor(item),
          isHiddenDefault: item.isHiddenDefault || false,
        };
      });
    },

    getJoinedList(listNow, listPrevious) {
      const list = [];
      for (let itemTop of listNow) {
        const obj = {
          advert_campaign_name: itemTop.advert_campaign_name,
          drr: itemTop.drr,
        };
        list.push(obj);
      }

      let index = 0;

      for (let item of listPrevious) {
        if (list[index]) {
          list[index].previous_advert_campaign_name = item.advert_campaign_name;
          list[index].previous_drr = item.drr;
          index++;
        }
      }

      return list;
    },

    getRandomColor() {
      const letters = '0123456789ABCDEF';
      let color = '#';
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    },

    onChangeFilter(params) {
      this.dataStart = params.start;
      this.dateEnd = params.end;
      this.getData();
    },

    getData() {
      axios({
        url: process.env.VUE_APP_API_URL + '/dashboard',
        method: 'GET',
        params: {
          start_date: moment(this.dataStart).format('YYYY-MM-DD'),
          end_date: moment(this.dateEnd).format('YYYY-MM-DD'),
          wb_account: this.currentOrganization.id,
        },
      })
        .then((response) => {
          this.dashboardData = response.data;
          this.reportOrders.title = `${this.dashboardData.top_products_percent}% артикулов которые принесли 80% заказов в рублях за выбранный период`;
          this.reportOrders.table.data = this.dashboardData.top_products;

          this.reportOrders.table.data.sort((a, b) => {
            return a.redeemed_pcs > b.redeemed_pcs ? -1 : 1;
          });

          this.reportTopProducts.table.data = this.getJoinedList(
            this.dashboardData.adverts_with_drr.top,
            this.dashboardData.previous_period_adverts_with_drr.top
          );
          this.reportBottomProducts.table.data = this.getJoinedList(
            this.dashboardData.adverts_with_drr.bottom,
            this.dashboardData.previous_period_adverts_with_drr.bottom
          );
          this.drr = this.dashboardData.total_stats.drr;

          this.setChartValue();
        })
        .catch((err) => {
          this.notSuitableTariff =
            err.response.data.detail === 'Tariff is not suitable';
          this.dashboardData = {};
          this.reportTopProducts.table.data = [];
          this.reportBottomProducts.table.data = [];
          this.reportOrders.table.data = [];
          this.drr = 0;
          this.setChartValue();
        });
    },
  },

  mounted() {
    setTimeout(() => {
      this.getData();
    }, 1000);
  },
};
</script>

<template>
  <div class="dashboard">
    <div class="dashboard__header">
      <h3 class="header__title">Статистика</h3>

      <div class="header__filters">
        <Filters
          :hideOptions="['image', 'export']"
          :onChangeFilter="onChangeFilter"
        />
      </div>
    </div>

    <div v-if="isHasSubscribe && !notSuitableTariff">
      <div class="dashboard__chart">
        <dynamicChart :data="dynamic_chart" :isShowTitles="false" />
      </div>

      <div class="dashboard__drr">
        <p class="dashboard__drr__text">
          Средний TACoO от суммы заказов по отчёту ВБ по всем РК за выбранный
          период: <b>{{ renderFormatPercent(drr) }}%</b>
        </p>

        <b-button class="dashboard__drr__last_time" @click="toggleViewChart">
          {{
            chartActiveView === 'default'
              ? 'Посмотреть за прошлый период'
              : 'За текущий период'
          }}
        </b-button>
      </div>

      <div class="dashboard__content">
        <div class="dashboard__content__reports">
          <DashboardReport :report="reportTopProducts" />
          <DashboardReport :report="reportBottomProducts" />
          <DashboardReport :report="reportOrders" />
        </div>

        <DashboardAside />
      </div>
    </div>
    <PartEmpty :notSuitableTariff="notSuitableTariff" v-else />
  </div>
</template>

<style lang="scss">
.dashboard {
  margin-top: 30px;
  padding-bottom: 100px;

  &__header {
    display: grid;
    grid-template-columns: 200px calc(100% - 200px);
    align-items: center;
    gap: 1rem;

    & .header__title {
      position: relative;
      top: 3px;
      color: #160027;
      font-size: 24px;
      font-weight: 600;
      line-height: 130%;
    }

    & .filters {
      border: none;

      & .third-report-card {
        background: none;

        & > div {
          display: flex !important;
          justify-content: space-between;
        }

        & .third-report-card__date-filter:last-child {
          width: 100%;
          justify-content: flex-end;
        }
      }
    }
  }

  &__chart {
    margin-bottom: 40px;
  }

  &__drr {
    margin: 31px 0 55px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;

    &__text {
      font-size: 20px;
      font-weight: 600;
    }

    & .dashboard__drr__last_time {
      height: 56px;
      color: #481173;
      border-radius: 8px;
      border: 1px solid #ece6f0;
      box-shadow: none;
      outline: none;
      background: #fff;

      &:hover,
      &:active,
      &:focus {
        color: #fff !important;
        border: 1px solid #481173 !important;
        background: #481173 !important;
        box-shadow: none !important;
        outline: none !important;
      }
    }
  }

  &__content {
    display: grid;
    grid-template-columns: 65% 30%;
    gap: 5%;

    &__reports {
      display: flex;
      flex-flow: column;
      gap: 64px;

      & .dashboard_report__wrap_table {
        max-height: 450px;
        padding: 0;
      }
    }
  }
}
</style>
