<template>
  <div class="companiesAdvertising">
    <div class="companiesAdvertising-filter wrap_page_filter">
      <filterAndSort
        @getReports="getReports"
        @setFilters="setFilters"
        @dateFilterHandler="dateFilterHandler"
        v-if="isShowCompFilter"
        :dateStart="from"
        :dateEnd="to"
      ></filterAndSort>
    </div>

    <div
      style="overflow-x: scroll"
      :style="'height: ' + tableHeight + 'px;'"
      class="companiesAdvertising-table"
      v-if="isHasSubscribe && hasCompaniesSuitableSubscribtion"
    >
      <SettingsColumn
        :columns="columnsForSort"
        :saved="setSettings"
        :pageName="pageName"
        v-if="isShowColumnSettings"
      />
      <b-skeleton-table
        :rows="5"
        v-if="loading"
        :columns="4"
        :table-props="{ bordered: true, striped: true }"
      ></b-skeleton-table>
      <div v-if="!loading">
        <table
          v-if="companiesAdvertising.length > 0 && !loading"
          class="companies-advertising-table table table-v2"
        >
          <thead>
            <tr>
              <th
                scope="col"
                class="sticky-col table-label text-center"
                style="
                  z-index: 10;
                  background: #fcf8ff !important;
                  border-left: 1px solid #f1f1f1;
                "
                title="Статус Вашей рекламной кампании (зеленый- РК активна, оранжевый- РК приостановлена, серый- РК завершена (в архиве))"
              >
                Статус
                <filterAndSortModal
                  :active_key="{ status_key: row_keys.status_key }"
                  @activeKeyHandler="activeKeyHandler"
                  :active-sort-key="sortKey"
                  sort-key="adv_id"
                  @sort="sort"
                  :items="setFilterContainer('status')"
                  :isUsed="bufferQueryArray.status !== null"
                  :onUpdateReport="onUpdateReport"
                  :clearReport="clearReport"
                  keyItem="status"
                ></filterAndSortModal>
              </th>

              <th
                scope="col"
                :class="[
                  row_keys.type_key
                    ? 'right-box-shadow table-label'
                    : 'sticky-col table-label',
                ]"
                style="
                  min-width: 100px;
                  max-width: 120px;
                  z-index: 10;
                  left: 84px;
                  background: #fcf8ff !important;
                "
                title="Тип Вашей рекламной кампании (АРК-Автоматическая кампания, Поиск+Каталог)"
              >
                Тип
                <filterAndSortModal
                  :active_key="{ category_name: row_keys.category_name }"
                  @activeKeyHandler="activeKeyHandler"
                  :active-sort-key="sortKey"
                  sort-key="category_name"
                  @sort="sort"
                  :items="setFilterContainer('category_name')"
                  :isUsed="bufferQueryArray.category_name !== null"
                  :onUpdateReport="onUpdateReport"
                  keyItem="category"
                  :clearReport="clearReport"
                ></filterAndSortModal>
              </th>

              <th
                scope="col"
                class="table-label"
                :class="[
                  row_keys.id_key
                    ? ' right-box-shadow table-label'
                    : ' sticky-col table-label',
                ]"
                style="
                  min-width: 300px;
                  max-width: 300px;
                  z-index: 9;
                  left: 184px;
                  background: #fcf8ff !important;
                "
                title="Название Вашей рекламной кампании (из рекламного кабинета)"
              >
                Кампания
                <filterAndSortModal
                  :active_key="{ company_key: row_keys.company_key }"
                  @activeKeyHandler="activeKeyHandler"
                  :active-sort-key="sortKey"
                  sort-key="campaign_name"
                  @sort="sort"
                  :items="setFilterContainer('campaign_name')"
                  :isUsed="bufferQueryArray.campaign_name !== null"
                  :onUpdateReport="onUpdateReport"
                  keyItem="company"
                  :clearReport="clearReport"
                ></filterAndSortModal>
              </th>

              <template v-for="(columnSort, columnIndex) in columnsForSort">
                <th
                  scope="col"
                  v-if="
                    activeColumns.includes('campaign_id') &&
                    columnSort.key === 'campaign_id'
                  "
                  @drop="onDrop(columnIndex)"
                  @dragover.prevent
                  @dragenter.prevent
                  title="ID кампании (из рекламного кабинета)"
                >
                  <div
                    draggable
                    @dragstart="startDrag(columnSort, columnIndex)"
                  >
                    ID
                  </div>
                </th>

                <th
                  scope="col"
                  class="table-label"
                  style="min-width: 200px; z-index: 1"
                  v-if="
                    activeColumns.includes('articul') &&
                    columnSort.key === 'articul'
                  "
                  @drop="onDrop(columnIndex)"
                  @dragover.prevent
                  @dragenter.prevent
                >
                  <div
                    draggable
                    @dragstart="startDrag(columnSort, columnIndex)"
                  >
                    Фото
                  </div>
                </th>
                <th
                  scope="col"
                  class="table-label"
                  style="min-width: 135px"
                  v-if="
                    activeColumns.includes('brand') &&
                    columnSort.key === 'brand'
                  "
                  @drop="onDrop(columnIndex)"
                  @dragover.prevent
                  @dragenter.prevent
                >
                  <div
                    draggable
                    @dragstart="startDrag(columnSort, columnIndex)"
                  >
                    Бренд
                    <filterAndSortModal
                      :active_key="{ brand_key: row_keys.brand_key }"
                      @activeKeyHandler="activeKeyHandler"
                      :active-sort-key="sortKey"
                      :items="setFilterContainer('brand')"
                      :isUsed="bufferQueryArray.brands !== null"
                      :onUpdateReport="onUpdateReport"
                      sort-key="brand"
                      @sort="sort"
                      keyItem="brands"
                      :clearReport="clearReport"
                    ></filterAndSortModal>
                  </div>
                </th>

                <th
                  scope="col"
                  class="table-label"
                  style="min-width: 135px"
                  v-if="
                    activeColumns.includes('start_date') &&
                    columnSort.key === 'start_date'
                  "
                  @drop="onDrop(columnIndex)"
                  @dragover.prevent
                  @dragenter.prevent
                  title="Дата первого запуска рекламной кампании"
                >
                  <div
                    draggable
                    @dragstart="startDrag(columnSort, columnIndex)"
                  >
                    Старт

                    <sort-btn
                      sort-key="create_date"
                      :active-sort-key="sortKey"
                      :isAscDefault="sortIsAsc"
                      @sort="sort"
                    />
                  </div>
                </th>
                <th
                  scope="col"
                  class="table-label"
                  style="min-width: 130px"
                  v-if="
                    activeColumns.includes('finish_date') &&
                    columnSort.key === 'finish_date'
                  "
                  @drop="onDrop(columnIndex)"
                  @dragover.prevent
                  @dragenter.prevent
                  title="Дата завершения рекламной кампании"
                >
                  <div
                    draggable
                    @dragstart="startDrag(columnSort, columnIndex)"
                  >
                    Финиш
                    <sort-btn
                      sort-key="finish_date"
                      :active-sort-key="sortKey"
                      :isAscDefault="sortIsAsc"
                      @sort="sort"
                    />
                  </div>
                </th>
                <th
                  scope="col"
                  class="table-label"
                  style="min-width: 160px"
                  v-if="
                    activeColumns.includes('views') &&
                    columnSort.key === 'views'
                  "
                  @drop="onDrop(columnIndex)"
                  @dragover.prevent
                  @dragenter.prevent
                  title="Количество единиц показов товара/товаров рекламируемых в рекламной кампании за выбранный период"
                >
                  <div
                    draggable
                    @dragstart="startDrag(columnSort, columnIndex)"
                  >
                    Просмотры, шт
                    <sort-btn
                      sort-key="views"
                      :active-sort-key="sortKey"
                      :isAscDefault="sortIsAsc"
                      @sort="sort"
                    />
                  </div>
                </th>
                <th
                  scope="col"
                  class="table-label"
                  style="min-width: 150px"
                  v-if="
                    activeColumns.includes('frequency') &&
                    columnSort.key === 'frequency'
                  "
                  @drop="onDrop(columnIndex)"
                  @dragover.prevent
                  @dragenter.prevent
                >
                  <div
                    draggable
                    @dragstart="startDrag(columnSort, columnIndex)"
                  >
                    Частота
                    <sort-btn
                      sort-key="frequency"
                      :active-sort-key="sortKey"
                      :isAscDefault="sortIsAsc"
                      @sort="sort"
                    />
                  </div>
                </th>
                <th
                  scope="col"
                  class="table-label"
                  style="min-width: 120px"
                  v-if="
                    activeColumns.includes('clicks') &&
                    columnSort.key === 'clicks'
                  "
                  @drop="onDrop(columnIndex)"
                  @dragover.prevent
                  @dragenter.prevent
                  title="Количество единиц кликов (переходов клиента в карточку товара) по товару/товарам рекламируемых в рекламной кампании за выбранный период"
                >
                  <div
                    draggable
                    @dragstart="startDrag(columnSort, columnIndex)"
                  >
                    Клики, шт
                    <sort-btn
                      sort-key="clicks"
                      :active-sort-key="sortKey"
                      :isAscDefault="sortIsAsc"
                      @sort="sort"
                    />
                  </div>
                </th>

                <th
                  scope="col"
                  class="table-label"
                  style="min-width: 120px"
                  v-if="
                    activeColumns.includes('cpc') && columnSort.key === 'cpc'
                  "
                  @drop="onDrop(columnIndex)"
                  @dragover.prevent
                  @dragenter.prevent
                  title="CPC (сost per click)- цена за 1 клик (переход клиента в карточку ) в рекламной кампании за выбранный период"
                >
                  <div
                    draggable
                    @dragstart="startDrag(columnSort, columnIndex)"
                  >
                    CPC, руб.
                    <sort-btn
                      sort-key="cpc"
                      :active-sort-key="sortKey"
                      :isAscDefault="sortIsAsc"
                      @sort="sort"
                    />
                  </div>
                </th>
                <th
                  scope="col"
                  class="table-label"
                  style="min-width: 120px"
                  v-if="
                    activeColumns.includes('ctr') && columnSort.key === 'ctr'
                  "
                  @drop="onDrop(columnIndex)"
                  @dragover.prevent
                  @dragenter.prevent
                  title="CTR (Click-Through Rate) - соотношение количества кликов к показам в процентах. (считаем формулой (количество кликов / количество показов) х 100%)"
                >
                  <div
                    draggable
                    @dragstart="startDrag(columnSort, columnIndex)"
                  >
                    CTR, %
                    <sort-btn
                      sort-key="ctr"
                      :active-sort-key="sortKey"
                      :isAscDefault="sortIsAsc"
                      @sort="sort"
                    />
                  </div>
                </th>
                <th
                  scope="col"
                  class="table-label"
                  style="min-width: 150px"
                  v-if="
                    activeColumns.includes('bid') && columnSort.key === 'bid'
                  "
                  @drop="onDrop(columnIndex)"
                  @dragover.prevent
                  @dragenter.prevent
                  title="CPM (Cost Per Mille или Cost Per Millenium) — стоимость тысячи показов рекламного объявления. Ставка, которую Вы указали в рекламном кабинете по рекламной кампании за выбранный период"
                >
                  <div
                    draggable
                    @dragstart="startDrag(columnSort, columnIndex)"
                  >
                    CPM по WB, руб
                    <sort-btn
                      sort-key="bid"
                      :active-sort-key="sortKey"
                      :isAscDefault="sortIsAsc"
                      @sort="sort"
                    />
                  </div>
                </th>
                <th
                  scope="col"
                  class="table-label"
                  style="min-width: 120px"
                  v-if="activeColumns.includes('cr') && columnSort.key === 'cr'"
                  @drop="onDrop(columnIndex)"
                  @dragover.prevent
                  @dragenter.prevent
                  title="CR (Conversion rate) Соотношение количества кликов к количеству заказов. (считаем формулой (количество кликов/ количество показов) х 100%)"
                >
                  <div
                    draggable
                    @dragstart="startDrag(columnSort, columnIndex)"
                  >
                    CR, %
                    <sort-btn
                      sort-key="cr"
                      :active-sort-key="sortKey"
                      :isAscDefault="sortIsAsc"
                      @sort="sort"
                    />
                  </div>
                </th>

                <th
                  scope="col"
                  class="table-label"
                  style="min-width: 120px"
                  v-if="
                    activeColumns.includes('total') &&
                    columnSort.key === 'total'
                  "
                  @drop="onDrop(columnIndex)"
                  @dragover.prevent
                  @dragenter.prevent
                >
                  <div
                    draggable
                    @dragstart="startDrag(columnSort, columnIndex)"
                  >
                    Расход, руб.
                    <sort-btn
                      sort-key="total"
                      :active-sort-key="sortKey"
                      :isAscDefault="sortIsAsc"
                      @sort="sort"
                    />
                  </div>
                </th>
                <th
                  scope="col"
                  class="table-label"
                  style="min-width: 140px"
                  v-if="
                    activeColumns.includes('cart') && columnSort.key === 'cart'
                  "
                  @drop="onDrop(columnIndex)"
                  @dragover.prevent
                  @dragenter.prevent
                  title="Количество единиц добавленых товаров в корзину по рекламной кампании за выбранный период. (из рекламной статистики ВБ)"
                >
                  <div
                    draggable
                    @dragstart="startDrag(columnSort, columnIndex)"
                  >
                    Корзина, шт.
                    <sort-btn
                      sort-key="cart"
                      :active-sort-key="sortKey"
                      :isAscDefault="sortIsAsc"
                      @sort="sort"
                    />
                  </div>
                </th>
                <th
                  scope="col"
                  class="table-label"
                  style="min-width: 130px"
                  v-if="
                    activeColumns.includes('orders') &&
                    columnSort.key === 'orders'
                  "
                  @drop="onDrop(columnIndex)"
                  @dragover.prevent
                  @dragenter.prevent
                  title="Количество единиц заказанных товаров по рекламной кампании за выбранный период. (из рекламной статистики ВБ)a"
                >
                  <div
                    draggable
                    @dragstart="startDrag(columnSort, columnIndex)"
                  >
                    Заказы, шт.
                    <sort-btn
                      sort-key="orders"
                      :active-sort-key="sortKey"
                      :isAscDefault="sortIsAsc"
                      @sort="sort"
                    />
                  </div>
                </th>

                <th
                  scope="col"
                  class="table-label"
                  style="min-width: 130px"
                  v-if="
                    activeColumns.includes('drr') && columnSort.key === 'drr'
                  "
                  @drop="onDrop(columnIndex)"
                  @dragover.prevent
                  @dragenter.prevent
                  title="Расход денежных средств  за выбранный период относительно рекламной кампании. (из рекламной статистики ВБ)"
                >
                  <div
                    draggable
                    @dragstart="startDrag(columnSort, columnIndex)"
                  >
                    ДРР, %
                    <sort-btn
                      sort-key="drr"
                      :active-sort-key="sortKey"
                      :isAscDefault="sortIsAsc"
                      @sort="sort"
                    />
                  </div>
                </th>

                <th
                  scope="col"
                  class="table-label"
                  style="min-width: 120px"
                  v-if="
                    activeColumns.includes('cpl') && columnSort.key === 'cpl'
                  "
                  @drop="onDrop(columnIndex)"
                  @dragover.prevent
                  @dragenter.prevent
                  title="(«Cost per lead» - цена за лид). Стоимость одного добавления в корзину по рекламной кампании за выбранный период. (считаем формулой расход,руб / на корзины в шт)"
                >
                  <div
                    draggable
                    @dragstart="startDrag(columnSort, columnIndex)"
                  >
                    CPL, руб.
                    <sort-btn
                      sort-key="cpl"
                      :active-sort-key="sortKey"
                      :isAscDefault="sortIsAsc"
                      @sort="sort"
                    />
                  </div>
                </th>
                <th
                  scope="col"
                  class="table-label"
                  style="min-width: 120px"
                  v-if="
                    activeColumns.includes('cpo') && columnSort.key === 'cpo'
                  "
                  @drop="onDrop(columnIndex)"
                  @dragover.prevent
                  @dragenter.prevent
                  title="(«Cost per Order» - стоимость оформленного заказа). Стоимость одного оформленного заказа по рекламной кампании за выбранный период. (считаем формулой расход,руб / на заказы в шт)"
                >
                  <div
                    draggable
                    @dragstart="startDrag(columnSort, columnIndex)"
                  >
                    CPO, руб.
                    <sort-btn
                      sort-key="cpo"
                      :active-sort-key="sortKey"
                      :isAscDefault="sortIsAsc"
                      @sort="sort"
                    />
                  </div>
                </th>

                <th
                  scope="col"
                  class="table-label"
                  style="min-width: 135px"
                  v-if="
                    activeColumns.includes('cpm') && columnSort.key === 'cpm'
                  "
                  @drop="onDrop(columnIndex)"
                  @dragover.prevent
                  @dragenter.prevent
                >
                  <div
                    draggable
                    @dragstart="startDrag(columnSort, columnIndex)"
                  >
                    CPM факт, руб
                    <sort-btn
                      sort-key="cpm"
                      :active-sort-key="sortKey"
                      :isAscDefault="sortIsAsc"
                      @sort="sort"
                    />
                  </div>
                </th>
                <th
                  scope="col"
                  class="table-label"
                  style="min-width: 170px"
                  v-if="
                    activeColumns.includes('working_days') &&
                    columnSort.key === 'working_days'
                  "
                  @drop="onDrop(columnIndex)"
                  @dragover.prevent
                  @dragenter.prevent
                  title="Дни работы относительно каждой рекламной кампании"
                >
                  <div
                    draggable
                    @dragstart="startDrag(columnSort, columnIndex)"
                  >
                    Дни работы
                    <sort-btn
                      sort-key="working_days"
                      :active-sort-key="sortKey"
                      :isAscDefault="sortIsAsc"
                      @sort="sort"
                    />
                  </div>
                </th>
                <th
                  scope="col"
                  class="table-label"
                  style="min-width: 135px"
                  v-if="
                    activeColumns.includes('limit_consumption') &&
                    columnSort.key === 'limit_consumption'
                  "
                  @drop="onDrop(columnIndex)"
                  @dragover.prevent
                  @dragenter.prevent
                  title="СPM (Cost Per Mille или Cost Per Millenium) — стоимость тысячи показов рекламного объявления. Фактическая ставка в рекламной кампании за выбранный период. (считаем по формуле количество показов в шт/ 1000)"
                >
                  <div
                    draggable
                    @dragstart="startDrag(columnSort, columnIndex)"
                  >
                    Расход лимита
                  </div>
                </th>
              </template>
            </tr>
          </thead>

          <tbody>
            <template v-if="!loading">
              <tr v-for="(item, idx) in companiesAdvertising" :key="idx">
                <td class="sticky-col">
                  <div class="d-flex justify-content-center align-items-center">
                    <div
                      style="
                        width: 16px;
                        height: 16px;
                        border-radius: 50%;
                        cursor: help;
                      "
                      :style="{
                        'background-color': getStatusColor(item.status),
                      }"
                      :title="item.status"
                    ></div>
                  </div>
                </td>

                <td
                  class="sticky-col"
                  style="max-width: 120px; min-width: 100px; left: 84px"
                >
                  <span class="cell-text">
                    {{
                      item.category_name === 'Автоматическая кампания'
                        ? 'АРК'
                        : item.category_name
                    }}
                  </span>
                </td>

                <td
                  class="sticky-col"
                  style="
                    min-width: 300px;
                    max-width: 300px;
                    left: 184px;
                    box-shadow: inset -8px 0 8px -8px rgba(47, 10, 73, 0.12);
                  "
                >
                  <router-link
                    :to="'/reports/statistic-advertising/' + item.campaign_id"
                    class="wb-table-link"
                    v-if="item.campaign_id !== 0"
                  >
                    <span class="cell-text">
                      {{ item.campaign_name }}
                    </span>
                  </router-link>
                </td>

                <template v-for="columnSort in columnsForSort">
                  <td
                    v-if="
                      activeColumns.includes('campaign_id') &&
                      columnSort.key === 'campaign_id'
                    "
                  >
                    <span class="cell-text">
                      {{ item.campaign_id }}
                    </span>
                  </td>

                  <td
                    class="td_product_images"
                    v-if="
                      activeColumns.includes('articul') &&
                      columnSort.key === 'articul'
                    "
                  >
                    <div class="table_container_images">
                      <img
                        v-for="product in item.products"
                        :key="`${product.nm_id}-${idx}`"
                        :src="product.image"
                        :alt="product.brand"
                        :title="product.brand"
                        class="image_preview_small"
                        @mouseover="onMouseOverImg(idx, product)"
                        @mouseleave="onMouseLeaveImg()"
                      />
                    </div>

                    <ImgPreview
                      :src="activeImg.src"
                      :alt="activeImg.brand"
                      :title="activeImg.brand"
                      v-if="activeImg && activeImg.index === idx"
                    />
                  </td>
                  <td
                    v-if="
                      activeColumns.includes('brand') &&
                      columnSort.key === 'brand'
                    "
                  >
                    <div v-for="brand in renderBrand(item)">
                      {{ brand }}
                    </div>
                  </td>
                  <td
                    v-if="
                      activeColumns.includes('start_date') &&
                      columnSort.key === 'start_date'
                    "
                  >
                    {{ renderDate(item.create_date) }}
                  </td>
                  <td
                    v-if="
                      activeColumns.includes('finish_date') &&
                      columnSort.key === 'finish_date'
                    "
                  >
                    {{ renderDate(item.finish_date) }}
                  </td>
                  <td
                    v-if="
                      activeColumns.includes('views') &&
                      columnSort.key === 'views'
                    "
                  >
                    {{ renderPrice(Math.round(item.views)) }}
                  </td>
                  <td
                    v-if="
                      activeColumns.includes('frequency') &&
                      columnSort.key === 'frequency'
                    "
                  >
                    {{ Math.round(item.frequency) }}
                  </td>
                  <td
                    v-if="
                      activeColumns.includes('clicks') &&
                      columnSort.key === 'clicks'
                    "
                  >
                    {{ renderPrice(Math.round(item.clicks)) }}
                  </td>
                  <td
                    v-if="
                      activeColumns.includes('cpc') && columnSort.key === 'cpc'
                    "
                  >
                    {{ renderFixedFormat(item.cpc) }}
                  </td>
                  <td
                    v-if="
                      activeColumns.includes('ctr') && columnSort.key === 'ctr'
                    "
                  >
                    {{ renderFixedFormat(item.ctr, 2) }}
                  </td>
                  <td
                    v-if="
                      activeColumns.includes('bid') && columnSort.key === 'bid'
                    "
                  >
                    {{ item.bid }}
                  </td>
                  <!--                <td v-if="activeColumns.includes('slot') && columnSort.key === 'slot'">-->
                  <!--                  {{ renderPrice(item.slot) }}-->
                  <!--                </td>-->
                  <td
                    v-if="
                      activeColumns.includes('cr') && columnSort.key === 'cr'
                    "
                  >
                    {{ item.cr }}
                  </td>
                  <td
                    v-if="
                      activeColumns.includes('total') &&
                      columnSort.key === 'total'
                    "
                  >
                    {{ renderPrice(Math.round(item.total)) }}
                  </td>
                  <td
                    v-if="
                      activeColumns.includes('cart') &&
                      columnSort.key === 'cart'
                    "
                  >
                    {{ renderPrice(Math.round(item.cart)) }}
                  </td>
                  <td
                    v-if="
                      activeColumns.includes('orders') &&
                      columnSort.key === 'orders'
                    "
                  >
                    {{ renderPrice(Math.round(item.orders)) }}
                  </td>
                  <td
                    v-if="
                      activeColumns.includes('drr') && columnSort.key === 'drr'
                    "
                  >
                    {{ renderPrice(renderFixedFormat(item.drr, 2)) }}
                  </td>
                  <td
                    v-if="
                      activeColumns.includes('cpl') && columnSort.key === 'cpl'
                    "
                  >
                    {{ renderPrice(Math.round(item.cpl)) }}
                  </td>
                  <td
                    v-if="
                      activeColumns.includes('cpo') && columnSort.key === 'cpo'
                    "
                  >
                    {{ renderPrice(Math.round(item.cpo)) }}
                  </td>
                  <td
                    v-if="
                      activeColumns.includes('cpm') && columnSort.key === 'cpm'
                    "
                  >
                    {{ renderPrice(Math.round(item.cpm)) }}
                  </td>
                  <td
                    v-if="
                      activeColumns.includes('working_days') &&
                      columnSort.key === 'working_days'
                    "
                  >
                    {{ renderPrice(item.working_days) }}
                  </td>
                  <td
                    v-if="
                      activeColumns.includes('limit_consumption') &&
                      columnSort.key === 'limit_consumption'
                    "
                  >
                    {{ renderLimit(item.limit_consumption) }}
                  </td>
                </template>
                <td class="table-v2-btn-settings_icon"></td>
              </tr>
              <tr class="table-row-total">
                <td class="sticky-col"></td>
                <td
                  class="sticky-col"
                  style="max-width: 120px; min-width: 100px; left: 84px"
                ></td>
                <td
                  style="
                    max-width: 120px;
                    min-width: 100px;
                    left: 184px;
                    box-shadow: inset -8px 0 8px -8px rgba(47, 10, 73, 0.12);
                  "
                  colspan="2"
                >
                  <span class="total_text">Итог</span>
                </td>

                <template v-for="columnSort in columnsForSort">
                  <td
                    v-if="
                      activeColumns.includes('company') &&
                      columnSort.key === 'company'
                    "
                  ></td>
                  <td
                    class="td_product_images"
                    v-if="
                      activeColumns.includes('articul') &&
                      columnSort.key === 'articul'
                    "
                  ></td>

                  <td
                    v-if="
                      activeColumns.includes('brand') &&
                      columnSort.key === 'brand'
                    "
                  ></td>

                  <td
                    v-if="
                      activeColumns.includes('start_date') &&
                      columnSort.key === 'start_date'
                    "
                  ></td>

                  <td
                    v-if="
                      activeColumns.includes('finish_date') &&
                      columnSort.key === 'finish_date'
                    "
                  ></td>

                  <td
                    v-if="
                      activeColumns.includes('views') &&
                      columnSort.key === 'views'
                    "
                  >
                    {{ renderPrice(Math.round(total.views)) }}
                  </td>
                  <td
                    v-if="
                      activeColumns.includes('frequency') &&
                      columnSort.key === 'frequency'
                    "
                  >
                    {{ renderPrice(Math.round(total.frequency)) }}
                  </td>
                  <td
                    v-if="
                      activeColumns.includes('clicks') &&
                      columnSort.key === 'clicks'
                    "
                  >
                    {{ renderPrice(Math.round(total.clicks)) }}
                  </td>
                  <td
                    v-if="
                      activeColumns.includes('cpc') && columnSort.key === 'cpc'
                    "
                  >
                    {{ renderFixedFormat(total.cpc) }}
                  </td>
                  <td
                    v-if="
                      activeColumns.includes('ctr') && columnSort.key === 'ctr'
                    "
                  >
                    {{ renderFixedFormat(total.ctr, 2) }}
                  </td>
                  <td
                    v-if="
                      activeColumns.includes('bid') && columnSort.key === 'bid'
                    "
                  >
                    <!--                  {{ renderPrice(total.bid) }}-->
                    -
                  </td>
                  <!--                <td v-if="activeColumns.includes('slot') && columnSort.key === 'slot'">-->
                  <!--                  {{ renderPrice(total.slot) }}-->
                  <!--                </td>-->
                  <td
                    v-if="
                      activeColumns.includes('cr') && columnSort.key === 'cr'
                    "
                  >
                    {{ renderPrice(total.cr) }}
                  </td>
                  <td
                    v-if="
                      activeColumns.includes('total') &&
                      columnSort.key === 'total'
                    "
                  >
                    {{ renderPrice(Math.round(total.total)) }}, руб
                  </td>
                  <td
                    v-if="
                      activeColumns.includes('cart') &&
                      columnSort.key === 'cart'
                    "
                  >
                    {{ renderPrice(Math.round(total.cart)) }}
                  </td>
                  <td
                    v-if="
                      activeColumns.includes('orders') &&
                      columnSort.key === 'orders'
                    "
                  >
                    {{ renderPrice(Math.round(total.orders)) }}
                  </td>
                  <td
                    v-if="
                      activeColumns.includes('drr') && columnSort.key === 'drr'
                    "
                  >
                    <!--                  {{ renderPrice(Math.round(total.drr)) }}-->
                    -
                  </td>
                  <td
                    v-if="
                      activeColumns.includes('cpl') && columnSort.key === 'cpl'
                    "
                  >
                    {{ renderPrice(Math.round(total.cpl)) }}
                  </td>
                  <td
                    v-if="
                      activeColumns.includes('cpo') && columnSort.key === 'cpo'
                    "
                  >
                    {{ renderPrice(Math.round(total.cpo)) }}
                  </td>
                  <td
                    v-if="
                      activeColumns.includes('cpm') && columnSort.key === 'cpm'
                    "
                  >
                    {{ renderPrice(Math.round(total.cpm)) }}
                  </td>
                  <td
                    v-if="
                      activeColumns.includes('working_days') &&
                      columnSort.key === 'working_days'
                    "
                  >
                    <!--                  {{ renderPrice(total.working_days) }}-->
                    -
                  </td>
                  <td
                    v-if="
                      activeColumns.includes('limit_consumption') &&
                      columnSort.key === 'limit_consumption'
                    "
                  >
                    {{ total.limit_consumption }}
                  </td>
                </template>
                <td class="table-v2-btn-settings_icon"></td>
              </tr>
            </template>
            <tr v-else>
              <td colspan="22" class="text-center">Загрузка...</td>
            </tr>
          </tbody>
        </table>

        <div v-else>
          <NoData />
        </div>
      </div>
    </div>
    <PartEmpty v-else :notSuitableTariff="!hasCompaniesSuitableSubscribtion" />
  </div>
</template>

<script>
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import filterAndSort from './components/filterAndSort';
import filterAndSortModal from './components/filterAndSortModal';
import SortBtn from '@/components/ui/SortBtn.vue';
import NoData from '@/components/no-data/NoData.vue';
import ImgPreview from '@/components/images/ImgPreview.vue';
import SettingsColumn from '@/components/setting-columns/SettingsColumn.vue';
import PartEmpty from '@/components/empty/PartEmpty.vue';
import ModalSetting from '@/components/setting-columns/ModalSetting.vue';

import { renderPrice } from '@/utils/prices';
import {
  setFilterStore,
  getFilterFromStore,
  setSortStore,
} from '@/middleware/filter-store';
import { nextTick } from 'vue';

export default {
  name: 'index',
  components: {
    PartEmpty,
    filterAndSort,
    SortBtn,
    filterAndSortModal,
    NoData,
    ImgPreview,
    SettingsColumn,
    ModalSetting,
  },
  data() {
    return {
      droppedItem: {},

      loaderCounter: 0,
      from: moment().subtract(7, 'days').format('YYYY-MM-DD'),
      to: moment().format('YYYY-MM-DD'),
      fromDefault: moment().subtract(7, 'days').format('YYYY-MM-DD'),
      toDefault: moment().format('YYYY-MM-DD'),
      loading: false,
      sortKey: 'total',
      brand: '',
      subject: '',
      row_keys: {
        id_key: false,
        type_key: false,
        company_key: false,
      },
      status: '',
      article: '',
      tableHeight: 0,
      activeImg: null,
      activeColumns: [],
      pageName: 'companiesAdvertising',
      isShowColumnSettings: false,
      columnsForSortDefault: [
        { key: 'campaign_id', isChecked: false, name: 'Id' },
        { key: 'articul', isChecked: false, name: 'Фото' },
        { key: 'brand', isChecked: false, name: 'Бренд' },
        { key: 'start_date', isChecked: false, name: 'Старт' },
        { key: 'finish_date', isChecked: false, name: 'Финиш' },
        { key: 'views', isChecked: false, name: 'Просмотры' },
        { key: 'clicks', isChecked: false, name: 'Клики' },
        { key: 'cpc', isChecked: false, name: 'CPC' },
        { key: 'ctr', isChecked: false, name: 'CTR' },
        { key: 'bid', isChecked: false, name: 'Ставка' },
        // {key: 'slot', isChecked: false, name: 'Место'},
        { key: 'cr', isChecked: false, name: 'CR' },
        { key: 'total', isChecked: false, name: 'Расход' },
        { key: 'cart', isChecked: false, name: 'Корзина' },
        { key: 'orders', isChecked: false, name: 'Заказы по РК' },
        { key: 'drr', isChecked: false, name: 'ДРР, %' },
        { key: 'cpl', isChecked: false, name: 'CPL' },
        { key: 'cpo', isChecked: false, name: 'CPO' },
        { key: 'cpm', isChecked: false, name: 'CPM' },
        { key: 'working_days', isChecked: false, name: 'Дни работы' },
        { key: 'limit_consumption', isChecked: false, name: 'Расход лимита' },
      ],
      columnsForSort: [],
      columns: [
        { key: 'campaign_id', isChecked: false, name: 'Id' },
        { key: 'articul', isChecked: false, name: 'Фото' },
        { key: 'brand', isChecked: false, name: 'Бренд' },
        { key: 'start_date', isChecked: false, name: 'Старт' },
        { key: 'finish_date', isChecked: false, name: 'Финиш' },
        { key: 'views', isChecked: false, name: 'Просмотры' },
        { key: 'clicks', isChecked: false, name: 'Клики' },
        { key: 'cpc', isChecked: false, name: 'CPC' },
        { key: 'ctr', isChecked: false, name: 'CTR' },
        { key: 'bid', isChecked: false, name: 'CPM по WB, руб' },
        // {key: 'slot', isChecked: false, name: 'Место'},
        { key: 'cr', isChecked: false, name: 'CR(клики в заказы) %' },
        { key: 'total', isChecked: false, name: 'Сумма' },
        { key: 'cart', isChecked: false, name: 'Корзина' },
        { key: 'orders', isChecked: false, name: 'Заказы' },
        { key: 'drr', isChecked: false, name: 'ДРР, %' },
        { key: 'cpl', isChecked: false, name: 'CPL' },
        { key: 'cpo', isChecked: false, name: 'CPO' },
        { key: 'cpm', isChecked: false, name: 'CPM факт, руб' },
        { key: 'working_days', isChecked: false, name: 'Дни работы' },
        { key: 'limit_consumption', isChecked: false, name: 'Расход лимита' },
      ],
      queryBufferParams: {},
      arrayOptions: {
        campaign_name: null,
        status: null,
        category_name: null,
        brands: null,
      },
      bufferQueryArray: {
        campaign_name: null,
        status: null,
        category_name: null,
        brands: null,
      },
      isFirstLoad: true,
      isShowCompFilter: false,
      defaultFilter: {},
      query: {},
      sortIsAsc: true,
    };
  },
  computed: {
    ...mapGetters([
      'companiesAdvertising',
      'currentOrganization',
      'total',
      'isHasSubscribe',
      'hasCompaniesSuitableSubscribtion',
    ]),
  },
  methods: {
    ...mapActions(['getCompaniesAdvertising']),
    renderPrice,

    saveColumnsOrder() {
      const arr = this.columnsForSort.map((column) => {
        return column.key;
      });

      localStorage.setItem(`WBLeadColumnSort${this.pageName}`, arr.toString());
    },

    startDrag(column, index, type, list = []) {
      this.droppedItem = {
        index: index,
      };

      if (type === 'second') {
        this.droppedItem.list = list;
      }
    },

    onDrop(index, type) {
      this.loading = true;

      if (type !== 'second') {
        const old = this.columnsForSort[index];
        this.columnsForSort[index] =
          this.columnsForSort[this.droppedItem.index];
        this.columnsForSort[this.droppedItem.index] = old;

        this.saveColumnsOrder();
      } else {
        const list = this.droppedItem.list;
        const old = list[index];

        this.droppedItem.list[index] = list[this.droppedItem.index];
        list[this.droppedItem.index] = old;
      }

      this.columns = this.columnsForSort.slice();
      this.isShowColumnSettings = false;

      nextTick(() => {
        this.loading = false;
        this.isShowColumnSettings = true;
      });
    },

    renderFixedFormat(value, count = 1) {
      if (Number(value) === 0) return 0;

      return Number(value).toFixed(count);
    },

    renderLimit(limit) {
      if (typeof limit === 'number') {
        return `Лимита осталось меньше чем на ${limit} часа`;
      }

      return limit;
    },

    renderDate(value) {
      let date = 'В работе';
      if (value) date = moment(value).format('DD.MM.YYYY');

      return date;
    },

    setFilterContainer(key = null) {
      const set = new Set();

      if (Array.isArray(this.companiesAdvertising) && key !== null) {
        for (let item of this.companiesAdvertising) {
          switch (key) {
            case 'brand':
              if (this.arrayOptions.brands === null) {
                if (item.brands) {
                  for (let brandItem of item.brands) set.add(brandItem);
                }
              } else {
                for (let brand of this.bufferQueryArray.brands) {
                  set.add(brand);
                }
              }
              break;

            default:
              if (this.bufferQueryArray[key] === null) {
                if (item[key]) set.add(item[key]);
              } else {
                for (let arrItem of this.bufferQueryArray[key]) {
                  set.add(arrItem);
                }
              }
          }
        }
      }

      const arr = Array.from(set);
      return arr.map((item) => {
        return {
          text: item,
          isChecked: true,
        };
      });
    },

    initColumns() {
      let storeColumns = localStorage.getItem(
        `WBLeadColumnSort${this.pageName}`
      );
      if (storeColumns === null) {
        this.activeColumns = this.columns.map((column) => column.key);
        this.columns = this.columns.map((column) => {
          column.isChecked = true;
          return column;
        });
      } else {
        const storeActiveColumns = localStorage.getItem(
          `WBLeadColumncompaniesAdvertising`
        );

        // WBLeadColumncompaniesAdvertising
        // WBLeadColumncompaniesAdvertising

        if (storeActiveColumns) {
          this.activeColumns = [];
          this.activeColumns = storeActiveColumns.split(',');

          this.columns = this.columns.map((column) => {
            if (this.activeColumns.includes(column.key))
              column.isChecked = true;
            else column.isChecked = false;

            return column;
          });
        }
      }

      this.isShowColumnSettings = true;
    },

    setSettings(options) {
      this.setSortColumns();
      this.initColumns();
      this.getReports(options);
    },

    dateFilterHandler(dates) {
      this.to = dates.to;
      this.from = dates.from;
      this.getReports();
    },
    activeKeyHandler(value) {
      let key = Object.keys(value)[0];
      this.row_keys[key] = !this.row_keys[key];
      this.$forceUpdate();
    },
    setFilters(payload) {
      this.brand = payload.brand;
      this.article = payload.article;
      this.subject = payload.subject;
      this.status = payload.status;
      this.getReports();
    },
    getStatusColor(status) {
      if (status === 'Активна') return '#4CAF50';
      if (status === 'Показы завершены') return '#999999';
      return '#FB8C00';
    },
    sort(key, isAsc) {
      this.sortKey = key;
      this.sortIsAsc = !this.sortIsAsc;

      setSortStore(
        {
          sortKey: key,
          sortIsAsc: isAsc,
        },
        'companies-advertising'
      );

      let keys = [
        'campaign_name',
        'company',
        'adv_type',
        'create_date',
        'finish_date',
        'working_days',
        'cpm',
        'cpo',
        'cpl',
        'orders',
        'drr',
        'cart',
        'bid',
        'cr',
        'slot',
        'ctr',
        'cpc',
        'clicks',
        'views',
        'create_date',
        'finish_date',
      ];
      const sortedItems = [...this.companiesAdvertising];

      if (keys.includes(key)) {
        sortedItems.sort((a, b) => {
          let aValue = this.fetchFromObject(a, key);
          let bValue = this.fetchFromObject(b, key);

          if (typeof aValue !== 'number') {
            if (!['clicks', 'views', 'total'].includes(key)) {
              if (aValue) aValue = aValue.toString().toLowerCase();
              else aValue = '';
            } else {
              aValue = Number(aValue);
            }
          } else {
            if (aValue === null) aValue = 0;
          }

          if (typeof bValue !== 'number') {
            if (!['clicks', 'views'].includes(key)) {
              if (bValue) bValue = bValue.toString().toLowerCase();
              else bValue = '';
            } else {
              bValue = Number(bValue);
            }
          } else {
            if (bValue === null) bValue = 0;
          }

          if (!['clicks', 'views'].includes(key)) {
            return isAsc
              ? aValue.toString().localeCompare(bValue.toString())
              : bValue.toString().localeCompare(aValue.toString());
          } else {
            return isAsc ? aValue - bValue : bValue - aValue;
          }
        });
      } else {
        sortedItems.sort((a, b) => {
          const aValue = this.fetchFromObject(a, key);
          const bValue = this.fetchFromObject(b, key);
          return isAsc ? aValue - bValue : bValue - aValue;
        });
      }

      // // Replace the array with the sorted one to maintain Vue reactivity
      this.companiesAdvertising.splice(
        0,
        this.companiesAdvertising.length,
        ...sortedItems
      );
    },
    fetchFromObject(obj, prop) {
      if (typeof obj === 'undefined') {
        return false;
      }

      let _index = prop.indexOf('.');
      if (_index > -1) {
        return this.fetchFromObject(
          obj[prop.substring(0, _index)],
          prop.substr(_index + 1)
        );
      }

      return obj[prop];
    },

    onUpdateReport(key, list) {
      this.getReports({
        type: 'filter',
        key: key,
        value: list.map((item) => item.text),
      });
    },

    clearReport(key) {
      switch (key) {
        case 'category':
          this.arrayOptions.category_name = null;
          this.bufferQueryArray.category_name = null;
          break;

        case 'status':
          this.arrayOptions.status = null;
          this.bufferQueryArray.status = null;
          break;

        case 'company':
          this.arrayOptions.campaign_name = null;
          this.bufferQueryArray.campaign_name = null;
          break;

        case 'brands':
          this.arrayOptions.brands = null;
          this.bufferQueryArray.brands = null;
          break;
      }

      this.getReports({
        type: 'filter',
        key: key,
        value: [],
      });
    },

    async getReports(options) {
      this.loading = true;
      this.items = [];
      let query = this.$route.query;

      let isShowZeros = false;
      let brands = null,
        campaign_name = null,
        category_name = null,
        status = null;

      let arrBrands = [],
        arrCampaign_name = [],
        arrCategory_name = [],
        arrStatus = [];

      if (!this.isFirstLoad) {
        if (options) {
          if (options.hasOwnProperty('isShowNull')) {
            if (options.isShowNull === 'true' || options.isShowNull === true) {
              isShowZeros = 'false';
            } else {
              isShowZeros = 'true';
            }
          }

          if (options.type === 'filter') {
            // оптимизировать все в одну функцию
            switch (options.key) {
              case 'brands':
                if (options.value.length > 0) {
                  arrBrands = options.value.slice();
                  brands = '';
                  this.bufferQueryArray.brands = options.value.slice();

                  for (let item of options.value) {
                    brands += `brands=${item}&`;
                  }

                  this.arrayOptions.brands = brands;
                } else {
                  this.arrayOptions.brands = null;
                  this.bufferQueryArray.brands = null;
                }
                break;

              case 'company':
                if (options.value.length > 0) {
                  arrCampaign_name = options.value.slice();
                  campaign_name = '';
                  this.bufferQueryArray.campaign_name = options.value.slice();

                  for (let item of options.value) {
                    campaign_name += `campaign_name=${item}&`;
                  }

                  this.arrayOptions.campaign_name = campaign_name;
                } else {
                  this.arrayOptions.campaign_name = null;
                }
                break;

              case 'category':
                if (options.value.length > 0) {
                  arrCategory_name = options.value.slice();
                  category_name = '';
                  this.bufferQueryArray.category_name = options.value.slice();

                  for (let item of options.value) {
                    category_name += `category_name=${item}&`;
                  }

                  this.arrayOptions.category_name = category_name;
                } else {
                  this.arrayOptions.category_name = null;
                  this.bufferQueryArray.category_name = null;
                }
                break;

              case 'status':
                if (options.value.length > 0) {
                  arrStatus = options.value.slice();
                  status = '';
                  this.bufferQueryArray.status = options.value.slice();

                  for (let item of options.value) {
                    status += `status=${item}&`;
                  }

                  this.arrayOptions.status = status;
                } else {
                  this.arrayOptions.status = null;
                  this.bufferQueryArray.status = null;
                }
                break;
            }
          }
        }
      } else {
        if (
          Array.isArray(this.defaultFilter.brands) &&
          this.defaultFilter.brands.length > 0
        ) {
          arrBrands = this.defaultFilter.brands;
          this.bufferQueryArray.brands = this.defaultFilter.brands;
        }

        if (
          Array.isArray(this.defaultFilter.campaign_name) &&
          this.defaultFilter.campaign_name.length > 0
        ) {
          arrCampaign_name = this.defaultFilter.campaign_name;
          this.bufferQueryArray.campaign_name =
            this.defaultFilter.campaign_name;
        }

        if (
          Array.isArray(this.defaultFilter.arrCategory_name) &&
          this.defaultFilter.arrCategory_name.length > 0
        ) {
          arrCategory_name = this.defaultFilter.arrCategory_name;
          this.bufferQueryArray.category_name =
            this.defaultFilter.category_name;
        }

        if (
          Array.isArray(this.defaultFilter.status) &&
          this.defaultFilter.status.length > 0
        ) {
          arrStatus = this.defaultFilter.status;
          this.bufferQueryArray.status = this.defaultFilter.status;
        }

        isShowZeros = this.defaultFilter.isShowZeros || null;
      }

      let params = {};
      const startDate = this.from
        ? moment(this.from)
        : moment().subtract(8, 'days').format('YYYY-MM-DD');
      const endDate = this.to
        ? moment(this.to)
        : moment().subtract(1, 'days').format('YYYY-MM-DD');

      try {
        params = {
          wb_account: this.currentOrganization.id || 1,
          start_date: moment(startDate).format('YYYY-MM-DD'),
          end_date: moment(endDate).format('YYYY-MM-DD'),
          nm_id: this.article || query.article,
          subject: this.subject || query.subject,
          show_zeros: isShowZeros,
          brands: arrBrands,
          campaign_name: arrCampaign_name,
          category_name: arrCategory_name,
          status: arrStatus,
        };
      } catch (err) {
        console.error(err);
      }

      for (let key of Object.keys(params)) {
        if (key && !params[key]) {
          delete params[key];
        }
      }

      if (!this.isFirstLoad) setFilterStore(params, 'companies-advertising');

      await this.getCompaniesAdvertising({
        params: params,
        arrayOptions: this.arrayOptions,
      });

      if (this.defaultFilter.sortKey) {
        this.sort(this.defaultFilter.sortKey, this.defaultFilter.sortIsAsc);
      } else {
        this.sort('total', false);
      }

      if (this.isFirstLoad) this.isFirstLoad = false;

      this.loaderCounter++;
      this.loading = false;
    },
    calcTableHeight() {
      this.tableHeight = 0;

      this.$nextTick(() => {
        if (document.getElementById('content')) {
          this.tableHeight =
            document.getElementById('content').clientHeight - 150;
        }
      });
    },

    onMouseOverImg(index, product) {
      this.activeImg = {
        index: index,
        src: product.image,
        brand: product.brand,
      };
    },

    onMouseLeaveImg() {
      this.activeImg = null;
    },

    renderBrand(item) {
      const brands = new Set();

      if (item && Array.isArray(item.products)) {
        for (let product of item.products) {
          brands.add(product.brand);
        }
      }

      return Array.from(brands);
    },

    setSortColumns() {
      let columns = localStorage.getItem(`WBLeadColumnSort${this.pageName}`);

      const storeUpdate = localStorage.getItem('WBLeadColumnSubReports2Update');
      if (storeUpdate === null) {
        localStorage.setItem('WBLeadColumnSubReports2Update', '17-05-2024');
        columns = null;
      }

      if (!columns) {
        this.columnsForSort = this.columnsForSortDefault.map((item) => {
          item.isChecked = true;
          this.activeColumns.push(item.key);
          return item;
        });
        return false;
      }

      this.columnsForSort = [];
      for (let column of columns.split(',')) {
        for (let item of this.columnsForSortDefault) {
          if (column === item.key) {
            item.isChecked = true;
            this.activeColumns.push(column);
            this.columnsForSort.push(item);
          }
        }
      }
    },

    initFilters() {
      const filter = getFilterFromStore('companies-advertising');

      if (filter) {
        this.from = null;
        this.to = null;
        this.query.from = null;
        this.query.to = null;

        this.defaultFilter = {
          brands: filter.brands || null,
          campaign_name: filter.campaign_name || null,
          category_name: filter.category_name || null,
          status: filter.status || null,
          isShowZeros: filter.show_zeros || null,
          sortKey: filter.sortKey || null,
          sortIsAsc: filter.sortIsAsc || null,
          subject: filter.subject || null,
        };

        this.subject = filter.subject || null;
      }

      this.isShowCompFilter = true;

      this.getReports();
    },
  },
  mounted() {
    this.initFilters();
    this.calcTableHeight();
    this.initColumns();
    this.setSortColumns();

    window.addEventListener('resize', () => {
      this.calcTableHeight();
    });
  },
};
</script>

<style lang="scss" scoped>
@import './index.scss';

table thead th {
  border: none !important;
}

table {
  position: relative;

  thead {
    position: sticky;
    top: 0;
    z-index: 2;
    tr {
      width: 100%;
    }
  }
}
table tbody td.sticky-col {
  position: sticky;
  left: -1px;
  z-index: 1;
}

.no-data {
  margin-top: 10px;
}

.image_preview_small {
  height: 16px;
  width: 16px;
}

.app .companies-advertising-table {
  & th {
    cursor: help;

    &:nth-child(1) {
      min-width: 84px;
      width: 84px;
      max-width: 84px;
    }
  }

  & td {
    padding: 6px 10px !important;
  }

  & tbody tr:nth-last-child(-n + 5) {
    .preview {
      top: auto;
      bottom: calc(100% + 2px);
    }
  }
}

.total_text {
  font-weight: 700;
}

.companiesAdvertising {
  & .table_settings {
    top: 40px !important;
  }
}

.cell-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.table-row-total {
  & td {
    position: sticky;
    bottom: 0;
    z-index: 10 !important;
    font-weight: 700;

    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: -1px;
      width: 100%;
      height: 1px;
      background: #fff;
    }

    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3) {
      z-index: 11 !important;
    }

    &:nth-child(1) {
      max-width: 84px;
      min-width: 84px;
    }

    &:nth-child(2) {
      max-width: 122px;
      min-width: 122px;
      left: 84px !important;
    }

    &:nth-child(3) {
      min-width: 100px;
      max-width: 110px;
      left: 184px !important;
    }
  }
}

.table_container_images {
  &::-webkit-scrollbar {
    width: 0 !important;
    height: 0 !important;
  }
}
</style>
