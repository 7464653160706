<script>
export default {
  name: "profileHeader",
  props: {
    user: {
      type: Object,
      required: false,
      default: {}
    }
  }
}
</script>

<template>
  <div class="profile_header">
    <img class="profile_header__photo"
         src="/img/hat.png"
         alt=""
    />
    <h5 class="profile_header__name">
      Профиль
    </h5>
  </div>
</template>

<style scoped lang="scss">
.profile_header {
  position: relative;
  padding-left: 132px;
  margin-bottom: 45px;

  &__photo {
    position: absolute;
    bottom: -22px;
    left: 0;
    height: 120px;
    width: 120px;
  }

  &__name {
    color: #160027;
    font-size: 24px;
    font-weight: 500;
  }
}
</style>
