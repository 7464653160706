import { render, staticRenderFns } from "./LandingBanner.vue?vue&type=template&id=0e80a24d&scoped=true"
import script from "./LandingBanner.vue?vue&type=script&setup=true&lang=js"
export * from "./LandingBanner.vue?vue&type=script&setup=true&lang=js"
import style0 from "./LandingBanner.vue?vue&type=style&index=0&id=0e80a24d&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0e80a24d",
  null
  
)

export default component.exports