<template>
  <div class="d-inline cursor-pointer" @click="sort">
      <div class="d-inline">
          <template v-if="isActive && isShow">
              <img v-if="isAscDefault"
                   height="18"
                   class="ml-1"
                   src="@/assets/img/ArrowsDownUp.svg"
              >
              <img v-else
                   height="18"
                   class="ml-1 sort_desc"
                   src="@/assets/img/SortDescending.svg"
              >
          </template>

          <img v-else
               height="18"
               class="ml-1"
               src="@/assets/img/Sort-Default.svg"
          >
      </div>
  </div>
</template>

<script>
export default {
  name: 'SortBtn',

  props: {
    sortKey: {
        type: String
    },
    activeSortKey: {
        type: String
    },
    isAscDefault: {
      type: Boolean,
      required: false,
      default: true
    },
    date: {
      type: String,
      required: false,
      default: null
    },
    isShow: {
      type: Boolean,
      required: false,
      default: true
    }
  },

  data() {
    return {
      isAsc: this.isAscDefault
    }
  },

  computed: {
      isActive() {
          return this.sortKey === this.activeSortKey
      }
  },

  methods: {
    sort() {
      this.isAsc = !this.isAsc;
      this.$emit('sort', this.sortKey, this.isAsc, this.date)
    }
  }
}
</script>

<style lang="scss" scoped>
.sort_desc {
  transform: rotate(180deg);
}
</style>
