<script setup>
import LandingTariff from '@/components/landing/LandingTariff.vue';

const tariffs = [
  {
    id: 19,
    name: '5 отчёт',
    price: '490₽',
    discount: 'месяц',
    benefits: [
      'Организаций: 1',
      'Отчёт "Динамика заказов и продаж"',
      'Заказы и Продажи за 90 дней',
    ],
    color: 'purpule',
  },
  {
    id: 1,
    name: 'Базовый',
    price: '1990₽',
    discount: 'месяц',
    benefits: [
      'Организаций: 2',
      '5 отчётов',
      'Дашборд',
      'Рекламная статистика за всю историю',
      'Заказы и Продажи за 90 дней',
    ],
    color: 'purpule',
  },
  {
    id: 2,
    name: 'Расширенный',
    price: '3990₽',
    discount: 'месяц',
    benefits: [
      'Организаций: 5',
      '5 отчётов',
      'Дашборд',
      'Рекламная статистика за всю историю',
      'Заказы и Продажи за 90 дней',
    ],
    color: 'purpule',
  },
  {
    id: 3,
    name: 'Максимальный',
    price: '7990₽',
    discount: 'месяц',
    benefits: [
      'Организаций: 10',
      '5 отчётов',
      'Дашборд',
      'Рекламная статистика за свою историю',
      'Заказы и Продажи за 90 дней',
    ],
    color: 'purpule',
  },
];
</script>

<template>
  <section class="landing_tariffs" id="Tariffs">
    <h6 class="landing_tariffs__title text-center">Тарифы WBLeads</h6>
    <p class="landing_tariffs__description text-center">
      Выберете тариф, который лучше подходит под ваши потребности
    </p>

    <div class="landing_tariffs__container">
      <LandingTariff
        v-for="(tariff, index) in tariffs"
        :key="tariff.id"
        :tariff="tariff"
        :index="index + 1"
      />
    </div>
  </section>
</template>

<style scoped lang="scss">
.landing_tariffs {
  margin: 100px 0;
  padding: 40px 0;

  &__title {
    margin-bottom: 10px;
    font-size: 50px;
    font-weight: 500;
  }

  &__description {
    margin-bottom: 56px;
    font-size: 18px;
  }

  &__container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 100px;
  }
}
</style>
