export const state = {
  loading: false,
  totalsItems: {
    reports_totals: {},
    products_totals: {},
  },
  items: [],
  options: null,
  filters: null,
};
