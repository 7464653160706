import axios from "axios";
import moment from "moment";

const state = {
  productStatistic: [],
  dynamic_chart: {
    labels: [],
    datasets: [],
  },
  total_drr: "",
  from: moment().subtract(7, "days").format("YYYY-MM-DD"),
  to: moment().format("YYYY-MM-DD"),
  totals: [],
  items: [],
  productStatisticChart: [],
  limit: null
};

const getters = {
  productStatistic: (state) => state.productStatistic,
  dynamic_chart: (state) => state.dynamic_chart,
  total_drr: (state) => state.total_drr,
  items: (state) => state.items,
  totals1: (state) => state.totals,
  productStatisticChart: (state) => state.productStatisticChart,
  limit: (state) => state.limit
};

const mutations = {
  SET_LIMIT(state, value) {
    state.limit = value;
  },

  SET_FROM(state, from) {
    state.from = from;
  },
  SET_TO(state, to) {
    state.to = to;
  },

  SET_PRODUCT_ITEMS(state, items) {
    state.items = items;
  },

  SET_PRODUCT_STATISTIC(state, payload) {
    console.log('pay', payload);
    state.productStatistic = payload;
    this.commit("TRANSFORM_DATA");
  },

  SET_PRODUCT_TOTALS(state, payload) {
    state.totals = payload;
  },

  SET_DATE(state, payload) {
    state.to = payload.to;
    state.from = payload.from;
  },
  SET_ADVERTS(state, payload) {
    state.productStatisticChart = payload;
  },
  SET_TOTAL_DRR(state) {
    state.total_drr = state.productStatistic.total_data.reduce(
      (accumulator, currentVal) => accumulator + currentVal.drr,
      0
    );
  },
  SET_TOTAL_DATA(state, payload) {
    state.productStatistic.total_data = payload;
  },
  SET_CHART(state) {
    const values = [
      { label: "Общий расход по всем РК", key: "consumption", isMoney: true, isHiddenDefault: true, color: '#009900' },
      { label: "Просмотры общ", key: "views", isMoney: false, isHiddenDefault: true, color: '#CC9966' },
      { label: "Клики общ", key: "clicks", isMoney: false, color: '#996633' },
      { label: "CPM факт, руб", key: "cpm_rub", isMoney: false, color: '#66FF00' },
      // { label: "Добавления в корзину", key: "added_cart_pcs", isMoney: false },
      { label: "Заказы по РК", key: "placing_order_pcs", isMoney: false, color: '#FF99CC' },
      { label: "Заказы по отчету ВБ, шт", key: "ordered_in_pieces", isMoney: false, isHiddenDefault: true, color: '#FF6666' },
      { label: "Заказы по отчету ВБ, руб", key: "ordered_in_rubles", isMoney: true, color: '#CC0033' },
      { label: "Продажи по отчету ВБ, шт", key: "redeemed", isMoney: false, color: '#9933CC' },
      { label: "Продажи по отчету ВБ, руб", key: "to_transfer", isMoney: true, color: '#6600CC' },
      { label: "Цена, руб", key: "discount_price", isMoney: true, color: '#33CCCC' },
      { label: "Цена с СПП, руб", key: "seller_price", isMoney: true, isHiddenDefault: true, color: '#339999' },
      { label: "Остаток товара, шт", key: "stock", isMoney: false, isHiddenDefault: true, color: '#0066FF' },
      { label: "CTR средний по артикулу, %", key: "ctr_percent", isMoney: false, color: '#003366'}
    ];

    if (state.totals) {
      const dates = [];
      state.dynamic_chart.labels = [];
      for(let day in state.totals.days) {
        dates.push(state.totals.days[day]);
        state.dynamic_chart.labels.push(moment(day).format('DD.MM.YYYY'));
      }

      state.dynamic_chart.datasets = values.map((item) => {
        return {
          label: item.label,
          isMoney: item.isMoney || false,
          data:dates.map((obj) => {
            let value = obj[item.key];

            if(item.key === 'seller_price') {
              value = obj[item.key] / 100;
              value = value.toFixed(1)
            }

            return value
          }),
          backgroundColor: item.color || getRandomColor(),
          isHiddenDefault: item.isHiddenDefault || false
        };
      });
    }
  },
  TRANSFORM_DATA(state) {
    const transformedData = [];
    const transformedTotalData = [];

    const startDate = moment(state.from);
    const endDate = moment(state.to);
    const dateRange = getDateRange(startDate, endDate);

    for(let advert of state.productStatistic?.adverts) {
      const transformedAdvert = {
        advert_name: advert.campaign_name,
        stats: dateRange.map((date) => {
          // const statsForDate = advert.stats.find((stat) => stat.date === date);
          const statsForDate = null;

          if (statsForDate) {
            return statsForDate;

          } else {
            return {
              date: date,
              week: getWeekDay(date),
              consumption: advert.days[date]?.consumption || null,
              views: advert.days[date]?.views || null,
              cpm_rub: advert.days[date]?.cpm_rub  || null,
              added_cart_pcs: advert.days[date]?.added_cart_pcs  || null,
              placing_order_pcs: advert.days[date]?.placing_order_pcs || null,
              cp2: advert.days[date]?.cp2  || null,
              cost_cart_rub: advert.days[date]?.cost_cart_rub || null,
              cost_order_rub: advert.days[date]?.cost_order_rub || null,
            };
          }
        }),
      };
      transformedTotalData.push(transformedAdvert);
    }

    this.commit("SET_ADVERTS", transformedData);
    this.commit("SET_TOTAL_DATA", transformedTotalData);
    this.commit("SET_TOTAL_DRR", transformedTotalData);
    this.commit("SET_CHART");
  },
};

const actions = {
  updateDateRangeFromQueryParams({ commit }, queryParams) {
    const { from, to } = queryParams;
    // If 'from' and 'to' query parameters are present, use them
    if (from && to) {
      commit("SET_FROM", from);
      commit("SET_TO", to);
    } else {
      // Use default values if query parameters are not present
      commit("SET_FROM", moment().subtract(7, "days").format("YYYY-MM-DD"));
      commit("SET_TO", moment().format("YYYY-MM-DD"));
    }
  },
  async getProductStatistic({ commit }, payload) {
    try {
      return await  axios
        .get("/new-reports/dyn_product/", {
          params: { ...payload },
        })
        .then((response) => {
          commit("SET_PRODUCT_ITEMS", response.data.data.adverts);
          commit("SET_PRODUCT_TOTALS", response.data.totals);
          commit("SET_PRODUCT_STATISTIC", response.data.data);

          if(response.data.data.adverts[0]) {
            commit("SET_LIMIT", response.data.data.adverts[0].limit_hours_left);
          } else {
            commit("SET_LIMIT", null);
          }

          return response.data.data;
        });
    } catch (error) {
      console.error(error);

      commit("SET_PRODUCT_STATISTIC", {});
      commit("SET_PRODUCT_TOTALS", []);
      commit("SET_LIMIT", null);
    }
  },

   postDirectCompetitor({}, payload) {
     axios.post("/reports/dyn_art/add/direct_competitors/", {
      competitor_nm_id: payload.competitor_nm_id,
      nm_id: payload.article,
    });
  },
   postKeyword({}, payload) {
     axios.post("/reports/dyn_art/add/keyword/", {
      nm_id: payload.nm_id,
      name: payload.keyword,
    });
  },
  async deleteKeyword({}, payload) {
    await axios.delete("/reports/dyn_art/keyword/" + payload.id);
  },
};

// Helper functions

function getRandomColor() {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

function getDateRange(startDate, endDate) {
  const dateRange = [];
  const currentDate = new Date(startDate);

  while (currentDate <= endDate) {
    dateRange.push(currentDate.toISOString().split("T")[0]);
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return dateRange;
}

function getWeekDay(dateString) {
  const daysOfWeek = ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"];
  const date = new Date(dateString);
  const dayOfWeek = date.getDay();
  return daysOfWeek[dayOfWeek];
}

export default {
  state,
  getters,
  mutations,
  actions,
};
