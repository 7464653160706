const storeName = 'WBLeadFilter';

export const getFilterFromStore = (pageName = '') => {
    const filter = localStorage.getItem(`${storeName}-${pageName}`);

    if(!filter) return null;

    return JSON.parse(filter);
}

export const setFilterStore = (filter, pageName = '') => {
    localStorage.setItem(`${storeName}-${pageName}`, JSON.stringify(filter));
}

export const setSortStore = (sort_value, pageName = '') => {
    let filter = getFilterFromStore(pageName);

    if(!filter) filter = {};
    localStorage.setItem(`${storeName}-${pageName}`, JSON.stringify({
        ...filter,
        ...sort_value
    }));
}
