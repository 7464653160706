import Cookies from "js-cookie";
import axios from "axios";

// state
const state = () => ({
  accessToken: Cookies.get("access_token"),
  refreshToken: Cookies.get("refresh_token"),
});

// getters
const getters = {
  accessToken: (state) => state.accessToken,
  refreshToken: (state) => state.refreshToken,
};

// mutations
const mutations = {
  SAVE_TOKEN(state, payload) {
    state.accessToken = payload.access_token;
    state.refreshToken = payload.refresh_token;

    Cookies.set("access_token", payload.access_token, { expires: 7 });
    Cookies.set("refresh_token", payload.refresh_token, { expires: 60 });
    if(payload.user) {
      delete payload.user.data.token
      localStorage.setItem('user', JSON.stringify(payload.user.data));
    }
  },

  LOGOUT(state) {
    state.accessToken = null;
    state.refreshToken = null;

    Cookies.remove("access_token");
    Cookies.remove("refresh_token");
  },
};

// actions
const actions = {
  login(context, credentials) {
    return new Promise((resolve) => {

      axios.post(
          "/auth/signin/",
          credentials
      )
          .then((response) => {
            context.commit("SAVE_TOKEN", {
              access_token: response.data.access,
              refresh_token: response.data.refresh
            });
            Cookies.set("access_token", response.data.access, { expires: 7 });
            Cookies.set("refresh_token", response.data.refresh, { expires: 60 });
            resolve('success');

          })
          .catch((e) => {
            if(
                e &&
                e.response &&
                e.response.data &&
                e.response.data.detail === 'user is not activated'
            ) {
              resolve('error');

            } else {
              resolve('invalid-pass');
            }
          })
    })
  },
  async checkCode(context, payload) {
    return new Promise((resolve) => {
      this.processing = true;

      axios
          .post("/auth/confirm-signup/", {
            email: payload.email,
            code: payload.code,
            is_registration: payload.is_registration,
          })
          .then((res) => {
            context.commit("SAVE_TOKEN", {
              access_token: res.data.access,
              refresh_token: res.data.refresh,
              user: res
            });
            this.processing = false;
            resolve('redirect');

          })
          .catch((err) => {
            console.error(err);
            this.processing = false;
            resolve('error');
          })
    })
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
