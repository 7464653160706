<template>
  <div class="auth-card">
    <div class="title mb-1">Код подтверждения</div>

    <div class="subtitle mb-4">
      Введите код, отправленный на вашу почту
    </div>

    <div class="invalid_message" v-if="isShowError">
      Неправильный код
    </div>

    <div id="otp" class="inputs d-flex flex-row justify-content-center mb-3">
      <input
        class="m-2 text-center form-control rounded"
        @keyup="inputenter(1)"
        type="text"
        id="input1"
        maxlength="1"
      />
      <input
        class="m-2 text-center form-control rounded"
        @keyup="inputenter(2)"
        type="text"
        id="input2"
        maxlength="1"
      />
      <input
        class="m-2 text-center form-control rounded"
        @keyup="inputenter(3)"
        type="text"
        id="input3"
        maxlength="1"
      />
      <input
        class="m-2 text-center form-control rounded"
        @keyup="inputenter(4)"
        type="text"
        id="input4"
        maxlength="1"
      />
      <input
        class="m-2 text-center form-control rounded"
        @keyup="inputenter(5)"
        type="text"
        id="input5"
        maxlength="1"
      />
      <input
        class="m-2 text-center form-control rounded"
        @keyup="inputenter(6)"
        type="text"
        id="input6"
        maxlength="1"
      />
    </div>

    <div v-if="countdown !== 0" class="resend">
      Переотправить код через
      {{ new Date(countdown * 1000).toISOString().substring(14, 19) }}
    </div>
    <div v-else @click="resend" class="resend" style="cursor: pointer">
      Отправить код заново
    </div>

    <b-btn
      @click="sendOtp"
      :disabled="code.length !== 6 || processing"
      pill
      class="mt-4 w-100 py-2"
      variant="wblead"
      >Подтвердить</b-btn
    >

    <div class="no-account mt-5 text-center">
      Уже есть аккаунт?
      <a
        @click.prevent="$router.push({ name: '/' })"
        href="/auth/login"
        >Войти</a
      >
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapActions } from "vuex";
export default {
  name: "LoginView",

  data() {
    return {
      countdown: 0,
      interval: null,
      processing: false,
      code: "",
      isShowError: false
    };
  },

  created() {
    this.startTimer();
  },

  destroyed() {
    this.clearTimer();
  },

  methods: {
    ...mapActions('auth', ['checkCode']),

    async resend() {
      this.isShowError = false;

      axios({
        url: process.env.VUE_APP_API_URL + '/auth/send-confirmation/',
        method: 'POST',
        data: {
          email: this.$route.query.email,
          code: this.code,
          is_registration: this.$route.query.registration === 'true' || this.$route.query.registration === true,
        }
      })
    },

    async sendOtp() {
      try {
        this.isShowError = false;
        const status = await this.checkCode(
            { email: this.$route.query.email, code: this.code,is_registration: this.$route.query.registration === 'true' || this.$route.query.registration === true }
        );

        if(status === 'redirect') {
          this.$router.push({ name: '/' });
          location.reload();
          return;

        } else {
          this.isShowError = true;
        }

      } catch(err) {
        console.error(err);
      }
    },

    startTimer() {
      this.clearTimer();
      this.countdown = 60;

      this.interval = setInterval(() => {
        if (this.countdown > 0) {
          this.countdown -= 1;
        } else {
          this.clearTimer();
        }
      }, 1000);
    },

    clearTimer() {
      clearInterval(this.interval);
      this.interval = null;
    },

    inputenter() {
      // navigator.clipboard.readText()
      //     .then(text => {
      //       // `text` содержит текст, прочитанный из буфера обмена
      //       console.log('trext', text);
      //     })
      //     .catch(err => {
      //       console.error(err);
      //     })



      const inputs = document.querySelectorAll("#otp > *[id]");


      for (let i = 0; i < inputs.length; i++) {
        inputs[i].addEventListener("keydown", function (event) {
          if ((event.ctrlKey || event.metaKey) && event.keyCode == 67) {
            console.log('x1');
            inputs[i].value = event.key;
            if (i !== inputs.length - 1) inputs[i + 1].focus();
            event.preventDefault();
            return;
          }

          // Ctrl+V or Cmd+V pressed?
          if ((event.ctrlKey || event.metaKey) && event.keyCode == 86) {
            console.log('x2 new');
            navigator.clipboard.readText()
                .then(text => {
                  inputs[i].value = text[i] || null
                  if (i !== inputs.length - 1 && text[i]) inputs[i + 1].focus();
                })
            return;
          }

          // Ctrl+X or Cmd+X pressed?
          if ((event.ctrlKey || event.metaKey) && event.keyCode == 88) {
            console.log('x3');
            inputs[i].value = event.key;
            if (i !== inputs.length - 1) inputs[i + 1].focus();
            event.preventDefault();
            return;
          }

          if (event.key === "Backspace") {
            inputs[i].value = "";
            if (i !== 0) inputs[i - 1].focus();

          } else {
            if (i === inputs.length - 1 && inputs[i].value !== "") {
              return true;

            } else if (event.keyCode > 47 && event.keyCode < 58) {
              inputs[i].value = event.key;
              if (i !== inputs.length - 1) inputs[i + 1].focus();
              event.preventDefault();

            } else if (event.keyCode > 64 && event.keyCode < 91) {
              inputs[i].value = String.fromCharCode(event.keyCode);
              if (i !== inputs.length - 1) inputs[i + 1].focus();
              event.preventDefault();
            }
          }
        });
      }

      let result = "";

      for (let i = 0; i < inputs.length; i++) {
        result += inputs[i].value;
      }

      this.code = result;
    },

    copyListener() {
      console.log('copy listener');
      const inputs = document.querySelectorAll("#otp > *[id]");

      $('#input1').bind('paste', (event) => {
        navigator.clipboard.readText()
            .then(text => {
              console.log('trext', text);
              for (let i = 0; i < inputs.length; i++) {
                inputs[i].value = text[i] || null
                if (i !== inputs.length - 1 && text[i]) inputs[i + 1].focus();
                event.preventDefault();
              }

              this.code = text;
            })
            .catch(err => {
              console.error(err);
            })

        console.log('pase', event.value);
      });
    }
  },

  mounted() {
    this.inputenter();

    this.copyListener();
  }
};
</script>

<style scoped>
.resend {
  text-align: center;
  font-family: "Raleway";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 130%;
  color: #481173;
}

.inputs input {
  width: 48px;
  height: 56px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.form-control:focus {
  box-shadow: none;
  border: 2px solid #6f1667;
}

.invalid_message {
  margin: 1rem 0;
  color: red;
  font-size: 16px;
  text-align: center;
}
</style>
