<script setup>
import {getCurrentInstance, ref} from 'vue';

const props = defineProps({
  columns: Array,
  pageName: String,
  saved: Function,
  columnName: String | null,
  columnsSub: Array | [],
  saveSub: Function | null
});

const bvModal = getCurrentInstance().proxy.$root.$bvModal;
const view = ref('general');
const generalOptions = ref({
  isShowNull: false
});
const columns = ref(props.columns || []);

const show = () => {
  setSortColumns();
  bvModal.show('ModalSettingColumn');
  setSortable();

  console.log('columns', props.columns);
}

const close = () => bvModal.hide('ModalSettingColumn');

const save = () => {
  try {
    saveSortColumns();

    let strColumns = [];

    for(let column of columns.value) {
      if(column.hasOwnProperty('key') && (column.isChecked !== 'false' && column.isChecked !== false)) {
        strColumns.push(column.key)
      }
    }

    const value = JSON.stringify({name: props.pageName, options: strColumns.toString()});

    localStorage.setItem(`WBLeadColumn${props.pageName}`, value)
    props.saved(generalOptions.value);

    if(props.columnsSub) {
      props.saveSub(props.columnsSub);
    }

    close();

  } catch(err) {
    console.error(err);
  }
}

const changeView = (type) => {
  view.value = type;

  if(type === 'table') setSortable();
}

const renderLabel = () => {
  switch (props.pageName) {
    case 'productsAdvertising':
      return 'Скрыть товары с нулями';

    default:
      return 'Скрыть кампании с нулями';
  }
}

const saveSortColumns = () => {
  const arr = [];
  $('.js-sortable__item').each(function() {
    arr.push($(this).data('key'));
  });

  localStorage.setItem(`WBLeadColumnSort${props.pageName}`, arr.toString());
}

const setSortColumns = () => {
  columns.value = props.columns.slice();
}

const renderColumnName = (column) => {
  if(props.columnName) return column[props.columnName];

  return column.name;
}

const setSortable = () => {
  setTimeout(() => {
    $( "#sortable" ).sortable();
  }, 500);
}

const removeSettings = () => {
  localStorage.removeItem(`WBLeadColumn${props.pageName}`);
  props.saved(generalOptions.value);
  close();
}

defineExpose({
  show
})
</script>

<template>
  <b-modal
      id="ModalSettingColumn"
      @hide="close"
      centered
      title="Настройка колонок"
  >
    <nav class="modal_settings__tabs">
      <a class="tab"
         :class="{'active': view === 'general'}"
         @click="changeView('general')"
      >Общие</a>
      <a class="tab"
         :class="{'active': view === 'table'}"
         @click="changeView('table')">Таблица</a>
    </nav>

    <div class="modal_settings__content">
      <div v-if="view === 'table'">
        <ul id="sortable" class="modal_settings__list">
          <li v-for="column in columns" :key="column.key"
              :data-key="column.key"
              class="js-sortable__item"
          >
            <b-form-checkbox
                :id="`checkbox-${column.key}`"
                :name="`checkbox-${column.key}`"
                v-model="column.isChecked"
                variant="wblead"
                unchecked-value="false"
            >
              <span class="modal_settings__text">{{ renderColumnName(column) }}</span>
            </b-form-checkbox>
          </li>
        </ul>

        <ul class="modal_settings__list">
          <li v-for="columnSub in columnsSub" :key="columnSub.key">
            <b-form-checkbox
                :id="`checkbox-${columnSub.key}`"
                :name="`checkbox-${columnSub.key}`"
                v-model="columnSub.isChecked"
                variant="wblead"
                unchecked-value="false"
            >
              <span class="modal_settings__text">{{ columnSub.name }}</span>
            </b-form-checkbox>
          </li>
        </ul>
      </div>

      <div class="modal_settings__general" v-if="view === 'general'">
        <div>
          <b-form-checkbox
              id="CheckboxShowNull"
              v-model="generalOptions.isShowNull"
              variant="wblead"
              unchecked-value="false"
          >
            <span class="modal_settings__text">
              {{ renderLabel() }}
            </span>
          </b-form-checkbox>
        </div>
      </div>
    </div>

    <template #modal-footer>
      <div class="modal_settings__footer">
        <b-btn  variant="default" @click="removeSettings">сбросить все фильтры</b-btn>

        <div class="control_right">
          <b-btn variant="default" @click="close">Отмена</b-btn>
          <b-btn class="wb-btn" @click="save">Сохранить</b-btn>
        </div>
      </div>
    </template>
  </b-modal>
</template>

<style lang="scss">
.modal_settings__list {
  padding-left: 0;
  list-style: none;
}

.modal_settings__text {
  position: relative;
  top: -3px;
  font-size: 20px;
  cursor: pointer;
}

.modal_settings__content {
  padding-top: 1.5rem;
}

.modal_settings__tabs {
  & .tab {
    padding: .5rem 1rem;
    border: 1px solid #481173;
    color: #481173;
    text-decoration: none;
    cursor: pointer;
    background: none;

    &.active {
      color: #fff;
      background: #481173;
    }
  }
}

#ModalSettingColumn {
  & .modal-footer {
    display: block;

    & .modal_settings__footer {
      display: flex;
      align-items: center;
      justify-content: space-between;

      & .control_right {
        display: flex;
        gap: 1rem;
        align-items: center;
      }
    }
  }
}
</style>
