import axios from 'axios';

class OrganizationAPI {
    edit(item, id) {
        return axios({
            url: `/wb-profile/accounts/${id}/`,
            method: 'PATCH',
            data: item
        })
    }
}

export default new OrganizationAPI();