<template>
  <div id="LineChart" class="line_chart">
    <button @click="toggleDataSetView"
            class="line_chart__toggle_view"
    >{{ isShowAll ? 'скрыть все' : 'показать все' }}
    </button>

    <canvas id="Chart"></canvas>
  </div>
</template>

<script>
export default {
  name: "LineChart",

  props: [
    'data'
  ],

  watch: {
    $props: {
      handler() {
        setTimeout(() => {
          this.draw();
        }, this.counter === 0 ? 1000 : 0);
      },
      deep: true,
      immediate: true,
    },
  },

  data() {
    return {
      counter: 0,
      isShowAll: true,
      chart: null,
      counterY: 0,
      isInit: true
    }
  },

  methods: {
    toggleDataSetView() {
      this.isShowAll = !this.isShowAll;

      for (let i = 0; i < this.data.datasets.length; i++) {
        this.chart.getDatasetMeta(i).hidden = !this.isShowAll;
        this.chart.config.options.scales[i].display = this.isShowAll;
        this.chart.update();
      }
    },

    calcIsAll() {
      let counterHidden = 0;

      for (let i = 0; i < this.data.datasets.length; i++) {
        if(this.chart.getDatasetMeta(i).hidden) {
          counterHidden++;
        }
      }

      if(this.data.datasets.length > counterHidden) {
        this.isShowAll = true;
      } else {
        this.isShowAll = false;
      }
    },

    destroyCanvas() {
      const canvas = document.getElementById('Chart');
      const lineChart = document.querySelector('#LineChart');

      if (canvas && lineChart) {
        lineChart.removeChild(canvas)
      }
    },

    createCanvas() {
      const canvas = document.createElement('canvas');
      const lineChart = document.querySelector('#LineChart');

      if (lineChart) {
        canvas.id = 'Chart';
        lineChart.appendChild(canvas);
      }
    },

    hexToRgbA(hex, isBorder) {
      let c;
      if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split('');
        if (c.length == 3) {
          c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = '0x' + c.join('');
        if (isBorder) {
          return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ', 1)';
        }
        return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ', .2)';
      }
      throw new Error('Bad Hex');
    },

    getPosition(item = null, i = 0) {
      if (item && item.hasOwnProperty('isMoney')) {
        if (item.isMoney) return 'left';
        else return 'right';
      }

      if (i % 2 === 0) return 'left';
      else return 'right'
    },

    draw() {
      this.destroyCanvas();
      this.createCanvas();

      if(!Array.isArray(this.data.datasets)) return false;

      this.counterY = 0;
      this.isInit = true;

      const scalesOptions = {scales: {yAxes: []}};
      for (let i = 0; i < this.data.datasets.length; i++) {
        const item = this.data.datasets[i];

        scalesOptions.scales.yAxes.push({
          id: i.toString(),
          type: 'linear',
          display: true,
          stacked: true,
          position: this.getPosition(item, i),
          ticks: {
            min: 0,
            beginAtZero: true,
            stepSize: 1,
            callback: function(value) {
              if (Number.isInteger(value)) {
                return value;
              }
            },
            color: item.backgroundColor || '#999'
          },
          min: 0,
          beginAtZero: true,
          yAxis: {
            stepSize: 1,
            callback: function(value) {
              if (Number.isInteger(value)) {
                return value;
              }
            },
          }
        })
      }

      const ctx = document.getElementById('Chart').getContext('2d');
      const listData = [];

      for (let i = 0; i < this.data.datasets.length; i++) {
        let color = 'rgba(255, 99, 132, 0.2)';
        let borderColor = 'rgba(255, 99, 132, 1)';
        const item = this.data.datasets[i];

        if (item.backgroundColor) {
          color = this.hexToRgbA(item.backgroundColor);
          borderColor = this.hexToRgbA(item.backgroundColor, true);
        }

        listData.push({
          ...item,
          yAxisID: i.toString(),
          min: 0,
          beginAtZero: true,
          suggestedMin: 0,
          backgroundColor: color,
          borderColor: borderColor,
          borderWidth: 2,
          fill: true,
          hidden: false,
          lineTension: 0.3,
          scaleStartValue : 0,
          isHiddenDefault: item.isHiddenDefault || false
        })
      }

      this.chart = new Chart(ctx, {
        type: "line",
        data: {
          labels: this.data.labels,
          datasets: listData,
          lineTension: 0
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          bezierCurve: false,
          elements: {
            line: {
              tension: 0
            }
          },
          ticks: {
            maxTicksLimit: 10
          },
          plugins: {
            legend: {
              generateLabels: () => {
                if(this.isInit) {
                  for(let i = 0; i < this.chart.data.datasets.length; i++) {
                    const item = this.chart.data.datasets[i];

                    this.chart.data.datasets[i].hidden = !item.isHiddenDefault;
                    this.chart.config.options.scales[i].display = item.isHiddenDefault;
                    this.chart.getDatasetMeta(i).hidden = !item.isHiddenDefault;
                    this.chart.config.options.scales[i].display = item.isHiddenDefault;
                  }

                  this.isInit = false;
                  this.calcIsAll();
                  this.chart.update();
                }
              },

              onClick: (_, item, legend) => {
                const value = !item.hidden;

                this.chart.data.datasets[item.datasetIndex].hidden = value;
                this.chart.config.options.scales[item.datasetIndex].display = !value;
                this.chart.getDatasetMeta(item.datasetIndex).hidden = value;
                this.chart.config.options.scales[item.datasetIndex].display = !value;

                legend.chart.update();
                this.chart.update();

                this.calcIsAll();
              }
            },
          },
          scales: {
            ...scalesOptions.scales.yAxes || [],

            x: {
              grid: {
                display: false
              },
              ticks: {
                autoSkip: false,
                maxRotation: 90, // Максимальный угол поворота
                minRotation: 45, // Минимальный угол поворота
              },
            },
            y: {
              grid: {
                display: false
              },

              suggestedMin: 1,
              suggestedMax: 100
            },

            xAxes: [{
              ticks: {
                autoSkip: false
              }
            }]
          }
        }
      });
    }
  }
};
</script>

<style lang="scss">
.line_chart {
  position: relative;
  height: 350px;

  & #Chart {
    width: 100% !important;
  }

  &__toggle_view {
    position: relative;
    background: none;
    padding-left: 5.5rem;
    border: none;
    color: #666;
    font-size: 12px;

    &:after {
      content: "";
      position: absolute;
      top: 3px;
      left: 2.5rem;
      width: 40px;
      height: 15px;
      background: #ccc;
      display: block;
    }
  }
}
</style>
