<template>
  <div id="app" class="h-100 app">
    <component :is="layout" />

    <notifications/>
  </div>
</template>

<script>
import DefaultLayout from '@/layouts/DefaultLayout'
import AuthLayout from '@/layouts/AuthLayout'
import LayoutBeforeReg from "@/layouts/LayoutBeforeReg.vue";
import LayoutTariffs from "@/layouts/LayoutTariffs.vue";

export default {
  name: 'App',

  components: {
    DefaultLayout,
    AuthLayout,
    LayoutBeforeReg,
    LayoutTariffs
  },

  computed: {
    layout () {
      return this.$route.meta.layout ?? 'DefaultLayout'
    }
  },
};
</script>

<style lang="scss">
@import "assets/app";
</style>
