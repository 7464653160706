import axios from "axios";
import moment from "moment";

const state = {
    statisticAdvertising: [
        {
            products: [
                {
                    nm_id: 12345,
                    nm_name: "product1",
                    nm_poster: "poster1",
                    stats: [
                        {
                            date: "2023-08-01",
                            week: "Week 1",
                            consumption: 1000,
                            cpm_rub: 500,
                            views: 2000,
                            frq: 1.5,
                            clicks: 100,
                            ctr_percent: "10%",
                            cpc: "5",
                            orders_report_3: 50,
                            orders_report_3_rub: 2000,
                            cost_cart_rub: 3000,
                            cost_order_rub: 4000,
                            added_cart_pcs: 30,
                            placing_order_pcs: 20,
                            placing_order_rub: 2500
                        },
                        {
                            date: "2023-08-01",
                            week: "Week 1",
                            consumption: 1000,
                            cpm_rub: 500,
                            views: 2000,
                            frq: 1.5,
                            clicks: 100,
                            ctr_percent: "10%",
                            cpc: "5",
                            orders_report_3: 50,
                            orders_report_3_rub: 2000,
                            cost_cart_rub: 3000,
                            cost_order_rub: 4000,
                            added_cart_pcs: 30,
                            placing_order_pcs: 20,
                            placing_order_rub: 2500
                        },
                        {
                            date: "2023-08-01",
                            week: "Week 1",
                            consumption: 1000,
                            cpm_rub: 500,
                            views: 2000,
                            frq: 1.5,
                            clicks: 100,
                            ctr_percent: "10%",
                            cpc: "5",
                            orders_report_3: 50,
                            orders_report_3_rub: 2000,
                            cost_cart_rub: 3000,
                            cost_order_rub: 4000,
                            added_cart_pcs: 30,
                            placing_order_pcs: 20,
                            placing_order_rub: 2500
                        },
                        {
                            date: "2023-08-02",
                            week: "Week 1",
                            consumption: 1100,
                            cpm_rub: 520,
                            views: 2100,
                            frq: 1.6,
                            clicks: 110,
                            ctr_percent: "11%",
                            cpc: "5.1",
                            orders_report_3: 55,
                            orders_report_3_rub: 2100,
                            cost_cart_rub: 3100,
                            cost_order_rub: 4100,
                            added_cart_pcs: 35,
                            placing_order_pcs: 25,
                            placing_order_rub: 2600
                        },
                        {
                            date: "2023-08-03",
                            week: "Week 1",
                            consumption: 1200,
                            cpm_rub: 530,
                            views: 2200,
                            frq: 1.7,
                            clicks: 120,
                            ctr_percent: "12%",
                            cpc: "5.2",
                            orders_report_3: 60,
                            orders_report_3_rub: 2200,
                            cost_cart_rub: 3200,
                            cost_order_rub: 4200,
                            added_cart_pcs: 40,
                            placing_order_pcs: 30,
                            placing_order_rub: 2700
                        },
                        {
                            date: "2023-08-04",
                            week: "Week 1",
                            consumption: 1300,
                            cpm_rub: 540,
                            views: 2300,
                            frq: 1.8,
                            clicks: 130,
                            ctr_percent: "13%",
                            cpc: "5.3",
                            orders_report_3: 65,
                            orders_report_3_rub: 2300,
                            cost_cart_rub: 3300,
                            cost_order_rub: 4300,
                            added_cart_pcs: 45,
                            placing_order_pcs: 35,
                            placing_order_rub: 2800
                        },
                        {
                            date: "2023-08-05",
                            week: "Week 1",
                            consumption: 1400,
                            cpm_rub: 550,
                            views: 2400,
                            frq: 1.9,
                            clicks: 140,
                            ctr_percent: "14%",
                            cpc: "5.4",
                            orders_report_3: 70,
                            orders_report_3_rub: 2400,
                            cost_cart_rub: 3400,
                            cost_order_rub: 4400,
                            added_cart_pcs: 50,
                            placing_order_pcs: 40,
                            placing_order_rub: 2900
                        }
                        // ... Add one more entry here for a total of 5 additional stats entries
                    ]
                }
            ],
            total: {
                total_consumption: 1500,
                total_views: 3000,
                total_clicks: 150,
                bid: "High",
                slot: "Slot A",
                total_cpm: 800,
                daily_limit: 5000,
                percent_daily_limit: "50%",
                total_added_cart_pcs: 80,
                total_orders_report_3_pcs: 100,
                total_orders_report_3_rub: 5000,
                total_redeemed_rub: 3000,
                total_orders_report_3_redeemed_pcs: 70,
                total_orders_report_3_redeemed_rub: 2500,
                total_cpl_cart_rub: 3500,
                total_cpo_order_rub: 4000,
                total_cps_rub: 4500,
                total_drr: "25%"
            }
        }
    ],
    dynamic_chart: {
        labels: [],
        datasets: [],
    },
    total_drr: "",
    from: moment().subtract(7, "days").format("YYYY-MM-DD"),
    to: moment().format("YYYY-MM-DD"),
};

const getters = {
    statisticAdvertising: (state) => state.statisticAdvertising,
};

const mutations = {
    SET_FROM(state, from) {
        state.from = from;
    },
    SET_TO(state, to) {
        state.to = to;
    },
    SET_STATIC_ADVERTISMENT(state, payload) {
        // state.statisticAdvertising = payload;
        // state.productStatistic.total_data =
        // state.productStatistic.total_data.reverse();
    },
    SET_DATE(state, payload) {
        state.to = payload.to;
        state.from = payload.from;
    },
    SET_TOTAL_DRR(state) {
        state.total_drr = state.statisticAdvertising.total_data.reduce(
            (accumulator, currentVal) => accumulator + currentVal.drr,
            0
        );
    },
    SET_TOTAL_DATA(state, payload) {
        state.statisticAdvertising.total_data = payload;
    },
    SET_CHART(state) {
        const values = [
            { label: "Просмотры (сумма)", key: "total_views" },
            { label: "Клики (сумма)", key: "total_clicks" },
            { label: "CPM фактический по артикулу", key: "total_cpm" },
            { label: "Добавление в корзину (сумма)", key: "total_added_cart_pcs" },
            { label: "Заказы по отчету 2 (сумма)", key: "orders_report_3" },
            { label: "Из этих заказов выкуплено, шт", key: "redeemed_pcs_report_3" },
            {
                label: "Продажи по отчету 2, шт (сумма)",
                key: "redeemed_pcs_report_3",
            },
        ];

        if (state.productStatistic && state.productStatistic.total_data) {
            state.dynamic_chart.labels = state.productStatistic.total_data.map(
                (ell) => ell.date
            );
            state.dynamic_chart.datasets = values.map((item) => {
                return {
                    label: item.label,
                    data: state.productStatistic.total_data.map((obj) => obj[item.key]),
                    backgroundColor: getRandomColor(),
                };
            });
        }
    },
};

const actions = {
    updateDateRangeFromQueryParams({ commit }, queryParams) {
        const { from, to } = queryParams;
        // If 'from' and 'to' query parameters are present, use them
        if (from && to) {
            commit("SET_FROM", from);
            commit("SET_TO", to);
        } else {
            // Use default values if query parameters are not present
            commit("SET_FROM", moment().subtract(7, "days").format("YYYY-MM-DD"));
            commit("SET_TO", moment().format("YYYY-MM-DD"));
        }
    },
    getStatisticAdvertising({ commit }, payload) {
        try {
            return  axios
                .get("/new-reports/dyn_art/", {
                    params: { ...payload,nm_id:1 },
                })
                .then((response) => {
                    commit("SET_STATIC_ADVERTISMENT", response.data.results);
                    return response.data.results;
                });
        } catch (error) {
            console.error(error);
            commit("SET_STATIC_ADVERTISMENT", {});
        }
    },

    postDirectCompetitor({}, payload) {
        axios.post("/reports/dyn_art/add/direct_competitors/", {
            competitor_nm_id: payload.competitor_nm_id,
            nm_id: payload.article,
        });
    },
    postKeyword({}, payload) {
        axios.post("/reports/dyn_art/add/keyword/", {
            nm_id: payload.nm_id,
            name: payload.keyword,
        });
    },
    async deleteKeyword({}, payload) {
        await axios.delete("/reports/dyn_art/keyword/" + payload.id);
    },
};

// Helper functions

function getRandomColor() {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
}

function getDateRange(startDate, endDate) {
    const dateRange = [];
    const currentDate = new Date(startDate);

    while (currentDate <= endDate) {
        dateRange.push(currentDate.toISOString().split("T")[0]);
        currentDate.setDate(currentDate.getDate() + 1);
    }

    return dateRange;
}

function getWeekDay(dateString) {
    const daysOfWeek = ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"];
    const date = new Date(dateString);
    const dayOfWeek = date.getDay();
    return daysOfWeek[dayOfWeek];
}

export default {
    state,
    getters,
    mutations,
    actions,
};
