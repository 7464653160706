import axios from "axios";

const state = () => ({
    instance_id: null,
    supliers: [],
});
const getters = {
    supliers(state) {
        return state.supliers;
    },
    rows_length(state) {
        return state.supliers.length
    },
};
const mutations = {
    SET_SUPLIERS(state, payload) {
        state.supliers = payload;
        if (state.supliers[0]) {
            localStorage.setItem("organization", JSON.stringify(state.supliers[0]));
        } else {
            localStorage.setItem("organization", {fullname: 'Выберите организатора', id: 1});
        }
    },
    updateActiveStatus(state, updatedUser) {
        console.log(updatedUser);
        const userIndex = state.supliers.findIndex(
            (user) => user.id === updatedUser.id
        );
        if (userIndex !== -1) {
            state.supliers[userIndex].is_active = updatedUser.is_active;
        }
    },
};
const actions = {
    sendUser({commit, state}, payload) {
        return axios
            .post("/wildberries/auth/step1/request_sms_code/", payload)
            .then((res) => {
                state.instance_id = res.data.instance_id;
                return res.data;
            })
            .catch((e) => {
                return e.response;
            });
    },
    sendUserByToken({commit, state}, payload) {
        return axios
            .post("/wb-profile/accounts/", payload)
            .then((res) => {
                state.instance_id = res.data.instance_id;
                return res.data;
            })
            .catch((e) => {
                return e.response;
            });
    },
    checkCaptcha({commit, state}, payload) {
        return axios
            .post("/wildberries/auth/step2/captcha/", {
                ...payload,
                instance_id: state.instance_id,
            })
            .then((res) => {
                return res.data.message;
            })
            .catch((e) => {
                return e.response;
            });
    },
    checkSmsCode({commit}, payload) {
        return axios
            .post("/wildberries/auth/step3/confirm_sms_code/", payload)
            .then((res) => {
                return res;
            })
            .catch((e) => {
                return e.response;
            });
    },
    getSupliers({state, commit}, payload) {
        axios.get("/wildberries/auth/suppliers/fetch_suppliers",).then((res) => {
            console.log(res);
            commit("SET_SUPLIERS", res.data);
        });
    },
    postActiveSupliers({commit}, payload) {
        axios
            .post("/wildberries/auth/suppliers/activate_suppliers/", {
                supplier_ids: payload,
            })
            .then((res) => {
            });
    },
    deleteSuplier({commit}, payload) {
        return new Promise((resolve) => {
            axios({
                url: "/wb-profile/accounts/" + payload.current_id,
                method: 'DELETE',
                headers: {
                    Authorization: `Bearer ${payload.accessToken}`,
                }
            })
                .then((res) => {
                    resolve(res)
                })
                .catch(() => {
                    resolve(true);
                })
        })
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
