<script>
import {mapGetters} from "vuex";
import moment from "moment";

export default {
  name: 'PaymentHistory',

  computed: {
    ...mapGetters(['currentUserTariffInfo'])
  },

  data() {
    return {
      tableItemKeys: [
        'date',
        'payment_amount',
        'status'
      ],
      items: []
    }
  },

  methods: {
    renderPaymentStatus(status) {
      switch (status) {
        case 'Fail':
          return 'Неудачно'

        case 'Success':
          return 'Оплачено';

        case 'Expired':
          return 'Оплата не прошла';

        default:
          return status;
      }
    },

    renderDate(date) {
      const formattedDate = moment(date).format('DD.MM.YYYY');

      if(formattedDate !== 'Invalid date')
        return moment(date).format('DD.MM.YYYY');

      return '';
    }
  }
}
</script>

<template>
  <div class="payment_history">
    <h6 class="payment_history__title">
      История платежей
    </h6>

    <div class="payment_history__table">
      <table class="table table-hover">
        <thead>
          <th>Дата</th>
          <th>Сумма</th>
          <th>Статус</th>
        </thead>
        <tbody>
        <tr v-for="payment of currentUserTariffInfo.payments"
            :key="payment.id"
        >
          <td v-for="tableKey in tableItemKeys"
              :key="tableKey"
          >
            <div v-if="tableKey === 'status'"
                 class="history-cell"
                 :class="payment.status"
            >
              {{ renderPaymentStatus(payment.status) }}
            </div>

            <div v-if="tableKey === 'date'">
              {{ renderDate(payment.from_date)}}
            </div>

            <div v-if="!['status', 'date'].includes(tableKey)">
              {{ payment[tableKey] }}₽
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<style scoped lang="scss">
$grey: #908F90;

.payment_history {
  &__title {
    margin-bottom: 1rem;
    color: $grey;
    font-size: 16px;
    font-weight: 400;
  }

  &__table {
    & td {
      padding: 12px 15px;
      border: 1px solid #F1F1F1;
      border-top: none;
      color: $grey;
      font-size: 16px;
    }
  }
}

.history-cell {
  &.Success {
    color: #459A48;
  }

  &.Expired,
  &.Fail {
    color: red;
  }
}
</style>
