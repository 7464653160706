<template>
  <div style="overflow-x: scroll">
    <table bordered class="mb-1 statisticAdvertising-table_total_data table mb-1">
      <thead>
      <tr>
        <th style="width: 160px;background: #fcf8ff!important;" class="sticky-col">Дата</th>
          <th v-for="(col, i) in data.products[0].stats" :key="i" class="text-muted" style="background: #fcf8ff!important;">
            {{ formatDate(col.date) }}
          </th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <th class="sticky-col right-box-shadow" style="border: 1px solid transparent;">
          <p class="statisticAdvertising-table_total_data__advert-name">Итог</p>
        </th>
      </tr>
      <tr v-for="(item, index) in values" :key="index">
        <td class="sticky-col">
            <span class="statisticAdvertising-table_total_data__item-label">
              {{ item.label }}
            </span>
        </td>
        <td v-for="(stat, i) in data.products[0].stats" :key="i">
            <span class="statisticAdvertising-table_total_data__item">
              {{ stat[item.key] }}
            </span>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "totalTable",
  props: {
    data: {},
  },
  data() {
    return {
      values: [
        { label: "Общий расход по всем РК", key: "total_consumption" },
        { label: "Просмотры общ", key: "total_views" },
        { label: "Клики общ", key: "total_clicks" },
        { label: "CPM фактический по артикулу", key: "total_cpm" },
        { label: "Добавления в корзину", key: "total_added_cart_pcs" },
        { label: "Заказано артикула по отчету 3", key: "orders_report_3" },
        { label: "Заказано по отчету 3 на сумму, руб", key: "orders_report_3_rub" },
        { label: "Из этих заказов выкуплено, шт", key: "total_orders_report_3_redeemed_pcs" },
        { label: "Выкуплено на сумму, руб", key: "total_redeemed_rub" },
        { label: "Продажи по отчету 3, шт (сумма)", key: "redeemed_pcs_report_3" },
        { label: "CPL Стоимость корзины, руб (средн)", key: "total_cpl_cart_rub" },
        { label: "CPO Стоимость заказа, руб (средн)", key: "total_cpo_order_rub" },
        { label: "CPS Стоимость продажи, руб (средн)", key: "total_cps_rub" },
        { label: "ДРР от суммы заказов, %", key: "total_drr" },
      ],
    };
  },
  methods:{
    formatDate(dateString){
      const daysOfWeek = ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"];
      const date = new Date(dateString);
      const dayOfWeek = daysOfWeek[date.getDay()];

      return `${dateString} ${dayOfWeek}`;
    },
  },
};
</script>

<style>
@import "../index.scss";
</style>
