<template>
  <div class="auth-card">
    <div class="title mb-4 align-content-between d-flex">
      Регистрация

      <div class="ml-auto d-flex justify-content-center align-items-center">
        <div class="stage">
          <span>{{ stage }}</span>/2
        </div>
      </div>
    </div>

    <validation-observer ref="observer">
        <b-form v-if="stage === 1">
          <validation-provider
              name="E-mail"
              :rules="{ required: true, email: true }"
              v-slot="validationContext"
          >
            <b-form-group class="mb-3">
              <b-form-input v-model="email" placeholder="E-mail" :state="getValidationState(validationContext)"/>

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

            <validation-provider
                    name="Пароль"
                    :rules="{ required: true, min: 8 }"
                    v-slot="validationContext"
                    vid="password"
            >
                <password-field v-model="password" class="mb-3 input_pass" :state="getValidationState(validationContext)" :errors="validationContext.errors"/>
            </validation-provider>

            <validation-provider
                    name="Пароль"
                    rules="required|min:8|confirmed:password"
                    v-slot="validationContext"
            >
                <password-field v-model="confirmPassword" placeholder="Повторите пароль" class="mb-3 input_pass" :state="getValidationState(validationContext)" :errors="validationContext.errors"/>
            </validation-provider>

          <div class="register_view_offer">
            <b-form-checkbox
                id="offer-1"
                class="modal-checkbox"
                v-model="isAcceptOffer"
            >
              Я подтверждаю, что ознакомлен и согласен с условиями <router-link to="/payment-offer">Оферты регулярных платежей</router-link> и <router-link to="/confidential">Политикой конфиденциальности</router-link>
            </b-form-checkbox>
          </div>

            <b-btn @click="next" pill class="mt-4 w-100 py-2" variant="wblead">Продолжить</b-btn>
        </b-form>
        <b-form v-if="stage === 2">
            <validation-provider
                    name="Имя"
                    :rules="{ required: true, min: 2 }"
                    v-slot="validationContext"
            >
                <b-form-group class="mb-3">
                    <b-form-input v-model="firstName" placeholder="Имя" :state="getValidationState(validationContext)"/>

                    <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                </b-form-group>
            </validation-provider>

            <validation-provider
                    name="Фамилия"
                    :rules="{ required: true, min: 2 }"
                    v-slot="validationContext"
            >
                <b-form-group class="mb-3">
                    <b-form-input v-model="lastName" placeholder="Фамилия" :state="getValidationState(validationContext)"/>

                    <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                </b-form-group>
            </validation-provider>

          <validation-provider
              name="Номер телефона"
              :rules="{ required: true, min: 11 }"
              v-slot="validationContext"
          >
            <b-form-group class="mb-3">
              <b-form-input v-model="phone" placeholder="Номер телефона" :state="getValidationState(validationContext)"/>

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

            <div class="register_view_offer">
              <b-form-checkbox
                  id="offer-1"
                  class="modal-checkbox"
                  v-model="isAcceptOffer"
              >
                Я подтверждаю, что ознакомлен и согласен с условиями <router-link to="/payment-offer">Оферты регулярных платежей</router-link> и <router-link to="/confidential">Политикой конфиденциальности</router-link>
              </b-form-checkbox>
            </div>

            <b-btn :disabled="processing || !isAcceptOffer"
                   @click="register"
                   pill class="mt-4 w-100 py-2" variant="wblead">Зарегистрироваться</b-btn>
        </b-form>

    </validation-observer>

    <div v-if="requestError" class="request-error">
      {{ requestError }}
    </div>

    <div class="no-account mt-5 text-center">
      Уже есть аккаунт? <a @click.prevent="$router.push({ name: 'auth.login' })" href="/auth/login">Войти</a>
    </div>
  </div>
</template>

<script>
import PasswordField from '@/components/ui/PasswordField.vue'
import axios from 'axios'

export default {
  name: 'LoginView',
  components: { PasswordField },

  data() {
    return {
      stage: 1,
      processing: false,
      email: null,
      phone: null,
      firstName: null,
      lastName: null,
      password: null,
      confirmPassword: null,
      requestError: null,
      isAcceptOffer: false
    }
  },

  methods: {
    async register() {
      try {
        this.processing = true
        this.requestError = null;

        if (await this.$refs.observer.validate()) {
          await axios.post(process.env.VUE_APP_API_URL + '/auth/signup/', {
            "first_name": this.firstName,
            "last_name": this.lastName,
            "phone": this.phone,
            "email": this.email,
            "password": this.password,
            "password_repeat": this.confirmPassword
          }).then(() => {
            this.$router.push({
              name: 'auth.otp',
              query: {
                email: this.email
              }
            });

          }).catch((err) => {
            console.error(err);

            if(
                err &&
                err.response &&
                err.response.data &&
                Array.isArray(err.response.data.non_field_errors)
            ) {
              this.requestError = err.response.data.non_field_errors[0];
            }
          })
        }
      } catch (e) {
        console.error(e);

      } finally {
        this.processing = false;
      }
    },

    isValidPass() {
      if (/[a-zа-яё]/i.test(this.password)){
        return true;
      } else {
        return false;
      }
    },

    async next () {
      try {
        this.requestError = null;

        if (await this.$refs.observer.validate()) {
          if(this.stage === 1) {
            if(!this.isValidPass()) {
              this.requestError = 'Пароль не может содержать только цифры';
              return false;
            }

            const status = await this.checkUniquenessEmail();
            if(!status) {
              this.requestError = 'Такой мейл уже зарегестрирован';
              return false;
            }

            this.$refs.observer.reset()

            if(status) {
              this.stage = 2
            } else {
              this.$router.push({
                name: 'auth.otp',
                query: {
                  email: this.email
                }
              });
            }
          }
        }

      } catch(err) {
        console.error(err);
      }
    },

    checkUniquenessEmail() {
      return new Promise((resolve, reject) => {
        axios({
          url: process.env.VUE_APP_API_URL + '/auth/check-email/ ',
          method: 'POST',
          data: {
            email: this.email
          }
        }).then((response) => {
          resolve(response.data.is_valid)

        }).catch((err) => {
          reject(err);
        })
      })
    }
  }
}
</script>

<style lang="scss">
  .stage {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 113.5%;
    text-align: center;
    color: #999999;

    span {
      font-family: 'Raleway';
      font-style: normal;
      font-weight: 500;
      font-size: 28px;
      line-height: 93%;
      color: #481173;
    }
  }

  .request-error {
    margin: 1rem 0;
    color: red;
    font-size: 16px;
    text-align: center;
  }

  .register_view_offer {
    & .custom-control-label {
      &:before {
        top: 9px;
      }

      &:after {
        top: 9px;
      }
    }
  }
</style>
