<template>
  <div class="filters">
    <div class="third-report-card p-3">
      <div
        style="flex-basis: 30%; flex-grow: 1"
        class="third-report-card__info"
        v-if="!hideOptions.includes('image')"
      >
        <div class="mr-2">
          <img height="70" src="/img/aesthetic purple outfits.png" />
        </div>
        <div class="third-report-card__info-data ml-2">
          <div class="third-report-card__title mt-1 mb-1">
            <a href="#"> {{ article }} </a>
          </div>
          <div class="text-muted text-sm third-report-card__description">
            {{ name }}
          </div>
        </div>
      </div>

      <div
        style="flex-basis: 60%; flex-grow: 1"
        class="d-flex"
        v-if="!hideOptions.includes('range')"
      >
        <div class="third-report-card__date-filter mr-1">
          <div class="d-flex period-filter me-3">
            <label class="d-flex align-items-center me-3 mb-0"> Период</label>
            <b-input-group style="width: 160px">
              <b-input-group-prepend
                class="third-report-card_sort-and-filter__input-prepend"
              >
                <b-form-datepicker
                  v-model="filters.from"
                  start-weekday="1"
                  button-only
                >
                  <template #button-content>
                    <img height="20" src="/img/datepicker-icon.svg" />
                  </template>
                </b-form-datepicker>
              </b-input-group-prepend>
              <b-form-input
                class="third-report-card_sort-and-filter__input"
                type="text"
                placeholder="17/02/2023"
                v-model="formattedFrom"
                disabled
                style="height: 45px"
              ></b-form-input>
            </b-input-group>
          </div>

          <div class="d-flex period-filter">
            <label class="d-flex align-items-center me-3 mb-0"> по </label>
            <b-input-group style="width: 160px">
              <b-input-group-prepend
                class="third-report-card_sort-and-filter__input-prepend"
              >
                <b-form-datepicker
                  v-model="filters.to"
                  start-weekday="1"
                  button-only
                >
                  <template #button-content>
                    <img height="20" src="/img/datepicker-icon.svg" />
                  </template>
                </b-form-datepicker>
              </b-input-group-prepend>
              <b-form-input
                type="text"
                class="third-report-card_sort-and-filter__input"
                placeholder="17/02/2023"
                v-model="formattedTo"
                disabled
                style="height: 45px"
              ></b-form-input>
            </b-input-group>
          </div>
        </div>

        <div
          class="third-report-card__date-filter ml-1"
          v-if="!hideOptions.includes('range_btns')"
        >
          <b-button-group class="wb-button-group">
            <b-button
              style="font-size: 13px"
              :pressed="checkDate(7)"
              @click="setDate(7)"
            >
              7 дней
            </b-button>
            <b-button
              style="font-size: 13px"
              :pressed="checkDate(14)"
              @click="setDate(14)"
            >
              14 дней
            </b-button>
            <b-button
              style="font-size: 13px"
              :pressed="checkDate(30)"
              @click="setDate(30)"
            >
              30 дней
            </b-button>
            <b-button
              style="font-size: 13px"
              :pressed="checkDate(90)"
              @click="setDate(90)"
            >
              90 дней
            </b-button>
          </b-button-group>
        </div>

        <div
          class="flex justify-content-end text-right filters__wrap_export"
          v-if="!hideOptions.includes('export')"
        >
          <b-btn @click="downloadReport" class="wb-btn wb-btn-reverse export">
            <div class="export__container">
              <img src="/img/export-icon-2.svg" />
              <span>Export</span>
            </div>
          </b-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapActions } from 'vuex';
import { includes } from 'core-js/internals/array-includes';

export default {
  name: 'filterAndsort',
  props: {
    article: {
      type: Number | String,
      default: '',
      required: false,
    },
    name: {
      type: String,
      default: '',
    },
    hideOptions: {
      type: Array,
      required: false,
      default: () => [],
    },
    onChangeFilter: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      tableHeight: 0,
      sortKey: null,
      isWatchCompetitor: false,
      newKeyword: '',
      newDirectCompetitor: '',
      filters: {
        from: moment().subtract(8, 'days').format('YYYY-MM-DD'),
        to: moment().subtract(1, 'days').format('YYYY-MM-DD'),
      },
    };
  },
  computed: {
    formattedTo() {
      return moment(this.filters.to).format('DD/MM/YYYY');
    },
    formattedFrom() {
      return moment(this.filters.from).format('DD/MM/YYYY');
    },
  },
  watch: {
    filters: {
      handler() {
        const formattedTo = moment(this.filters.to).format('YYYY-MM-DD');
        const formattedFrom = moment(this.filters.from).format('YYYY-MM-DD');

        this.onChangeFilter({
          start: formattedFrom,
          end: formattedTo,
        });
        console.log('handler');
      },
      deep: true,
    },
  },
  methods: {
    includes,
    ...mapActions(['uploadReport']),
    downloadReport() {
      this.uploadReport('dyn_art');
    },
    setFilterByQuery() {
      const { from, to } = Object.assign({}, this.$route.query);
      this.filters.from = moment().subtract(7, 'days').format('YYYY-MM-DD');
      this.filters.to = moment().format('YYYY-MM-DD');

      this.filters.from = moment(this.filters.from)
        .subtract(1, 'days')
        .format('YYYY-MM-DD');
      this.filters.to = moment(this.filters.to)
        .subtract(1, 'days')
        .format('YYYY-MM-DD');
    },
    setQuery() {
      this.$router.replace({
        name: 'productsStatistic',
        query: {
          from: this.filters.from || '',
          to: this.filters.to || '',
        },
      });
    },
    checkDate(interval) {
      let days = interval + 1;

      const now = moment().subtract(1, 'days');
      const pre = moment().subtract(days, 'days');

      return (
        now.isSame(moment(this.filters.to), 'day') &&
        pre.isSame(moment(this.filters.from), 'day')
      );
    },

    setDate(interval) {
      const now = moment();
      const pre = moment().subtract(interval, 'days');

      this.filters.from = pre.subtract(1, 'days').format('YYYY-MM-DD');
      this.filters.to = now.subtract(1, 'days').format('YYYY-MM-DD');
    },
    emitDateFilterHandler(data) {
      this.$emit('dateFilterHandler', data);
    },
  },
  mounted() {
    this.setFilterByQuery();
  },
};
</script>

<style lang="scss" scoped>
.add-input {
  height: 38px;
  border-left: none !important;
  border-right: 1px solid #ece6f0 !important;
  border-radius: 0 !important;
  border-top: none !important;
  border-bottom: none !important;
}
.sticky-header {
  position: sticky;
  top: 0;
  z-index: 2221; /* Чтобы заголовок оставался наверху других элементов */
  background-color: #fcf8ff !important;
}
table thead {
  position: sticky;
  top: -1px;
  background-color: white;
  box-shadow: none;
  z-index: 2;
}
table tbody td.sticky-col {
  position: sticky;
  left: -1px;
  z-index: 1;
  background-color: white;
}
.product-statistic-table thead th.sticky-col {
  position: sticky;
  left: 0;
  background-color: #fcf8ff;
  z-index: 1;
}
.table-container {
  overflow: auto;
  position: relative;
}

.product-statistic-table thead:first-child {
  position: sticky;
  top: 0;
  background: #fcf8ff;
  z-index: 1;
}
table thead {
  position: sticky;
  top: -1px;
  background-color: white;
  z-index: 2;
}
thead th {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 2;
}
/* Задаем стили для первой колонки */
table tbody td.sticky-col {
  position: sticky;
  left: -1px;
  z-index: 1;
  background-color: white;
}

.table-scroll {
  display: grid;
  grid-template-columns: auto repeat(auto-fit, minmax(100px, 1fr));
  max-width: 100%;
  overflow-x: scroll;
  display: none;
}

.third-report-card__date-filter {
  margin-left: 0;
}

.filters {
  margin-bottom: 0;
  border: 1px solid #f1f1f1;
  border-bottom: none;

  & .third-report-card {
    margin-bottom: 0;
    height: auto;
    padding: 19px 24px !important;

    & > div {
      width: 100%;
      display: grid !important;
      grid-template-columns: auto auto auto;
    }
  }
}

.productStatistic {
  &__header {
    height: 44px;
    border-top: 1px solid #f1f1f1;
    border-bottom: 1px solid #f1f1f1;
    display: flex;
    align-items: center;
    background: #fff;

    & > * {
      padding: 0 24px;
      text-align: center;
    }
  }

  & .product-statistic-table_total_data {
    margin-top: 0 !important;
  }

  & .th__limit {
    color: #dc5247;
    font-size: 16px;
    font-family: Raleway;
  }
}

.product-statistic-table {
  border: 1px solid #f1f1f1;

  & th,
  td {
    padding: 12px !important;
    vertical-align: middle;
    background: #fff !important;
  }

  & th {
    min-width: 14ch;
  }

  & .tr_date th {
    color: #908f90;
    font-size: 12px;
    font-weight: 500;
    background: #fcf8ff !important;

    &:not(:first-child) {
      border-right: none !important;
      box-shadow: none !important;
    }
  }

  & .limit th {
    box-shadow: none !important;
  }

  & .product_info {
    &__name {
      color: #481173;
      font-size: 12px;
      font-weight: 600;
    }
  }

  & .total-title {
    & td {
      border-left: none;
      border-right: none !important;
      box-shadow: none !important;
    }
  }
}
</style>
