import axios from "axios";
import productsStatistic from "./productsStatistic";
import companiesAdvertising from "./companiesAdvertising";
import productsAdvertising from "./productsAdvertising";

const state = () => ({
  current_tab: "comm_art",
});
const getters = {
  current_tab(state) {
    return state.current_tab;
  },
};
const mutations = {
  setCurrentTab(state, payload) {
    state.current_tab = payload;
  },
};
const actions = {
  setDataToUpload({ commit, state }, payload) {
    if (payload === 'comm_art') {
      return productsAdvertising.state.productsAdvertising;
    } else if (payload === 'comm_rk') {
      return companiesAdvertising.state.companiesAdvertising;
    } else {
      return productsStatistic.state.productStatistic;
    }
  },
  async uploadReport({state, dispatch}, payload = {}) {
    await axios({
      url: `/new-reports/${payload.type}/export/`,
      method: 'POST',
      data: {
          ...payload.filters
      },
        responseType: 'arraybuffer',
    })
      .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'report.xlsx');
          document.body.appendChild(link);
          link.click();
      });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
