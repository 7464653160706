export const getFixedValue = (value) => {
    if(value !== null && value !== '') {
        if (!isNaN(Number(value))) {
            if((value % 1) !== 0) {
                return value.toFixed(1);
            }
        }
    }

    return value;
}

export const getFormattedValue = (value) => {
    let formattedValue = value.toString().replace(/0*$/, "");

    if(formattedValue[formattedValue.length - 1] === '.') {
        return formattedValue.slice(0, formattedValue.length - 1);
    }

    return formattedValue;
}
