<script setup>
import LandingFooterTelegram from "@/components/landing/LandingFooterTelegram.vue";

const userInfoText = [
    'Индивидуальный предприниматель Соколова Евгения Геннадьевна',
    'ИНН: 711614562312',
    'ОГРНИП: 315774600180066',
    'Адрес: г. Москва.'
]
</script>

<template>
  <div class="landing_footer_left">
    <a class="landing_footer_left__logo"
       href="/landing"
    >
      <img src="/img/landing-logo-footer.svg"
           alt=""
      />
      <span class="landing_footer_left__logo__text">WBLeads</span>
    </a>

    <p class="landing_footer_left__text">
      Есть вопросы? Нашли баг? Нужна доработка?
    </p>

    <LandingFooterTelegram />

    <div class="landing_footer_left__info_texts">
      <p v-for="text in userInfoText"
         :key="text"
      >{{ text }}</p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.landing_footer_left {
  &__logo {
    margin-bottom: 20px;
    display: flex;
    gap: 14px;
    align-items: center;
    text-decoration: none;

    &__text {
      font-size: 24px;
      font-weight: 500;
    }
  }

  &__text {
    margin-bottom: 1rem;
    font-size: 18px;
  }

  &__info_texts {
    margin-top: 55px;

    & p {
      margin-bottom: 0;
      padding-bottom: 0;
      color: #908F90;
    }
  }
}
</style>
