<script setup>
import {ref, onMounted} from 'vue';
import axios from 'axios';

import ProfileHeader from "@/components/profile/ProfileHeader.vue";
import WBLoader from "@/components/loader/WBLoader.vue";
import CurrentTariff from '@/components/tariffs/CurrentTariff.vue';

const user = ref({});
const isLoading = ref(true);
const currentTariff = ref(null);
const isTrial = ref(false);

const trialOptions = [
  {key: 'org_count', name: 'Организаций:'},
  {key: 'advertisement', name: '5 отчетов'},
  {key: 'dashboard', name: 'Дашборд'},
  {key: 'analytical_data', name: 'Рекламная статистика за всю историю'},
  {key: 'analytical_data2', name: 'Заказы и Продажи за 90 дней'},
];

const onSave = () => {
  isLoading.value = true;
  axios({
    url: '/profile/update_me/',
    method: 'PUT',
    data: {
      first_name: user.value.first_name,
      last_name: user.value.last_name,
      phone: user.value.phone
    }
  }).then(() => {
    getData();

  }).catch((err) => {
    console.error(err);
  })
};

const getData = () => {
  isLoading.value = true;
  axios({
    url: '/profile/get_me/',
    method: 'GET',
  }).then((response) => {
    user.value = response.data;

    if(user.value.current_subscription) {
      if(user.value.current_subscription.tariff) {
        if(user.value.current_subscription.tariff.name === 'Trial') {
          currentTariff.value = {
            name: 'Пробный период',
            date_end: user.value.subscription_payed_until,
            price: null
          };
          isTrial.value = true;

        } else {
          currentTariff.value = {
            name: user.value.current_subscription.tariff.name,
            date_end: user.value.subscription_payed_until,
            price: user.value.current_subscription.tariff.price_rub,
            ...user.value.current_subscription
          };
          isTrial.value = false;
        }
      }
    }

    isLoading.value = false;

  }).catch((err) => {
    console.error(err);
    user.value = {};
    isLoading.value = false;
  })
}

onMounted(() => {
  getData();
})
</script>

<template>
  <div class="profile">
    <WBLoader v-if="isLoading" />

    <div v-if="!isLoading">
      <ProfileHeader
          :user="user"
      />

      <form class="profile__form"
            @submit.prevent="onSave"
      >
        <div class="profile__grid">
          <div>
            <div>
              <b-form-group label="Ваше имя">
                <b-form-input
                    v-model="user.first_name"
                    placeholder="Введите имя"
                ></b-form-input>
              </b-form-group>
              <b-form-group label="Ваша фамилия">
                <b-form-input
                    v-model="user.last_name"
                    placeholder="Введите фамилию"
                ></b-form-input>
              </b-form-group>
              <b-form-group label="Ваш e-mail">
                <b-form-input
                    v-model="user.email"
                    placeholder="Введите email"
                    :disabled="true"
                ></b-form-input>
              </b-form-group>
            </div>

            <div class="profile__control">
              <b-btn class="btn_cancel" @click="getData">
                Отменить изменения
              </b-btn>
              <b-btn class="wb-btn" @click="onSave">
                Сохранить изменения
              </b-btn>
            </div>
          </div>
          <div>
            <b-form-group label="Ваш номер телефона">
              <b-form-input
                  v-model="user.phone"
                  placeholder="Введите номер телефона"
              ></b-form-input>
            </b-form-group>

            <h6 class="profile__offer__title">Подписка</h6>
            <div
                class="profile__offer_wrap without_tariff"
                v-if="!currentTariff"
            >
              <router-link to="/tariffs"
                           class="profile__offer_wrap__try btn wb-btn btn-secondary "
              >

                Тарифы
              </router-link>
            </div>

            <CurrentTariff
                v-if="currentTariff"
                :tariff="currentTariff"
                :options="isTrial ? trialOptions: null"
                :isShowFooterControl="!isTrial"
            />
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<style lang="scss">
.profile {
  margin: 84px 0;
  padding: 28px 28px 32px 28px;
  background: #fff;

  &__grid {
    position: relative;
    display: grid;
    grid-template-columns: auto auto;
    gap: 48px;

    & .form-group {
      margin-bottom: 24px;

      & .col-form-label {
        color: #9E8EA9;
        font-size: 14px;
        font-weight: 500;
      }
    }
  }

  &__control {
    position: absolute;
    bottom: 0;
    width: 50%;
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  &__offer_wrap {
    height: 375px;
    max-width: 375px;
    border-radius: 14px;
    box-shadow: 0px 0px 20px 0px rgba(47, 10, 73, 0.10);
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.75);

    & a {
      text-decoration: none !important;
    }
  }
}

.profile__offer__title {
  margin-bottom: 8px;
  font-size: 14px;
  color: #9E8EA9;
  font-weight: 500;
}
</style>
