import axios from "axios";

const state = () => ({
    users: [],
    currentUser: localStorage.getItem('user') != null ? JSON.parse(localStorage.getItem('user')) : null,
    currentOrganization: localStorage.getItem('organization') != null ? JSON.parse(localStorage.getItem('organization')) : {fullname:'Выберите организатора',id:1},
    isHasSubscribe: true,
    currentUserTariffInfo: {}
});
const getters = {
    users(state){
        return state.users
    },
    currentUser(state){
        return state.currentUser
    },
    currentOrganization(state){
        return state.currentOrganization
    },

    isHasSubscribe(state) {
        return state.isHasSubscribe
    },

    currentUserTariffInfo(state) {
        return state.currentUserTariffInfo;
    }
};
const mutations = {
    SET_USERS(state,payload){
        state.users = payload
    },
    SET_CURRENT_ORGANIZATION(state,payload){
        if(!payload[0]) {
            localStorage.removeItem('organization')
        } else {
            localStorage.setItem('organization',JSON.stringify(payload[0]))
            state.currentOrganization = payload
        }

        location.reload();
    },
    SET_CURRENT_ORGANIZATION_INIT(state, payload) {
        if(!JSON.stringify(payload)) {
            localStorage.removeItem('organization')
        } else {
            localStorage.setItem('organization' ,JSON.stringify(payload))
            state.currentOrganization = payload
        }

    },
    SET_IT_HAS_SUBSCRIBE(state, payload) {
        state.isHasSubscribe = payload;
    },

    SET_CURRENT_USER_TARIFF_INFO(state, payload) {
        state.currentUserTariffInfo = payload;
    }
};
const actions = {
   getUsers({commit},payload = {}){
       return new Promise((resolve) => {
           axios.get(`/wb-profile/accounts/`).then((res)=>{
               const list = res.data.filter((item) => {
                   if(!item.is_deleted) return item;
               })

               commit('SET_USERS', list)

               if(!localStorage.getItem('user')) {
                   if(!state.currentUser && Array.isArray(res.data) && res.data[0]) {
                       commit('SET_IT_HAS_SUBSCRIBE', res.data[0]);
                   }
               }

               if(payload && payload.options && payload.options.isStart) {
                   const localUer = localStorage.getItem('organization')
                   if(localUer) {
                       const organization = JSON.parse(localUer);
                       let isHas = false;
                       for(let item of list) {
                           if(item.id === organization.id) isHas = true;
                       }

                       if(!isHas) {
                           commit('SET_CURRENT_ORGANIZATION_INIT', list[0]);
                       }

                   } else {
                       localStorage.removeItem('organization');
                       if(list[0]) {
                           commit('SET_CURRENT_ORGANIZATION_INIT', list[0]);
                       }
                   }
               }

               if(payload && payload.options && payload.options.isNewUser) {
                   console.log('here');
                   commit('SET_CURRENT_ORGANIZATION_INIT', payload.options.newUser);
               }


               resolve(res.data);
           })
       })
   },

    setUser({commit}, user) {
       console.log('user', user);
        //commit('SET_CURRENT_ORGANIZATION_INIT', user);
    },

    getProfileInfo({commit}, payload) {
        return new Promise((resolve) => {
            axios.get(`/profile/get_me/`).then((res)=>{
                commit('SET_IT_HAS_SUBSCRIBE', res.data.has_active_subscription);
                commit('SET_CURRENT_USER_TARIFF_INFO', res.data);
            })
        })
    }
};

export default {
    state,
    getters,
    mutations,
    actions,
};
