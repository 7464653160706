<script setup>
import LandingUsefulCard from "@/components/landing/LandingUsefulCard.vue";

const cards = [
  {
    id: 1,
    title: 'Владелец рекламного кабинета',
    text: 'Получайте вижимку важнейшей статистики на дашбордах и принимайте решения полагаясь на точность цифр.'
  },
  {
    id: 2,
    title: 'Менеджер маркетплейсов',
    text: 'Упростите и автоматизируйте сбор и подготовку финансовой отчетности для своих заказчиков. Освободите время для работы с новыми клиентами и зарабатывайте больше.'
  }
]
</script>

<template>
  <div class="landing_block_useful">
    <h6 class="landing_block_useful__title text-center">
      <span class="landing_block_useful__title__color">Наш продукт</span> будет полезен, если вы
    </h6>

    <div class="landing_block_useful__cards">
      <LandingUsefulCard
        v-for="card in cards"
        :key="card.id"
        :block="card"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
.landing_block_useful {
  &__title {
    margin-bottom: 40px;
    color: #160027;
    font-size: 32px;
    font-weight: 600;
    line-height: 130%;

    &__color {
      color: #771CBD;
      font-weight: 500;
    }
  }

  &__cards {
    display: flex;
    gap: 32px;
  }
}
</style>
