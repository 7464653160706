<script setup>
import {ref} from 'vue';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';

import LandingOpportunity from "@/components/landing/LandingOpportunity.vue";

const opportunities = [
  {
    id: 1,
    src: '/img/landing-opportunites/1.svg',
    title: 'Отчёт по всем товарам в рекламе',
    text: 'Сводный отчёт по каждому товару в вашем кабинете продавца позволит контролировать показатели всех товаров в рекламе.'
  },
  {
    id: 2,
    src: '/img/landing-opportunites/2.svg',
    title: 'Отчёт по всем рекламным кампаниям',
    text: 'Сводный отчёт по всем рекламным кампаниям позволит принимать правильные решения по распределению бюджета.'
  },
  {
    id: 3,
    src: '/img/landing-opportunites/3.svg',
    title: 'Детальный отчёт по товару',
    text: 'Полный анализ статистики по товару и рекламным кампаниям в которых товар участвовал. Ключевые показатели: CPL, CPO, CPS'
  },
  {
    id: 4,
    src: '/img/landing-opportunites/4.svg',
    title: 'Детальный отчёт по кампании',
    text: 'Полный разбор всей статистики рекламной кампании. Анализ показателей за каждый день позволяет эффективнее использовать бюджет.'
  },
  {
    id: 5,
    src: '/img/landing-opportunites/5.svg',
    title: 'Дашборд',
    text: 'Дашборд на главной странице кабинета позволяет контролировать главные показатели по кампаниям в реальном времени.'
  }
];
const swiperAction = ref(null);
const RefSwiper = ref(null);

const prev = (event) => {
  if(swiperAction.value !== 'prev') {
    RefSwiper.value.$swiper.navigation.onPrevClick(event);
    swiperAction.value = 'prev';
  }
}

const next = (event) => {
  if(swiperAction.value !== 'next') {
    RefSwiper.value.$swiper.navigation.onNextClick(event);
    swiperAction.value = 'next';
  }
}
</script>

<template>
  <div id="Opportunities" class="landing_opportunities">
    <div class="landing_opportunities__header">
      <h5 class="landing_opportunities__title">
        Возможности сервиса
      </h5>

      <div class="landing_opportunities__header__grid">
        <p class="landing_opportunities__description">
          Сервис WBLeads предоставляет инструментарий для глубокого анализа рекламных и финансовых отчётов.
        </p>

        <div class="landing_opportunities__control">
          <a class="landing_opportunities__control_btn"
             @click="prev"
          >
            <img src="/img/landing-control-left.svg"
                 alt=""
            />
          </a>
          <a class="landing_opportunities__control_btn"
             @click="next"
          >
            <img src="/img/landing-control-right.svg"
                 alt=""
            />
          </a>
        </div>
      </div>
    </div>

    <div class="landing_opportunities__list">

      <swiper
          :allow-touch-move="true"
          :slides-per-view="3"
          :autoplay="{ delay: 3500}"
          :centered-slides="true"
          ref="RefSwiper"
      >
        <swiper-slide class="slide"
                      v-for="opportunity in opportunities"
                      :key="opportunity.id"
        >
          <LandingOpportunity
              :opportunity="opportunity"
          />
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<style lang="scss">
.landing_opportunities {
  padding: 80px 100px;
  overflow: hidden;
  background: var(--landing-light-grad, linear-gradient(103deg, rgba(112, 13, 190, 0.10) 14.03%, rgba(203, 17, 171, 0.10) 100%));

  &__header {
    margin-bottom: 40px;
  }

  &__title {
    font-size: 32px;
  }

  &__description {
    font-size: 18px;
  }

  &__list {
    & .swiper-wrapper {
      gap: 36px;
    }

    & .slide {
      width: 440px !important;
    }
  }

  &__header__grid {
    align-items: center;
    display: grid;
    grid-template-columns: auto 120px;
    gap: 1rem;
  }

  &__control {
    display: flex;
    align-items: center;
    gap: 24px;
  }

  &__description {
    max-width: 600px;
    font-size: 18px;
  }
}
</style>
