<script>
import {mapActions} from "vuex";

import PaymentMethod from "@/components/profile/PaymentMethod.vue";
import PaymentHistory from "@/components/profile/PaymentHistory.vue";

export default {
  name: 'PaymentMethods',

  components: {
    PaymentMethod,
    PaymentHistory
  },

  props: {
    methods: {
      type: Array,
      required: false,
      default: []
    },
    addAccount: {
      type: Function,
      required: true
    }
  },

  methods: {
    ...mapActions(['getProfileInfo']),

    handleUpdate() {
      this.getProfileInfo();
    }
  }
}
</script>

<template>
  <div class="payment_methods">
    <h6 class="payment_methods__title">
      Способ оплаты
    </h6>

    <div class="payment_methods__list">
      <PaymentMethod
          v-for="method in methods"
          :key="method.id"
          :method="method"
          :onUpdate="handleUpdate"
      />
    </div>

    <a class="payment_methods__add link_as_btn"
       @click="addAccount"
    >
      <div class="payment_methods__add__container">
        <img src="/img/add-card.svg"
             alt=""
        />
        <span>Добавить способ оплаты</span>
      </div>
    </a>

    <div class="payment_methods__history">
      <PaymentHistory />
    </div>
  </div>
</template>

<style scoped lang="scss">
.payment_methods {
  &__title {
    margin: 0;
    padding: 0;
    color: #908F90;
    font-size: 16px;
    font-weight: 400;
  }

  &__list {
    margin: 1rem 0;
  }

  &__add {
    color: #908F90;
  }

  &__history {
    margin-top: 36px;
  }
}

.payment_methods__add__container {
  display: flex;
  gap: 12px;
  align-items: center;
}
</style>
