<script>
import axios from 'axios';
import { mapGetters } from 'vuex';

import ProfileOffer from '@/components/profile/ProfileOffer.vue';
import PaymentMethods from '@/components/profile/PaymentMethods.vue';
import PaymentTariffPromo from '@/components/profile/PaymentTariffPromo.vue';
import WBLoader from '@/components/loader/WBLoader.vue';

export default {
  name: 'ViewTariffs',

  components: {
    ProfileOffer,
    PaymentMethods,
    PaymentTariffPromo,
    WBLoader,
  },
  data() {
    return {
      tariffs: [],
      isLoading: false,
      promocode: null,
      isAddAccount: false,
      dateEnd: null,
    };
  },

  computed: {
    ...mapGetters(['currentUserTariffInfo']),
  },

  methods: {
    getTariffs() {
      this.isLoading = true;

      axios({
        url: '/tariff/',
        method: 'GET',
      })
        .then((response) => {
          this.tariffs = response.data.results.map((tariff) => ({
            ...tariff,
            listOfferInclude:
              tariff.id !== 19
                ? {
                    org_count: 'Организаций:',
                    advertisement: '5 отчётов',
                    dashboard: 'Дашборд',
                    statisticData: 'Рекламная статистика за всю историю',
                    orders_90: 'Заказы и Продажи за 90 дней',
                  }
                : {
                    org_count: 'Организаций:',
                    advertisement: 'Отчёт "Динамика заказов и продаж"',
                    orders_90: 'Заказы и Продажи за 90 дней',
                  },
          }));
          this.isAddAccount =
            !this.currentUserTariffInfo.has_active_subscription;
          this.isLoading = false;
        })
        .catch((err) => {
          console.error(err);
          this.isLoading = false;
        });
    },

    addAccount() {
      axios({
        url: '/payment-token/add/',
        method: 'GET',
      })
        .then((response) => {
          if (response.status === 200) {
            window.open(response.data.token_add_url);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },

    onSubscript(tariff) {
      if (this.currentUserTariffInfo.payment_tokens.length === 0) {
        this.addAccount();
        return false;
      }

      axios({
        url: '/subscription/buy/',
        method: 'POST',
        data: {
          tariff: tariff.id,
          promocode: this.promocode,
        },
      })
        .then((response) => {
          this.$notify({
            type: 'success',
            title: 'Успешно. Страница автоматически обновится через 5 секунд',
          });
          setTimeout(() => {
            location.reload();
          }, 4000);
        })
        .catch((err) => {
          console.error(err);

          let message = 'Ошибка';
          let type = 'error';
          if (err.response.data.detail === 'Already wait or retry payment') {
            message = 'Ожидайте, ваш платеж обрабатывается';
            type = 'warning';
          }

          this.$notify({
            type: type,
            title: message,
          });
        });
    },

    handleUpdatePromo(code) {
      this.promocode = code;
    },

    getIsMy(tariff, props = null) {
      try {
        if (this.currentUserTariffInfo.payment_tokens.length === 0)
          return false;

        if (!props) {
          return this.currentUserTariffInfo.current_subscription
            ? this.currentUserTariffInfo.current_subscription.tariff.id ===
                tariff.id
            : false;
        }

        if (
          this.currentUserTariffInfo.current_subscription.tariff.id ===
          tariff.id
        ) {
          return false;
        }
      } catch (err) {
        console.error(err);
        return false;
      }
    },
  },

  mounted() {
    this.getTariffs();
  },
};
</script>

<template>
  <div class="tariffs">
    <WBLoader v-if="isLoading || !currentUserTariffInfo.id" />

    <div v-if="!isLoading && currentUserTariffInfo.id">
      <div class="tariffs__types">
        <h6 class="tariffs__types__title">Тарифные планы</h6>

        <div class="tariffs__container">
          <ProfileOffer
            v-for="tariff in tariffs"
            :key="tariff.id"
            :isShowHoverEffect="false"
            :tariff="tariff"
            :isAddAccount="currentUserTariffInfo.payment_tokens.length === 0"
            :onSubscript="onSubscript"
            :isMy="getIsMy(tariff)"
            :isWaiting="getIsMy(tariff, 'waiting')"
            :currentUserTariffInfo="currentUserTariffInfo"
            :dateEnd="currentUserTariffInfo.subscription_payed_until"
          />
        </div>
      </div>

      <div class="tariffs__promo">
        <PaymentTariffPromo :updatePromo="handleUpdatePromo" />
      </div>

      <div class="tariffs__payment_methods">
        <PaymentMethods
          :methods="currentUserTariffInfo.payment_tokens"
          :addAccount="addAccount"
        />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
$padding: 36px;

.tariffs {
  padding: 1rem;
  background: #fff;

  &__payment_methods {
    margin-top: $padding;
    padding: 1rem;
    background: #fff;
  }

  &__types {
    padding-top: 40px;

    &__title {
      margin-bottom: 1rem;
      color: #908f90;
      font-size: 16px;
      font-weight: 400;
    }
  }

  &__container {
    display: grid;
    grid-template-columns: auto auto;
    gap: 40px;
  }

  &__promo {
    padding: $padding 0;
  }
}
</style>
