<script setup>
import LandingQuestion from "@/components/landing/LandingQuestion.vue";

const questions = [
  {
    id: 1,
    title: 'Мой кабинет не заблокируют из-за подключения к WBLeads?',
    text: 'Сервис WBLeads интегрируется с официальным API Wildberries напрямую, по "белой схеме", поэтому наши пользователи могут не опасаться блокировки. Более того, wildberries отменил "Действие пункта 9.9.8 пп.9 о блокировке Личного кабинета продавца за не разрешенное Офертой использование Портала и API приостановлено. С 7 июля этот пункт будет временно исключён из Оферты.',
    isShowDefault: true
  },
  {
    id: 2,
    title: 'Можно ли работать без API?',
    text: 'Для того, чтобы сервсис WBLeads мог собирать рекламную статистику по вашему кабинету для дальнейшего анализа, необходимо предоставить такой доступ. Этот доступ возможно открыть только с помощью API ключей.',
    isShowDefault: false
  },
  {
    id: 3,
    title: 'Статистика на WBLeads совпадает со статистикой на Wildberres?',
    text: 'WBLeads интегрируется с официальным API и собирает статистику с кабинета селлера каждые 10-15 минут. Поэтому все данные в сервисе практически синхронизированы с wildberries.',
    isShowDefault: false
  },
  {
    id: 4,
    title: 'Могу ли я подключить несколько рекламных кабинетов?',
    text: 'Вы можете подключить 2 рекламных кабинета, в "Стандартном" тарифе от WBLeads. С  тарифом "Максимальный", открывается возможность добавить до 5 рекламных кабинетов. Также, в ближайшее время появится возможность добавлять больше рекламных кабинетов за дополнительную плату, вне зависимости от тарифа.',
    isShowDefault: false
  }
]
</script>

<template>
  <div id="Faq" class="landing_questions">
    <h6 class="landing_questions__title">
      Часто задаваемые вопросы
    </h6>

    <LandingQuestion
      v-for="question in questions"
      :key="question.id"
      :question="question"
    />
  </div>
</template>

<style scoped lang="scss">
.landing_questions {
  &__title {
    margin-bottom: 45px;
    font-size: 32px;
    font-weight: 500;
    text-align: center;
  }
}
</style>
