<script setup>
import {ref, defineProps, onMounted, onUnmounted} from 'vue';

import ReportsGroupDropDown from "@/components/reports-groups/ReportsGroupDropDown.vue";

const props = defineProps({
  onSelect: {
    type: Function,
    required: false
  },
  options: {
    type: Array,
    required: false,
    default: []
  }
})

const isShowMenu = ref(false);
const activeOption = ref('Группировка');

const onSelect = (option) => {
  activeOption.value = option.name;
  if(props.onSelect) props.onSelect(option.key);
  isShowMenu.value = false;
}

const toggleMenu = () => isShowMenu.value = !isShowMenu.value;

onMounted(() => {
  window.addEventListener('click', (e) => {
    if(isShowMenu.value) {
      const ReportsDropDown = document.querySelector('#ReportsDropDown');
      const Groups = document.querySelector('#Groups');

      if (!ReportsDropDown.contains(e.target) && !Groups.contains(e.target)) {
        isShowMenu.value = false;
      }
    }
  })
})

onUnmounted(() => {
  window.removeEventListener('click', () => {console.log('remove click')}, false);
})
</script>

<template>
  <div id="Groups" class="reports_group_btn_menu">
    <button class="reports_group_btn_menu__btn wb-btn"
            @click="toggleMenu"

    >
      {{ activeOption }}
    </button>

    <ReportsGroupDropDown
        v-if="isShowMenu"
        :options="props.options"
        :onSelect="onSelect"
    />
  </div>
</template>

<style scoped lang="scss">
.reports_group_btn_menu {
  position: relative;
  width: 215px;
  display: flex;
  justify-content: start;

  &__btn {
    padding: 10px 1rem;
    border-radius: 8px;
    border: 1px solid #F1F1F1;
    color: #908F90;
    font-size: 14px;
    background: #FFF
  }
}
</style>
