import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import router from "@/router";
import store from "@/store";
import Cookies from "js-cookie";

axios.defaults.baseURL = process.env.VUE_APP_API_URL;

let isRefreshing = false; // Initialize the flag to false

axios.interceptors.request.use((request) => {
  const accessToken = store.getters["auth/accessToken"];

  if (accessToken) {
    request.headers["Authorization"] = `Bearer ${encodeURIComponent(accessToken)}`;
    request.headers["Content-Type"] = "application/json";
    request.headers["Accept"] = "application/json";
  }

  return request;
});

axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    let token = Cookies.get("refresh_token");

    if (error.response && error.response.status === 401) {
      if (!isRefreshing) {
        // Check if refreshing is not already in progress
        isRefreshing = true;

        try {
          const { state, data } = await axios.post(
            "/auth/refresh-token/",
            { refresh: token },
            {
              withCredentials: true,
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
                Accept: "application/json",
              },
            }
          );

          if (data.access && data.refresh) {
            Cookies.set("access_token", data.access, { expires: 7 });
            Cookies.set("refresh_token", data.refresh, { expires: 60 });
            window.location.reload()
          }
        } catch (refreshError) {
            Cookies.remove("access_token");
            Cookies.remove("refresh_token");

            if(
                router.currentRoute.name !== 'auth.landing' &&
                router.currentRoute.name !== 'agreement' &&
                router.currentRoute.name !== 'confidential' &&
                router.currentRoute.name !== 'auth.login'
            ) {
                router.push('/landing')
            }

        } finally {
          isRefreshing = false; // Reset the flag after the refresh attempt
        }
      }
      // Additional handling after token refresh
    }

    return Promise.reject(error);
  }
);

Vue.use(VueAxios, axios);
