<template>
  <div class="auth-card">
    <div class="title mb-4">
      Введите новый пароль
    </div>

    <b-form>
      <b-form-input type="password" placeholder="Пароль" class="mb-3"
                    v-model="pass"

      />

      <b-form-input type="password" placeholder="Повторите пароль" class="mb-3"
                    v-model="passSecond"
      />

      <b-btn pill class="mt-4 w-100 py-2" variant="wblead"
             @click="handleRecovery"
             :disabled="pass == null || passSecond === null || (passSecond !== pass)"
      >Восстановить пароль</b-btn>
    </b-form>

    <div v-if="requestError" class="request-error">
      {{ requestError }}
    </div>

    <div class="no-account mt-5 text-center">
      Нет аккаунта? <a @click.prevent="$router.push({ name: 'auth.register' })" href="/auth/register">Зарегистрироваться</a>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: 'ForgetView',

  data() {
    return {
      pass: null,
      passSecond: null,
      token: null,
      requestError: null
    }
  },

  methods: {
    isValidPass() {
      if (/[a-zа-яё]/i.test(this.pass)){
        return true;
      } else {
        return false;
      }
    },

    handleRecovery() {
      console.log('method recovery');
      if(this.pass !== this.passSecond) {
        return;
      }

      if(!this.isValidPass()) {
        this.requestError = 'Пароль не может содержать только цифры';
        return;
      }

      if(this.pass.length < 8) {
        this.requestError = 'Пароль должно быть не менее 8 символов';
        return;
      }

      axios({
        url: '/auth/password_reset/confirm/',
        method: 'POST',
        data: {
          password: this.pass,
          token: this.token
        }
      })
          .then((response) => {
            console.log(response);
            location.href = '/';
          })
          .catch((err) => {
            console.error(err);
          })
    },

    setToken() {
      this.token = new URL(location.href).searchParams.get('token');
    }
  },

  mounted() {
    this.setToken();
  }
}
</script>

<style lang="scss" scoped>
.request-error {
  margin: 1rem 0;
  color: red;
  font-size: 16px;
  text-align: center;
}
</style>
